import React, { Component } from 'react'
import { connect } from 'react-redux';
import moment from 'moment'

import {
  Link
} from "react-router-dom";


class List extends Component {

  constructor(props) {
    super(props)

    this.state = {
    }

    this._transfer = 0;
    this._paid = 0;
    this._rent = 0
    this._fee = 0
    this._bank = 0
    this._held = 0
    this._arrears = 0
    this.csvData = []
  }
  /**
   * mount component once render the initial component 
   * 
   * @param {*} NA
   * @return {*} NA
  */
  componentDidMount() {
    this._transfer = 0;
    this._paid = 0;
    this._rent = 0
    this._fee = 0
    this._bank = 0
    this._held = 0
    this._arrears = 0
    this.csvData = []
  }

  /**
   * get Closing balance for the property
   * 
   * @param {int} propertyId 
   * @return {*}
   */
  getClosingBalance(propertyId) {
    const { buildingLedgers } = this.props
    let balance = 0

    try {

      let aLength = buildingLedgers[propertyId]?.length
      balance = buildingLedgers[propertyId]?.[aLength - 1]?.balanceNF
      return balance ? balance : 0

    } catch (error) { console.log(error); }
    return balance
  }

  /**
   * change sign 
   * 
   * @param {float} transaction 
   * @returns 
   */
  transactionSign(transaction) {
    try {
      if (Math.sign(transaction) == -1)
        return parseFloat(transaction) * (-1)
    } catch (error) { }
    return 0
  }

  /**
   * render proeprty table and it details
   * 
   * @param {*} NA
   * @return {obejct}
  */
  renderPropertyDetails(propertyList) {console.log(propertyList, 'propertyList');
    const { props } = this
    const { constants, leasedOnly, buildingLedgers } = props

    const lists = (leasedOnly) ? props.helper.findLeaseOnly(propertyList) : propertyList

    this.csvData = []
    return lists.map(function (option, index) {

      let __fees = this.fees(option, constants)
      let __transaction = this.transactions(option, constants)

      let fees = props.helper.currencySign(__fees)

      let transaction = props.helper.currencySign(__transaction)
      let paid = this.paid(option, constants)
      let held = this.held(option, constants)
      let income = this.banked(option)

      let __paid = parseFloat(held.debit) - (parseFloat(__fees) + this.transactionSign(__transaction) + parseFloat(income))

      let totalIncome = parseFloat(income) + parseFloat(held.credit)
      let balance = this.getClosingBalance(option?.property_id)

      let tenancyLink = `/tenancy/${option.property_id}?start=${moment(props.start).format('L')}&end=${moment(props.end).format('L')}`
      let arrears = this.arrears(option, constants)
      this.csvData.push({ 'Property ID': option.address, "Rent": props.helper.currencySign(option.rent), "Fees": fees, 'Transfer': transaction, "Paid": props.helper.currencySign(__paid), "Bank": props.helper.currencySign(income), 'Held': props.helper.currencySign(balance), 'Arreas': props.helper.currencySign(arrears) })

      this._rent += option.rent

      /** total banked by the end of the calendar */
      this._held += parseFloat(balance)

      /** total arrears by the end of the calendar */
      this._arrears += (arrears != '0.00') ? arrears : 0

      /** total arrears by the end of the calendar */

      this._paid += paid

      return (

        <tr key={index}>
          <td><Link to={tenancyLink}>{option.address.substring(0, 6)}</Link></td>
          <td>{props.helper.currencySign(option.rent)}</td>
          <td className={props.helper.buildingLeaseStatus(props, option)}></td>
          <td>{fees}</td>
          <td>{transaction}</td>
          <td>{props.helper.currencySign(__paid)}</td>
          <td>{props.helper.currencySign(income)}</td>
          <td>
            {props.helper.currencySign(balance, true)}
          </td>
          <td>{props.helper.currencySign(arrears)}</td>
        </tr>
      )
    }, this)
  }

  render() {

    const { props } = this
    const { propertyList } = this.props.buildingProperty

    return (
      <>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
          <div className='ledger_property_table'>
            <table className="table property-building-table building_view_format">
              <thead>
                <tr>
                  <th>#</th>

                  <th><span className="_tooltip bottom animationTips blue" tool-tips="Property Monthly Rent">Rent</span></th>
                  <th>Status</th>
                  <th><span className="_tooltip bottom animationTips blue" tool-tips="Fees include: Admin Fee, Management Fee and Lease Fee etc">Fees</span></th>
                  <th><span className="_tooltip bottom animationTips blue" tool-tips="Transfers between your properties. Press Tenancy View for Details">Transfer</span></th>
                  <th><span className="_tooltip bottom animationTips blue" tool-tips="All other paid items. See tenancy view for details">Paid</span></th>

                  <th><span className="_tooltip bottom animationTips blue" tool-tips="Funds deposited to you">Banked</span></th>
                  <th><span className="_tooltip bottom animationTips blue" tool-tips={"Closing balance held in your ledger"}>Held</span> </th>
                  <th><span className="_tooltip bottom animationTips blue" tool-tips="Rents not received after due date">Arrears</span></th>
                </tr>
              </thead>
              <tbody>
                {this.renderPropertyDetails(propertyList)}
              </tbody>
              <tfoot>
                <tr>
                  <td >Total</td>
                  <td>{props.helper.currencySign(this._rent)}</td>
                  <td>&nbsp;</td>
                  <td>{props.helper.currencySign(this._fee)}</td>
                  <td>{props.helper.currencySign(this._transfer)}</td>
                  <td>{props.helper.currencySign(this._paid)}</td>
                  <td>{props.helper.currencySign(this._bank)}</td>
                  <td>{props.helper.currencySign(this._held, true)}</td>
                  <td>{props.helper.currencySign(this._arrears)}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </>
    )
  }

  /**
   * net transaction total 
   * 
   * @param {object} option 
   * @param {object} constants 
   * @returns 
   */
  transactions(option, constants) {

    const { ledgers } = option
    let netBalance = 0

    try {
      ledgers.forEach(element => {

        if (element.description && constants.BUILDING_AUTH_TRANSFER.includes((element.description).toLowerCase())) {
          if (element.type.toLowerCase() == constants.TYPES.DEBIT) {
            netBalance = parseFloat(netBalance) - parseFloat(element.amount)
          }
          else
            netBalance += parseFloat(element.amount)

        }
      });
      this._transfer += netBalance
      return netBalance
    } catch (error) { }

    return netBalance
  }

  /**
   * total fees
   * 
   * @param {object} option 
   * @param {object} constants 
   * @returns 
   */
  fees(option, constants) {
    const { ledgers } = option
    let netBalance = 0
    try {
      ledgers.forEach(element => {

        if (element.description && constants.BUILDING_FEES_ENTRY.includes((element.description).toLowerCase()))
          netBalance += parseFloat(element.amount)
      });

      /** total fees by the end of the calendar */
      this._fee += netBalance
      return netBalance
    } catch (error) { }

    return netBalance
  }

  /**
   * total transfer to landloard
   * 
   * @param {object} option 
   * @param {object} constants 
   * @returns 
  */
  banked(option) {
    const { ledgers } = option
    const { constants } = this.props
    let netBalance = 0

    try {
      ledgers.forEach(element => {
        if (element.description && constants.LEDGER_ENTRY_NOT_IN.includes((element.description).toLowerCase()))
          netBalance += parseFloat(element.amount)
      });

      /** total banked by the end of the calendar */
      this._bank += netBalance

      return netBalance
    } catch (error) { }

    return netBalance
  }

  /**
   * net paid total 
   * 
   * @param {object} option 
   * @param {object} constants 
   * @returns 
   */
  paid(option, constants) {
    const { ledgers } = option
    const { LEDGER_ENTRY_NOT_IN, AUTH_TRANSACTIONS } = constants
    let netBalance = 0

    try {

      ledgers.forEach(element => {
        if (
          element.txn_type.toLowerCase() == constants.TYPES.CREDIT &&
          !LEDGER_ENTRY_NOT_IN.includes((element.description).toLowerCase()) &&
          !AUTH_TRANSACTIONS.includes((element.description).toLowerCase())) {
          netBalance = parseFloat(netBalance) + parseFloat(element.amount)
        }
      });

      return netBalance
    } catch (error) { }

    return netBalance
  }

  /**
   * net past due fr last month
   * 
   * @param {object} option 
   * @param {object} constants 
   * @returns 
  */
  arrears(option) {
    try {

      if (option.lease && option.lease.ar)
        return option.lease.ar.total_debt_rent
    } catch (error) {
      return option.lease.ar.total_debt_rent
    }
    return '0.00';
  }

  /**
   * net past due fr last month
   * 
   * @param {object} option 
   * @param {object} constants 
   * @returns 
  */
  held(option, constants) {
    const { work_order } = option
    let totalDebit = 0
    let totalCredit = 0
    try {

      work_order.forEach(element => {

        if (element.txn_type.toLowerCase() == constants.TYPES.DEBIT)
          totalDebit += parseFloat(element.amount)
        else
          totalCredit += parseFloat(element.amount)
      });

      return { debit: totalDebit, credit: totalCredit }
    } catch (error) { }

    return { debit: totalDebit, credit: totalCredit }
  }

  /**
   * life cycle method
   * @param {*} NA
   */
  componentDidUpdate() {

    this._transfer = 0;
    this._paid = 0;
    this._rent = 0
    this._fee = 0
    this._bank = 0
    this._held = 0
    this._arrears = 0
    this.csvData = []
  }
}


/** validate function is or not */
List.propTypes = {
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
})

export default connect(mapStateToProps, {})(List)

