import React, {Component} from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types'
import moment from 'moment'
import Panel from './../panel'
import Refer from './../../../../modules/blocks/sections/panel/refer'
import Image from './../../../../component/assets/resource/2021_Calendar_2021_1.jpg'
import { findAllResources } from './../../../../modules/redux/actions/listActions'
import Linkify from 'linkify-react';
import { Link } from 'react-router-dom';
class Resource extends Component { // eslint-disable-line react/prefer-stateless-function
    constructor(props){
        super(props)        
    } 

    /**
     * mount component once render the initial component 
     * 
     * @param {*} NA
     * @return {*} NA
    */
    componentDidMount()
    { 
        document.title = "Resources | RentPerks"
        const { match, authData } = this.props
        this.props.findAllResources(this.props?.constants?.RESOURCE_TYPE?.OP_RESOURCE)
    }
 
    render() {

        const { props } = this
        const resource = props?.allResourcesData
        const { constants } = props
       
        return (

            <div className='row justify-content-center section_height'>
                <Panel {...props} />
                <div className='col-lg-10 col-md-10 col-sm-12 col-xl-10'>
                    <div className="dashboard-middle parentCell resources-middle">
                        <Link className="cross-button resource_cross_button" variant="" to="/folio-snap">
                            <i className="fa fa-times-circle fa-2x"></i>
                        </Link>
                        <div className="row">
                            <div className='col-md-12'>
                            { resourceLoop(resource, props) }
                            </div>
                        </div>
                    </div>
                    {/* <Refer /> */}
                </div>
            </div>
        )
    }
}

const resourceLoop = (resource, props) => {
    return resource.map((item, index) => {
        let media = ''
        let file_name = ''
        const mediaUrl  = (item?.media?.path) ? item?.media?.path : ''
        let file_name_original  = (item?.media?.name) ? item?.media?.name : ''
        file_name  = (item?.media?.name) ? item?.media?.name : ''
        if(item?.media?.description == 'pdf'){
            file_name = 'thumbs/' + file_name.substr(0, file_name.lastIndexOf(".")) + ".png";
        }
        media = <img className="card-img-top" src={props?.constants?.MEDIAURL+mediaUrl+file_name} />
        return (
            // <div  className="col-md-12">
                <a key={index} href={props?.constants?.MEDIAURL+mediaUrl+file_name_original} target="_blank">
                    <div className="card">
                    <div className="row">
                        <div className="col-md-3 card-media">{media}</div>
                        <div className="col-md-9 card-body">
                            <h5 className="card-title">{item?.name}</h5>
                            <p className="card-text"><Linkify>{item?.value}</Linkify></p>
                        </div>
                    </div>
                    </div>
                </a>
            // </div>
        )
    });
}

/** validate function is or not */
Resource.propTypes = {
    findAllResources: propTypes.func.isRequired,
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
 const mapStateToProps = state => ({
    authData: state.dataPack.authData,
    allResourcesData: state.dataPack.allResourcesData,
    constants: state.dataPack.constants,
})
export default connect (mapStateToProps, {findAllResources})( Resource )