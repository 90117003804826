import React, {Component, useEffect} from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import {
    Link
} from "react-router-dom";
import {getOwnerList,simulateUser} from './../../../redux/actions/listActions'


/**
 * render component
 * 
 * @param {object} props 
 * @returns 
 */
const List = (props) => {

    useEffect( async() => {

        props.getOwnerList()
    }, [])

    let {ownerList}  = props?.ownerList

    const { SearchBar } = Search;
    
    const columns = [{
  
            dataField: 'first_name',
            text: `Name`,
            formatter: nameFormatter

        }, {
            dataField: 'email',
            text: 'Email'
        },
        {
            dataField: 'email',
            text: 'Login As',
            className:"btn-primary",
            formatter: buttonFormatter, 
            formatExtraData: { props }
        }
    ];

    return (
        <>
        <ToolkitProvider
            keyField="id"
            data={ownerList ? ownerList : []} 
            columns={ columns }
            search
        >
            {
            props => (
                <div className="owner-table">
                <p className="mb-2 text-right"><SearchBar { ...props.searchProps } /></p>
                <BootstrapTable
                    { ...props.baseProps }
                    classes="table transparent_modal_box table_layout_auto super_admin_table_layout"
                    bootstrap4
                    DataIndication="No List Found!"
                    pagination={ paginationFactory() }
                />
                </div>
            )
            }
        </ToolkitProvider>
        </>
    )
} 

    const nameFormatter = (cell, option) => {

        return option?.first_name + ' ' + option?.last_name
    }

    const buttonFormatter = (cell, option, formatExtraData, next) => {

        const {props}   = next

        return (
            <button className="btn btn-success" onClick={() => handleSimulateEvent(option, props)}>Login As</button>
        )
    }

    /**
     * handle simulate event
     * 
     * @param {object} option 
     */
    const handleSimulateEvent = (option, props) => {

        const data = {
            customer: option?.customer_id,
            simulate: localStorage.getItem("simulate")
        }
        props.simulateUser(data);
    }
  
    /** validate function is or not */
    List.propTypes = {
        getOwnerList: propTypes.func.isRequired,
        simulateUser: propTypes.func.isRequired
    }

    /**
     * sending all state to component as props
     * 
     * @param {object} state 
     */
    const mapStateToProps = state => ({
        constants: state.dataPack.constants,
        helper: state.dataPack.helper,
        authData: state.dataPack.authData,
        ownerList: state.dataPack.ownerList
    })

    export default connect (mapStateToProps, { getOwnerList, simulateUser })( List )
