import React, { useState } from 'react';
import {Button, Modal} from 'react-bootstrap';
import moment from 'moment'
import {Link} from 'react-router-dom'
import 'react-notifications-component/dist/theme.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Message = (props) => {

    const [details, setDetails] = useState(0);
    
    return (
        <>
        {
            (details == 0) && 
            <div className="folio_table transparent_modal_box folio_table_box">
                <div size="lg" className="folio_table message_box transparent_modal_box">

                    <Modal.Header>
                        <Modal.Title>Recent Notification</Modal.Title>
                        <Button className="cross-button" variant="" onClick={(el) => props?.onReferClose(el)}>
                            <i className="fa fa-times-circle fa-2x"></i>
                        </Button>
                    </Modal.Header>

                    <Modal.Body>
                        <ul class="list-group list-group-flush">
                            {
                                renderNotifications(props)
                            }
                        </ul>
                    </Modal.Body>
                </div>
            </div>
        }
        {
            (details != 0) && 
            <div className="folio_table transparent_modal_box folio_table_box">
                <div size="lg" className="folio_table message_box wide-popup transparent_modal_box">
                    <Modal.Header>
                        <Modal.Title>Recent Notification</Modal.Title>
                        <Button className="cross-button" variant="" onClick={() => setDetails(0)}>
                            <i className="fa fa-times-circle fa-2x"></i>
                        </Button>
                    </Modal.Header>

                    <Modal.Body className="transparent_modal_box">
                        <ul class="list-group list-group-flush">
                            {
                                showMessageDetails(props) 
                            } 
                        </ul>
                    </Modal.Body>
                </div> 
            </div>
        }
        </>
    )

    function renderNotifications(props) {

        const { userNotification, constants } = props
  
        if (!userNotification?.length) return <h2>No Notification Found!</h2>
  
        return userNotification?.map(option => {
  
            let getMsgLen = props.helper.getReadMoreText(option?.message, '120')
  
            let isReccMsg = (!option?.is_viewed) ? 'msg_recc' : ''
            
            let readClass = option?.is_viewed == 1 ? "message_read" : 0;

            return (
                <li class={readClass+" owner_message_list list-group-item " + isReccMsg}>
                    <i className="fa fa-hand-o-right"></i>
                    {
                        getMsgLen ?
                            <>
                                <p onClick={(el) => {setDetails(option?.message_id);props?.setNotificationsViewed(props?.authData?.user, props, this, option?.message_id)}}>{getMsgLen}</p>
                            </>
                            :
                            <>
                                <p onClick={(el) => {setDetails(option?.message_id);props?.setNotificationsViewed(props?.authData?.user, props, this, option?.message_id)}}>{option?.message}</p>
                            </>
                    }
                    {
                        <p className="message_date">{moment(option?.created_at).format('MMM DD, YYYY')}</p>
                    }
                </li>
            )
        })
    }
    function showMessageDetails(props) 
    {
        const { userNotification } = props

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
        };

        try {

            let index = userNotification?.findIndex(list => list?.message_id == details)
            if (index >= 0) {
                return (
                    <>
                        {userNotification[index]?.message}
                        <div className="render_image">
                            <Slider {...settings}>
                                {get_images(userNotification[index]?.notification)}
                            </Slider>
                        </div>
                    </>
                )
            }

        } catch (error) {

        }
        return false
    }

    function get_images(notification_img) {
        try {

            const { constants } = this?.props

            if (notification_img) {
                return notification_img.map(element => {
                    return (

                        <div><img src={constants?.MEDIAURLDEV + element?.media?.path + '/' + element?.media?.name} className="msg_img" /></div>
                    )
                });
            }
        } catch (error) {

        }
    }
}

export default Message