import React, { Component } from 'react';
import { connect } from 'react-redux';
import Panel from './../panel'
import Refer from './../panel/refer'
import ForLeaseSlider from '../../../../component/structure/slider/forleaseslider'
import propTypes from 'prop-types'
import { findOwnersForLeaseProperty, forLease } from './../../../redux/actions/listActions'
import Overlay from './../../../../component/layout/overlay'
import NoData from './../../../../component/structure/noData'

class ForLease extends Component { // eslint-disable-line react/prefer-stateless-function
    constructor(props) {
        super(props)
        this.state = {
            creditType: 'all'
        }
    }

    /**
     * mount component once render the initial component 
     * 
     * @param {*} NA
     * @return {*} NA
    */
    componentDidMount() {
        const { match, authData } = this.props
        this.props.findOwnersForLeaseProperty(authData.user.customer_id)
        this.props.forLease(match.params.id, 'all')
    }

    componentDidUpdate(prevProps) {
        document.title = "For Lease | RentPerks"
        const { props } = this
        const { match } = this.props

        if (match.params.id != prevProps.match.params.id) {
            this.props.forLease(match.params.id, 'all')
        }

        let propCount = props?.propertyList?.length

        if (propCount >= 1 && props.location.pathname == '/for-lease') {
            let f_prop_id = props?.propertyList?.[0]?.property_id
            props.history.push('/for-lease/' + f_prop_id)
        }
    }

    render() {

        const { props, state } = this
        const { url } = props.match
        const advertismentsItems = props.forLeaseData.advertisments ? props.forLeaseData.advertisments : ''

        if (props?.propertyList?.length == 0)
            return <div className='row justify-content-center section_height'><NoData {...props} msg="No Property available For Lease." /></div>

        return (
            <div className='row justify-content-center section_height for_lease_container'>
                <Panel {...props} />
                <div class="col-lg-10 col-md-10 col-sm-12 col-xl-10 d-flex align-content-between flex-wrap">
                    <ForLeaseSlider {...props} creditType={state.creditType} />

                    <div class="row w-100">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                            <div class="for_lease_section">
                                <div class="for_lease_head d-flex justify-content-around">
                                    <div class="for_lease_state_box">
                                        <div class="for_lease_state">{props.forLeaseData.inquiries ? props.forLeaseData.inquiries : 0}</div>
                                        <div class="for_lease_state_text">Inquiries</div>
                                    </div>
                                    <div class="for_lease_state_box">
                                        <div class="for_lease_state">{props.forLeaseData.showings ? props.forLeaseData.showings : 0}</div>
                                        <div class="for_lease_state_text">Showing</div>
                                    </div>
                                    <div class="for_lease_state_box">
                                        <div class="for_lease_state">{props.forLeaseData.applications ? props.forLeaseData.applications : 0}</div>
                                        <div class="for_lease_state_text">Applications</div>
                                    </div>
                                    <div class="for_lease_state_box">
                                        <div class="for_lease_state">{props.forLeaseData.processing ? props.forLeaseData.processing : 0}</div>
                                        <div class="for_lease_state_text">Processing</div>
                                    </div>
                                    <div class="for_lease_state_box">
                                        <div class="for_lease_state">{props.forLeaseData.approved ? props.forLeaseData.approved : 0}</div>
                                        <div class="for_lease_state_text">Approved</div>
                                    </div>
                                </div>
                                <div class="for_lease_content">
                                    <div className='row'>
                                        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12'>Your Advertisements</div>
                                        <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12'>
                                            {props.forLeaseData.virtualTourUrl && virtualTourUrl(props.forLeaseData.virtualTourUrl)}
                                        </div>
                                    </div>
                                    <div className='for-lease-list-section mt-3'>
                                        {props.forLeaseData.advertisments && advertismentsSection(props.forLeaseData.advertisments)}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                            <div class="table_filter flex-wrap align-content-end d-grid  w-100">
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex align-content-end">
                                        <button class="custom_sm_button me-5" onClick={(el) => handleEvent(el, props)} data-type="all"> All</button>
                                        <button class="custom_sm_button" onClick={(el) => handleEvent(el, props)} data-type="custom"> Last 7 Days</button>
                                    </div>
                                    <div class="day_on_market">
                                        <button class="btn">Days On Market {props.forLeaseData.daysOnMarket ? props.forLeaseData.daysOnMarket : 0}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='col-lg-9 col-md-12 col-sm-12 col-xl-9 d-flex align-content-between flex-wrap'> */}
                {/* <div className="dashboard-middle parentCell"> */}
                {/* <div className='row w-100'>
                    <ForLeaseSlider {...props} creditType={state.creditType} />

                    <div class="row d-flex flex-wrap flex-wrap-reverses align-content-end">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                            <div class="for_lease_section">
                                <div class="for_lease_head d-flex justify-content-around">
                                    <div class="for_lease_state_box">
                                        <div class="for_lease_state">{props.forLeaseData.inquiries ? props.forLeaseData.inquiries : 0}</div>
                                        <div class="for_lease_state_text">Inquiries</div>
                                    </div>
                                    <div class="for_lease_state_box">
                                        <div class="for_lease_state">{props.forLeaseData.inquiries ? props.forLeaseData.showings : 0}</div>
                                        <div class="for_lease_state_text">Showing</div>
                                    </div>
                                    <div class="for_lease_state_box">
                                        <div class="for_lease_state">{props.forLeaseData.inquiries ? props.forLeaseData.applications : 0}</div>
                                        <div class="for_lease_state_text">Applications</div>
                                    </div>
                                    <div class="for_lease_state_box">
                                        <div class="for_lease_state">{props.forLeaseData.inquiries ? props.forLeaseData.processing : 0}</div>
                                        <div class="for_lease_state_text">Processing</div>
                                    </div>
                                    <div class="for_lease_state_box">
                                        <div class="for_lease_state">{props.forLeaseData.inquiries ? props.forLeaseData.approved : 0}</div>
                                        <div class="for_lease_state_text">Approved</div>
                                    </div>
                                </div>
                                <div class="for_lease_content">
                                    Your Advertisements
                                </div>
                            </div>

                        </div>
                        <div class="table_filter flex-wrap align-content-end d-grid">
                            <div class="d-flex justify-content-between">


                                <div class="d-flex align-content-end">
                                    <button class="download_button me-5"> PDF</button>
                                    <a download="/" class="download_button" target="_self"
                                        href="/">CSV
                                    </a>
                                </div>
                                <div class="day_on_market">
                                    <button class="btn">Days On Market 14</button>
                                </div>

                            </div>
                            {/* </div> *
                        </div>
                        {/* <div className="dash-form-bg detail-wrapper">
                            {
                                !props.match.params.id ?
                                    <Overlay /> :
                                    <>
                                        <section className="container-fluid graph-circle">
                                            <div className="row">
                                                <div className="col-md-12 graph-circle-div">
                                                    <ul className="graph-circle-items">
                                                        <li>
                                                            <h3 className="inquiries">
                                                                <span align="center">{props.forLeaseData.inquiries ? props.forLeaseData.inquiries : 0}</span>
                                                            </h3>
                                                            <p>INQUIRIES</p>
                                                        </li>
                                                        <li>
                                                            <h3 className="showings">
                                                                <span align="center">{props.forLeaseData.inquiries ? props.forLeaseData.showings : 0}</span>
                                                            </h3>
                                                            <p>SHOWINGS</p>
                                                        </li>
                                                        <li>
                                                            <h3 className="applications">
                                                                <span>{props.forLeaseData.inquiries ? props.forLeaseData.applications : 0}</span>
                                                            </h3>
                                                            <p>APPLICATIONS</p>
                                                        </li>
                                                        <li>
                                                            <h3 className="processing">
                                                                <span>{props.forLeaseData.inquiries ? props.forLeaseData.processing : 0}</span>
                                                            </h3>
                                                            <p>PROCESSING</p>
                                                        </li>
                                                        <li>
                                                            <h3 className="approved">
                                                                <span>{props.forLeaseData.inquiries ? props.forLeaseData.approved : 0}</span>
                                                            </h3>
                                                            <p>APPROVED</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </section>
                                        <section className="container-fluid lease-advertisement">
                                            <div className="row">
                                                <div className="col-md-12 main-d">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h4>YOUR ADVERTISEMENTS</h4>
                                                        </div>
                                                        <div className="col-md-6 ">
                                                            {props.forLeaseData.virtualTourUrl && virtualTourUrl(props.forLeaseData.virtualTourUrl)}
                                                        </div>
                                                    </div>
                                                    <div className="advrt-sect">
                                                        {props.forLeaseData.advertisments && advertismentsSection(props.forLeaseData.advertisments)}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className="container-fluid lease-action">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <button onClick={(el) => handleEvent(el, props)} data-type="all" className="btn btn-danger advert-btn">ALL</button>
                                                    <button onClick={(el) => handleEvent(el, props)} data-type="custom" className="btn btn-danger advert-btn">LAST 7 DAYS</button>
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    <h3>DAYS ON MARKET {props.forLeaseData.daysOnMarket ? props.forLeaseData.daysOnMarket : 0}</h3>
                                                </div>
                                            </div>
                                        </section>
                                    </>
                            }
                        </div> *
                    </div>
                    </div> */}
                {/* </div> */}
                {/* <Refer /> */}
            </div>

        )
    }

}

const handleEvent = (el, props) => {
    let type = el.target.dataset.type;
    props.forLease(props.match.params.id, type)
}

const advertismentsSection = (advertismentsItems) => {
    return advertismentsItems.map((item, index) => {
        return (
            <div key={index} className="row mb-2">
                <div className="col-md-10 advert-h">
                    <h2>{item?.platformTitle}</h2>
                </div>
                <div className="col-md-2">
                    <a href={item?.url} className="download_button pl-4 pr-4" target="_blank">VIEW</a>
                </div>
            </div>
        )
    });
}

const virtualTourUrl = (vTourUrl) => {

    if (vTourUrl != '') {
        return (
            <div className="row">
                <div className="col-md-8 text-right">
                    <h4>TOUR VIDEO</h4>
                </div>
                <div className="col-md-4">
                    <a className="btn btn-dark advert-btn tourVideoBtn" href={vTourUrl} target="_blank"><i className="fa fa-video-camera" aria-hidden="true"></i></a>
                </div>
            </div>
        )
    }

    return;
}

/** validate function is or not */
ForLease.propTypes = {
    findOwnersForLeaseProperty: propTypes.func.isRequired,
    forLease: propTypes.func.isRequired,
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    propertyList: state.dataPack.forLeasePropertyList,
    forLeaseData: state.dataPack.forLeaseData,
    constants: state.dataPack.constants,
    authData: state.dataPack.authData,
    helper: state.dataPack.helper
})

export default connect(mapStateToProps, { findOwnersForLeaseProperty, forLease })(ForLease)
