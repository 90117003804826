import React, {Component} from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types'
import Iframe from 'react-iframe'
import { Redirect } from "react-router-dom";
import { findOwnersForLeaseProperty, forLease, checkUserDetails, getCustomerPendingSla } from '../../../redux/actions/listActions'

class Sla extends Component { // eslint-disable-line react/prefer-stateless-function
    constructor(props){
        super(props)
        this.state  = {
            creditType : 'all',
            redirect : false
        }  
    }  
   
    /**
     * mount component once render the initial component 
     * 
     * @param {*} NA
     * @return {*} NA
    */
    componentDidMount()
    {
        const { user_detail, user } = this.props.authData
        const { searchPropertyList, location } = this.props

        this.getUserDetails();
        this.props.getCustomerPendingSla(user, this);

        if (!user?.simulate) {
            this.interval = setInterval(() => {
                this.props.getCustomerPendingSla(user, this);
            }, 15000);
        }
    }

    componentDidUpdate()
    {
        this.getUserDetails()
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }
 
    render() {

        const { props, state } = this
        const { pendingSla } = props
        
        let user_sla   = pendingSla[0] || {} 

        return (
            <>
                { 
                    (pendingSla?.length == 0) &&
                    <Redirect to="/folio-snap"/>

                    
                }
                <Iframe url={"https://rentperks.com/pub/service_agreement/signServiceLevelAgreement/" + props?.authData?.user?.customer_id + '/' + user_sla?.sla_id} 
                    width="100%"
                    height="650px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"
                />
            </>
        )
    }
    
    /**
     * get user details
     * 
     * @param {object} authData 
     */
    getUserDetails()
    {
        const {isSlaSigned, authData, pendingSla} = this.props
        
        if( !pendingSla?.length )
            this.setState({redirect: "/folio-snap", sign: isSlaSigned})
        else
        {
            let data  = {
                token: authData?.access_token,
                customerId: authData?.user?.customer_id,
                simulate: authData?.simulate?.customer_id
            }
            setTimeout(
                () => this.props.checkUserDetails(data, this), 
                4000
            );   
        }
    }
}

/** validate function is or not */
Sla.propTypes = {
    findOwnersForLeaseProperty: propTypes.func.isRequired,
    forLease: propTypes.func.isRequired,
    checkUserDetails: propTypes.func.isRequired,
    getCustomerPendingSla: propTypes.func.isRequired
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    propertyList: state.dataPack.forLeasePropertyList,
    forLeaseData: state.dataPack.forLeaseData,
    constants: state.dataPack.constants,
    authData: state.dataPack.authData,
    helper: state.dataPack.helper,
    isSlaSigned: state.dataPack.isSlaSigned,
    checkRuns: state.dataPack.checkRuns,
    pendingSla: state.dataPack.pendingSla
})

export default connect (mapStateToProps, {findOwnersForLeaseProperty, forLease, checkUserDetails, getCustomerPendingSla})( Sla )
