import React, {useState} from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types'
import {Button, Modal} from 'react-bootstrap';
import HOME_BLACK from './../../../../component/assets/img/owner-portal/home_black.png'
import {ownerEnquiry} from './../../../redux/actions/listActions'
import { useEffect } from 'react';


const Enquiry = (props) => {

    const [show, setShow] = useState(false);
    const [count, setCount] = useState(false);

    const [comment, setComment] = useState();
    const [address, setAddress] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        let property  = findProperty(props)
        setComment(property?.address);
        setAddress(property?.address);
    }, [props?.property_id])

    return (
        <>
            <div show={show} onHide={props?.onClose} size="lg" className='transparent_box w-full'>
                <div class="modal-header">
                    <div class="modal-title h4">Owner Enquiry</div>
                    <button type="button" class="close" onClick={() => props?.onClose()}>
                        <i className='fa fa-times'></i>
                    </button>
                </div>
                <form id="ownerEnquiry" onSubmit={(el) => handleSubmitEvent(el, setShow, props)}>
                    <Modal.Body>
                            <div className="form-group modal-body-element">
                                <label htmlFor="exampleInputEmail1">Description:</label>
                                <textarea value={comment} onChange={(el) => setComment(el?.target?.value)} className="form-control" name="comments" placeholder="Your Comments here..." rows="3" required minLength="25" maxLength="160" onKeyUp={(el) => setCount( ((el.target.getAttribute('maxLength') ) - (el.target.value.length - (address?.length ? parseInt(address?.length) : 0) ))) }></textarea>
                                <p className="char_counter">Char left <span className="count">{count}</span></p>
                            </div>

                            <div className="form-group modal-body-element">
                                <label htmlFor="exampleInputEmail1">Attachment:</label>
                                <input type="file" name="attachment" className="form-control" />
                                <p className='notes'>We'll get back to you within 48 hours.</p>
                            </div>
                            
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary">Submit Enquiry</button>
                    </Modal.Footer> 
                </form>
            </div>
       </>
    )

    function findProperty(props){

        const { list, match, property_id} = props
        
        if( property_id ){
            return (list?.filter( list => list?.property_id  == property_id))?.values()?.next()?.value
        }
    }
} 

const handleSubmitEvent = (el, setShow, props) => {

    el.preventDefault();

    let form_data    = new FormData();

    form_data.append('document', el.target.attachment.files[0])
    form_data.append('comments', el.target.comments.value)
    form_data.append('userId', props?.authData?.user?.customer_id)

    if( props.ownerEnquiry( form_data  ) ){
        props?.onClose()
        document.getElementById('ownerEnquiry').reset();
    }
}

/** validate function is or not */
Enquiry.propTypes = {
    ownerEnquiry: propTypes.func.isRequired
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    authData: state.dataPack.authData,
})
export default connect (mapStateToProps, {ownerEnquiry})( Enquiry )