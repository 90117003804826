import React from 'react';
import {Button, Modal} from 'react-bootstrap';

const TenantProfile = (props) => {

    return (
        <>
            <Modal show={props.show} size="lg" className="folio_table">
                <Modal.Header>
                    <Modal.Title>{props.suTtitle}</Modal.Title>
                    <Button className="modal-button" variant="" onClick={() => props.handlePopupEvent()}>
                        <i className="fa fa-times-circle fa-2x"></i>
                    </Button>
                </Modal.Header>
                
                <Modal.Body>
                    <p>{props?.show?.lease?.tenant_profile}</p>
                </Modal.Body>
               
            </Modal>
       </>
    )
} 
export default TenantProfile