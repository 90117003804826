import React from 'react'
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom'

import Container from './../../component/structure/container'
import Header from './../../component/layout/header'
import ReactNotification from 'react-notifications-component'
import Footer from './../../component/layout/footer'
import Loader from './../../component/layout/loader'

const privateRoute = ({ component: Component, ...rest }) => {

    const userAccessInfo = rest.authData
    const isRedirection = rest.isRedirection

    return (
        <Route
            {...rest}
            render={(props) => {

                const user  = userAccessInfo?.user

                if (!userAccessInfo) {
                    return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                }

                // if(isRedirection){
                //     props.history.block();
                // }

                if (user?.simulate && props.location.pathname != "/owner/list") {

                    return <Redirect to={{ pathname: '/owner/list', state: { from: props.location } }} />

                } else if (!user?.simulate && props.location.pathname == "/owner/list") {
                    return <Redirect to={{ pathname: '/folio-snap', state: { from: props.location } }} />
                }

                return (
                    <section class="section d-flex align-items-center justify-content-center flex-wrap">
                        <Header {...props} />
                        <div class="container">
                            <ReactNotification />
                            <Container>
                                <Component {...props} />
                            </Container>
                            <Footer {...props} />
                        </div>


                        <Loader />
                    </section>
                )
            }}
        />
    );
}

const mapStateToProps = state => ({
    isLoggedIn: state.dataPack.isLoggedIn,
    authData: state.dataPack.authData,
    isRedirection: state.dataPack.isRedirection
})

export default connect(mapStateToProps, {})(privateRoute)