import React, {Component, useState} from 'react';
import {Link} from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types'
import moment from 'moment'
import ItemsCarousel from 'react-items-carousel';
import NO_PROPERTY_IMAGE from './../../../component/assets/images/home.png'
import PIGGY_BANK_ICON from './../../../component/assets/images/piggy-bank.svg'
import swal from 'sweetalert2';
import {Modal} from 'react-bootstrap'
import ReactDOM from 'react-dom';
import { findPropertyLedger, findLeaseDetails, findPropertyDocument, findPropertyBill, applyNonResident } from './../../../modules/redux/actions/listActions'

class PropertySlider extends Component { // eslint-disable-line react/prefer-stateless-function
    constructor(props){
        super(props)
        this.state = {
            autoPlayDelay : 6000,
            chevronWidth : 0,
            noOfItems : 3,
            noOfCards : 3,
            activeItemIndex: -1,
            propertyId: 0,
            showNonResiPop: false,
            showDiv : false,
            propIdForResi : false
        }
    }
    /**
     * slider rotate
     */
    tick  = () => 
    { 
        const {noOfItems, noOfCards, activeItemIndex }  = this.state
        this.setState(prevState => ({
            activeItemIndex: (activeItemIndex + 1) % (noOfItems-noOfCards + 1),
        }));
    }
    render() {
        const { state, props }  = this
        const { activeItemIndex , chevronWidth} = state
        let index              = props?.propertyList?.findIndex( list => list?.property_id == props?.match?.params?.id)
        let propCount = props?.propertyList?.length

        if(propCount == 1 && this.props.location.pathname == '/tenancy'){
            let f_prop_id = props?.propertyList?.[0]?.property_id
            this.props.history.push('/tenancy/'+f_prop_id)
        }
        return (
            <>
                <div className='row'>
                    <div className='col-md-3 pr-0'>
                        <div className="add_new_prop">
                            <Link to="/add-property">
                                <i className='fa fa-plus-circle fa-4x plus-circle'></i>
                            </Link>
                        </div>
                        <div className="add_new_prop_text">Add New Property</div>
                    </div>
                    <div className='col-md-9'>
                        <div className="dashbord-slider h-100" style={{ padding: `0 ${chevronWidth}px` }}>
                            <ItemsCarousel
                                requestToChangeActive={(el) => this.setState({ activeItemIndex: el })}
                                activeItemIndex={(activeItemIndex >= 0) ? activeItemIndex : index}
                                numberOfCards={3}
                                activePosition={"center"}
                                gutter={20}
                                leftChevron={<button className="ant-btn ant-btn-circle">{'<'}</button>}
                                rightChevron={<button className="ant-btn ant-btn-circle">{'>'}</button>}
                                outsideChevron={true}
                                chevronWidth={chevronWidth}
                                infiniteLoop={true}
                            >
                            {this.renderSliderItems(props)}
                            </ItemsCarousel>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.showNonResiPop} size="lg">
                    <Modal.Header>
                        <h2>NON RESIDENT FOR THIS TENANCY</h2>
                        <button className="close-btn-m" onClick={() => (this.setState({showNonResiPop:false}))}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </Modal.Header>
                    <Modal.Body className="non-resident-pop">
                        <div className="row non-resi-header">
                            <div className="col-md-12">
                                <p>HI. YOU ARE DECLARING YOURSELF AS NON RESIDENT FOR THIS TENANCY. THAT WILL RESULT IN TAX WITH-HOLDING. PLEASE SELECT FROM BELOW:</p>
                            </div>
                        </div>
                        <form className="row" id="frm_non_resident" autoComplete="off" onSubmit={(el) => handleApplyNonResident(el, this, props, 483)}>                            
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="checkbox">
                                        <label htmlFor={"standard-rate"}>
                                            <input 
                                                name="tax_type" 
                                                ref="tax_type" 
                                                id={"standard-rate"} 
                                                type="radio" 
                                                value="STANDARD_RATE" 
                                                onChange={ (el) => this.handleShowInputFields(el) }
                                            /> STANDARD RATE OF WITH-HOLDING
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="checkbox">
                                        <label htmlFor={"reduce-rate"}>
                                            <input 
                                                name="tax_type" 
                                                ref="tax_type" 
                                                id={"reduce-rate"} 
                                                type="radio" 
                                                value="REDUCED_RATE"
                                                onChange={ (el) => this.handleShowInputFields(el) }
                                            /> REDUCED RATE
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={this.state.showDiv== false ? "col-md-12 hide" : 'col-md-12 show'}>
                                <p className="non-resi-note">PLS ATTACH YOUR OFFICIAL TAX AGENCY FORM SHOWING THE REDUCED AMOUNT ENTERED. IF YOU DON’T ATTACH A FILE WE CANNOT REDUCE THE TAX AMOUNT UNTIL APPROVED. WE WILL CONTACT YOU IN THAT CASE.</p>
                            </div>

                            <div className={this.state.showDiv== false ? "col-md-12 hide" : 'col-md-12 show'}>
                                <div className="form-group">
                                    <input type="file" name="document" ref="document" className="form-control-file field_text_style" required="" />
                                    <span className="help-block with-errors"></span>
                                </div>
                            </div>
                            <div className={this.state.showDiv== false ? "col-md-12 hide" : 'col-md-12 show'}>
                                <div className="form-group">
                                    <input type="number" name="tax_rate" ref="tax_rate" placeholder="Enter tax amount (eg: $125.50)" className="field_text_style" required="" />
                                    <span className="help-block with-errors"></span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-danger btn-sm pop-save-btn">SAVE</button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
          );
    }
    /* slider items with images
     * 
     * @param {*} NA
     * @return { response } 
    */
    renderSliderItems(props) 
    {
        const { url, params } = props.match
        const { state } = this
        const { propertyList, constants, match } = props
        
        const urlString  = props.helper.explode(match.path)?.replace('add-property', 'edit-property')

        return propertyList.map((item, index) => {
            const mediaUrl  = (item.property_media && item.property_media.media ) ? item.property_media.media.path + '/thumb/' + item.property_media.media.name : '';
            let isDraft     = (item?.status == 3) ? 'draftProperty' : '';

            return (
                <>
                    <Link key={index} to={'/'+urlString+'/'+item.property_id} className={ isDraft + " item "+( (match.params && item.property_id == match.params.id) ? "activeSlider" : '' )} title={item.address} >
                        <div className={"default-image dash-img"}>
                            <img onError={(el) => el.target.classList.add('hide')} src={constants.MEDIAURL+mediaUrl} alt="Title" className="active" onLoad={() => {"Loading..."}}/>
                        </div>
                        <div className="dash-text"> <p>{item.address}</p> </div>                    
                    </Link>
                    {
                        url.includes('profile') &&
                        
                        <div key={index} className="slider-bank-details">
                            <span>
                                <i className="fa fa-university" aria-hidden="true"></i> {item.account_number != null ? item.account_number : 'Not Selected'}
                            </span>
                            <div className="checkbox">
                                <label htmlFor={"nonResident-" + index}>
                                    <input 
                                        id={"nonResident-" + index} 
                                        type="checkbox" 
                                        defaultChecked={item.foreign_resident == 1 ? 'checked' : ''} 
                                        onChange={ (el) => this.handleSetResidentEvent(el, el.target.checked, item.property_id) } 
                                    /> non-resident
                                </label>
                            </div>
                        </div>
                    }
                </>
            )
        });
    }

    /**
     * get property ledger list on property select
     *   
     * @param {object} el 
     * @param {object} item
     * @return {*}
    */
    handleEvents = async(el, item) => 
     {
        el.preventDefault();
        const { props }     = this
        const propertyId    = item.property_id
        const data      = {
            propertyId  : propertyId,
            creditType  : props.creditType,
            startFrom   : moment(props.start).format('L'),
            endTo       : moment(props.end).format('L')
        }
        this.setState({
            propertyId: propertyId
        }, async() => {
            if( props.match.path  === '/tenancy_bills_docs'){
                await props.findPropertyDocument( propertyId )
                await props.findPropertyBill( propertyId )
            }
            else{
                await props.findPropertyLedger( data )
            }
            await props.findLeaseDetails( propertyId )
        })
     }

    handleSetResidentEvent = (el, checked, propId) => {
        if(el.target.checked){
            this.setState({showNonResiPop:true, propIdForResi: propId})
        } else {
            el.target.checked = false
        }
    }

    handleShowInputFields = (el) => {
        if(el.target.checked && el.target.value == 'REDUCED_RATE'){
            this.setState({showDiv:true})
        } else {
            this.setState({showDiv:false})
        }
    }
}

/**
 * apply non resident for reducing tax
 * 
 * @param {object} el 
 * @return {*}
 */
 const handleApplyNonResident = (el, obj, props, property_id) => {

    el.preventDefault();

    let formData    = new FormData();
    formData.append("property_id", property_id);
    formData.append("tax_type", el.target.tax_type.value);
    formData.append("tax_rate", el.target.tax_rate.value);
    formData.append("document", el.target.document.value);
    
    props.applyNonResident(formData)

    obj.setState({showNonResiPop:false})
}

/** validate function is or not */
PropertySlider.propTypes = {
    findPropertyLedger: propTypes.func.isRequired,
    findLeaseDetails: propTypes.func.isRequired,
    findPropertyDocument: propTypes.func.isRequired,
    findPropertyBill: propTypes.func.isRequired,
    applyNonResident: propTypes.func.isRequired
}
/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    helper: state.dataPack.helper,
    constants: state.dataPack.constants,
})
export default connect (mapStateToProps, {findPropertyLedger, findLeaseDetails, findPropertyDocument, findPropertyBill, applyNonResident})( PropertySlider )
