import React, {Component} from 'react';

const Loader = () => {


    return (
       
        <div className="loadingComponent">
            <div className="overLay"></div>
            <div className="overLayText"></div>
        </div>
       
    )
}


export default Loader