import React, {Component} from 'react';
// import Menus from './../Menu/menus'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import propTypes from 'prop-types'
import { } from './../../../modules/redux/actions/listActions'

const Loader = ( props ) => {

    if( !props.processing ){
        return false
    }
    
    return (
        <>
        
            <div className="main_loader">
                
            </div>
            <div className="_loader load_property">
            <div className="loader"></div>
                {/* Processing... */}
            </div>
        </>
    )

}


const mapStateToProps = state => ({
    processing: state.dataPack.processing
})

export default connect (mapStateToProps, {  } )( Loader )