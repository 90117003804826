import React, {useState} from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types'
import {Link} from "react-router-dom";
import ReactNotification from 'react-notifications-component'
import { forgetPassword } from './../../redux/actions/listActions'

const ForgotPassword = (props) => {
    
    return(
        <div className="font-sans text-gray-900 antialiased">
            <ReactNotification />
            <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100">
                <div>
                    <a href="/"><img src="https://rentperks.com/assets/img/login-logo.png" alt="RentPerks" /></a>
                </div>
                <div className="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
                    <div className="mb-4 text-sm text-gray-600">
                        Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.
                    </div>
                    <form method="POST" action="" onSubmit={(el) => handleSubmitEvent(el, props)}>
                        <div>
                            <label className="block font-medium text-sm text-gray-700" htmlFor="email">Email/Username</label>
                            <input className="rounded-md shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 block mt-1 w-full" id="email" type="text" name="email" required="required" autoFocus="autofocus" autoComplete="off" />
                        </div>

                        <div className="flex items-center d-flex bd-highlight mb-3 mt-4">
                            <Link to="/login" className="mr-auto p-2 bd-highlight underline text-sm text-gray-600 hover:text-gray-900">Login</Link>
                            <button type="submit" className="inline-flex items-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring ring-gray-300 disabled:opacity-25 transition ease-in-out duration-150">
                                Email Password Reset Link
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

/**
 * user authenticate event handle
 * 
 * @param {object} el 
 * @return {*}
 */
const handleSubmitEvent = (el, props) => {

    el.preventDefault();

    let formData    = new FormData();
    formData.append("email", el.target.email.value);
    
    props.forgetPassword(formData)

    el.target.email.value = '';
} 

/** validate function is or not */
ForgotPassword.propTypes = {
    forgetPassword: propTypes.func.isRequired
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    forgotPasswordData: state.dataPack.forgotPasswordData
})
export default connect (mapStateToProps, { forgetPassword })( ForgotPassword )