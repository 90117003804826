import React, {useState} from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types'
import Loader from './../../../component/layout/loader'
import { userAuthenticate } from './../../redux/actions/listActions'
import {Link} from "react-router-dom";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

const Login = (props) => {
    
    return(
        <div className="font-sans text-gray-900 antialiased">
            <ReactNotification />
            <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100">
                <div>
                    <a href="/" className='login_section'>
                        <img src="https://rentperks.com/assets/frontend/images/logo/rp_footer_logo.png" className='login_logo' alt="RP" />
                    </a>
                </div>

            <div className="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
       
                <form method="POST" action="" className='login_form' onSubmit={(el) => handleSubmitEvent(el, props)}>
                    <div>
                        <label className="block font-medium text-sm text-gray-700" htmlFor="email">
                            Email or Username
                        </label>

                        <input  className="rounded-md shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 block mt-1 w-full" id="email" type="text" name="email" required autoFocus />
                    </div>

                    <div className="mt-4">
                        <label className="block font-medium text-sm text-gray-700" htmlFor="password">
                            Password
                        </label>

                        <input  className="rounded-md shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 block mt-1 w-full" id="password" type="password" name="password" required/>
                    </div>

                    <div className="block mt-4">
                        <label htmlFor="remember_me" className="inline-flex items-center">
                            <input id="remember_me" type="checkbox" className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" name="remember" />
                            <span className="ml-2 text-sm text-gray-600">Remember me</span>
                        </label>
                    </div>

                    <div className="flex items-center justify-end mt-4">
                        <Link to="/forget-password" className="underline text-sm text-gray-600 hover:text-gray-900">Forgot your password?</Link>
                
                        <button type="submit" className="inline-flex items-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring ring-gray-300 disabled:opacity-25 transition ease-in-out duration-150 ml-3">
                            Log in
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <Loader />
    </div>
    )
}

/**
 * user authenticate event handle
 * 
 * @param {object} el 
 * @return {*}
 */
const handleSubmitEvent = (el, props) => {

    el.preventDefault();

    let formData    = new FormData();

    formData.append("email", el.target.email.value);
    formData.append("password", el.target.password.value);
    formData.append("remember", el.target.remember.value);
    
    props.userAuthenticate(formData)
} 

/** validate function is or not */
Login.propTypes = {
    userAuthenticate: propTypes.func.isRequired
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    authData: state.dataPack.authData
})
export default connect (mapStateToProps, { userAuthenticate })( Login )