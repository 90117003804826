import React from 'react';
import moment from 'moment'



const Transaction = (props) => {
    
    return (
        <table className="table mt-0">
            <thead>
                <tr><th tabIndex="0">DATE</th>
                <th tabIndex="0">DESCRIPTION</th>
                <th tabIndex="0">AMOUNT</th>
                <th tabIndex="0">BALANCE</th>
                </tr>
              </thead>
            <tbody>
                {
                  renderTable(props)
                }
            </tbody>
        </table>
    )
} 

const renderTable  = (props) => {

  let { ledgers }  = props
  
  return ledgers?.map( function(option, index) { 

    const propertyDetails  = (option && option.ledger && option.ledger.transfer_from) ? option.ledger.transfer_from.property_to : 
                            ((option && option?.ledger && option?.ledger?.transfer_to) ? option.ledger.transfer_to.property_from : null)
    
      return (
        <tr key={index}>
            <td>{option.date}</td>
            <td>{option.description} { propertyDetails && `(${propertyDetails.address ?? null})`}</td>
            <td className="text-right">{option.amount}</td>
            <td className="text-right">{option.balance}</td>
        </tr>
      )
  })
}

export default Transaction