import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {Modal, Button} from 'react-bootstrap'


class BillDue extends Component {

    constructor(props)
    {
        super(props)

        this.state  = {
        }
    }
    
    render() {

        const { props }     = this
        
        return (
            
            <div
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="folio_table transparent_modal_box folio_table_box"
            >
                <Modal.Header>
                    <Modal.Title id="">
                        { props.title }
                        <p className="text">{ props.subtitle }</p>
                    </Modal.Title>
                    <Button className="cross-button" variant="" onClick={() => props.handlePopupEvent()}>
                        <i className="fa fa-times-circle fa-2x"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body>

                    <div className="">
                        <table className="table billDueTable">
                        { this.renderProperty(props)}
                        </table>
                    </div> 
                </Modal.Body>
            </div>
        )
    }
    
    renderProperty(props) {

        const { folioData }   =  props
    
        return folioData?.propertyList?.map(property => {
    
            return (
                <tr>
                    <td>
                        <h4><Link to={"/tenancy/"+property?.property_id}>{ property?.address}</Link></h4>
                        <table  className="table rent_detail_table layoutfixed">
                        {this.renderDocuments(property?.bill_due)}
                        </table>
                    </td>
                </tr>
            )        
        })
    
    }
    renderDocuments(bill_due)
    {
        const { props }       = this
        const { constants }   = props
        const billDueFilter   = bill_due?.filter(list => list.invoice)
       
        if( !billDueFilter?.length )
        {
            return (
                <tr>
                    <td col="3">No Data Found!</td>
                </tr>
            )
        }
        return billDueFilter?.filter(list => list.invoice)?.map( function(option, index) { 
     
            return (

                <tr key={index}>
                    <td>{option.description}</td>
                    <td className="text-right">{constants.CURRENCY_SIGN}{option.amount}</td>
                    <td className="text-right">{moment(option?.added_on).format('MMM DD, YYYY')}</td>
                    <td className="text-right">
                        <a target="_blank" href={props?.constants?.MEDIAURLDEV+option?.invoice?.path+'/'+option?.invoice?.name} download class="download_btn _leased _red"><i className="fa fa-share-square-o" aria-hidden="true"></i> View</a>
                    </td>
                </tr>
            )
        })
    }
}
export default BillDue
