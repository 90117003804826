import React, {useState} from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types'
import {Button, Modal} from 'react-bootstrap';
import {handleImageUpload} from './../../../../redux/actions/listActions'

import 'react-notifications-component/dist/theme.css'

const Upload = (props) => {

    const { constants }     = props

    const [doc, setDoc]     = useState(false);
    const [show, setShow]   = useState(false);
    const [count, setCount] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (doctype) => { setDoc(doctype); setShow(true)};

    return (
        <>
            <div className="col-sm-12 col-md-3 col-lg-3" onClick={() => handleShow(constants.DOCTYPE.OWNERPAY)}>
                <label className="bill_document">
                    <div className="box_icon"><i className="fa fa-user"></i></div>
                    <div className="_link">Add Bill</div>
                    <div className="_link red">I Pay</div>
                </label>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3" onClick={() => handleShow(constants.DOCTYPE.TENANTPAY)}>
                <label className="bill_document">
                    <div className="box_icon"><i className="fa fa-home"></i></div>
                    <div className="_link">Add Bill</div>
                    <div className="_link red">Tenant Pays</div>
                </label>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3" onClick={() => handleShow(constants.DOCTYPE.OTHERDOC)}>
                <label className="bill_document">
                    <div className="box_icon"><i className="fa fa-paperclip"></i></div>
                    <div className="_link">Add <br/> Document</div>
                </label>
            </div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{constants.DOCS[doc]}</Modal.Title>
                </Modal.Header>
                <form id="ownerEnquiry" onSubmit={(el) => handleUploadEvent(el, doc, setShow, props)}>
                    <Modal.Body>
                            {
                                ( constants.DOCSBILL_TENANT_REQUIRE.includes(doc)) && 
                            
                                <div className="form-group modal-body-element">
                                    <label for="exampleInputEmail1">Tenant:</label>
                                    <select className="form-control" required name="tenant">
                                        <option>Select Tenant</option>
                                        { gettenantList(props)}
                                    </select>
                                </div>
                            }
                            <div class="form-group modal-body-element">
                                <label for="exampleInputEmail1">Comments:</label>
                                <textarea class="form-control" name="comments" placeholder="Your Comments here..." rows="3" required maxLength="160" onKeyUp={(el) => setCount( (el.target.getAttribute('maxLength') - el.target.value.length)) }></textarea>
                                <p className="char_counter">Char left <span className="count">{count}</span></p>
                            </div>

                            <div class="form-group modal-body-element">
                                <label for="exampleInputEmail1">Attachment:</label>
                                <input type="file" name="attachment" className="form-control" required/>
                            </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <button type="submit" className="btn btn-primary">Submit Request</button>
                    </Modal.Footer> 
                </form>
            </Modal>
       </>
    )
} 
/**
 * find all tenant for the property
 * 
 * @param {object} props
 * @return {*}
 */
const gettenantList = (props)  => 
{
    const { tenant }    = props.documentBills
    if( tenant && tenant.list )
    {
        return tenant.list.map( function(option){

            return (

                <option value={option.application_id}>{(option && option?.tenant_property) && option?.tenant_property?.customer?.first_name +' '+ option?.tenant_property?.customer?.last_name}</option>
            )
        })
    }
}

/**
 * handle image upload
 * 
 * @param {object} el 
 * 
 * @return {*}
*/
const handleUploadEvent = (el, doc, setShow, props) => 
{
    el.preventDefault();

    const { leaseDetails }  = props
    let file                = el.target.attachment.files[0];

    var formdata = new FormData();
    formdata.append("document", file);
    formdata.append("documentType", doc);
    formdata.append("comments", el.target.comments.value);
    formdata.append("propertyId", leaseDetails.property_id);
    formdata.append("tenantId", (el.target.tenant ? el.target.tenant.value : 0));

    props.handleImageUpload(formdata)
    setShow(false)
}

/** validate function is or not */
Upload.propTypes = {
    handleImageUpload: propTypes.func.isRequired
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    leaseDetails: state.dataPack.leaseDetails,
    constants: state.dataPack.constants
})
export default connect (mapStateToProps, {handleImageUpload})( Upload )