import React, { Component } from 'react';
import Panel from './../../../../modules/blocks/sections/panel';

class UpComing extends Component { // eslint-disable-line react/prefer-stateless-function
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {

        const { props } = this
        return (
            <div className='row justify-content-center section_height'>
                <Panel {...props} />
                <div className='col-lg-10 col-md-10 col-sm-12 col-xl-10'>
                    <div className='row d-flex flex-wrap align-content-end  h-full'>
                        {/* <div class="row w-100"> */}
                            <div className="col-md-12 graph-circle-div  h-full">
                                <h1 className="no_doc_heading text-center color_white d-flex justify-content-center h-full align-items-center">Coming Soon</h1>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        )
    }

}

export default UpComing
