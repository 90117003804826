import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom'
import 'react-notifications-component/dist/theme.css'
import property from '../../property/bills/property';

const Held = (props) => {


    return (
        <>
            <div className="folio_table transparent_modal_box folio_table_box">
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                    <Button className="cross-button" variant="" onClick={() => props.handlePopupEvent()}>
                        <i className="fa fa-times-circle fa-2x"></i>
                    </Button>
                </Modal.Header>
                
                <Modal.Body>
                    <table className="table table-striped table-hover billDueTable layoutfixed">
                        <tbody>
                            {
                                renderProperty(props)
                            }
                        </tbody>
                    </table>
                </Modal.Body>
            </div>
       </>
    )
} 

const renderProperty = (props) => {

    const { folioData, constants,folioLedgers }   =  props

    try {
        
        return folioData?.propertyList?.map(property => {

            let held          = props.helper.held(property, constants)
            let income        = props.helper.banked(property)
            let totalIncome   = parseFloat(income) + parseFloat(held.credit)
            let balance       = parseFloat( totalIncome ) - parseFloat(held.debit)
            let _held         = getHeldForProperty(property, folioLedgers)
            return (
                <>
                    <tr>
                        <td className=""><h4><Link to={"/tenancy/"+property?.property_id}>{ property?.address}</Link></h4></td>
                        <td className="text-right">{props.helper.currencySign(_held?.balanceNF, true)}</td>
                        <td></td>
                    </tr>
                </>
            )
        })
    } catch (error) {
    }
}

/**
 * get proeprty held (closing balance)
 * 
 * @param {object} property 
 * @param {object} folioLedgers 
 * @returns 
 */
const getHeldForProperty = (property, folioLedgers) => {
    
    try {
        
        let ledger  = folioLedgers?.[property?.property_id]?.length
        return folioLedgers?.[property?.property_id][ledger-1]

    } catch (error) {}
}
export default Held