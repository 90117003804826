import React, { Component } from 'react'
import { connect } from 'react-redux';
import Panel from './../panel'
import List from './list'
import moment from 'moment'
import Dates from './../tenent/dates'
import Slider from './../../../../component/structure/slider/buildingSlider'
import Refer from './../panel/refer'
import propTypes from 'prop-types'
import DatePicker from 'react-mobile-datepicker';
import Overlay from './../../../../component/layout/overlay'
import TransactionReport from './transactionReport'
import NoData from './../../../../component/structure/noData'
import { CSVLink } from "react-csv";
import HOME_BLACK from './../../../../component/assets/img/owner-portal/home_black.png'

import { findOwnerBuilding, findBuildingProperty, generateBuildingViewPdf, generatePDF, getLedgersList } from './../../../redux/actions/listActions'

class Building extends Component {
    
    constructor(props)
    {
        super(props)

        this.state  = {
            start: new Date(moment().subtract(3,'months').subtract(1, 'days').format('YYYY-MM-DD')),
            end: new Date(moment().add(1, 'days').format('YYYY-MM-DD')),
            isStart: false,
            isENd: false,
            modalShow:false,
            leasedOnly: false
        }
    }
    /**
     * mount component once render the initial component 
     * 
     * @param {*} NA
     * @return {*} NA
    */
   async componentDidMount()
   { 
      document.title = "Building View | RentPerks"
      const { props } = this
      const { user }  = props.authData

      await props.findOwnerBuilding(user.customer_id)
      await this.handleUpdateEvent()
   }

   /**
     * trigger apis on url param change
     * 
     * @param {object} prevProps
     * @return {*} 
    */
    componentDidUpdate(prevProps) 
    {
      const { props } = this
        const { match }   = this.props

        if( match.params.id != prevProps.match.params.id )
          this.handleUpdateEvent()

        let propCount = props?.buildings?.length

        if(propCount >= 1 && props.location.pathname == '/building'){
            let f_prop_id = props?.buildings?.[0]?.group_id
            props.history.push('/building/'+f_prop_id)
        } 
    }
    render() {
      
      const { props, state }      = this
      const { loading }           = props
      const { propertyList }      = props.buildingProperty
      let filterName              = state.leasedOnly ? 1 :0 ;

        if( props?.buildings?.length == 0 )
            return (
                <div className='row justify-content-center section_height'>
                    <NoData {...props} msg="No Building found! " />
                </div>
              )

        return (
          <div className='row justify-content-center section_height'>
                <Panel {...props} />
                <div className='col-lg-10 col-md-10 col-sm-12 col-xl-10'>
                  <Slider {...props} />
                      {
                          !props.match.params.id &&
                          <Overlay />
                      }
                      {
                        propertyList ? 
                        <div className='row d-flex flex-wrap align-content-end'>
                          <List {... props} leasedOnly={state.leasedOnly} start={state.start} end={state.end}/>  
                          <div class="row w-100">
                            <div class="col-lg-5 col-md-4 col-sm-12 col-xl-5 d-flex flex-wrap align-content-end">
                              <div onClick={() => this.setState({leasedOnly: !state.leasedOnly})} class="details_box bg_gray_opacity d-flex justify-content-center align-items-center flex-column">
                                <i class="fa-solid fa-key fa-2x"></i>
                                <p class="details_box_text color_black">{ props.constants.BUILDING_FILTER_NAME[filterName]}</p>
                              </div>
                              <div class="details_box bg_gold_opacity d-flex justify-content-center align-items-center flex-column">
                                <img src={HOME_BLACK} class="details_box_img" alt="" />
                                <p class="details_box_text color_black">Owner Inquiry</p>
                              </div>
                            </div>
                            <div class="col-lg-7 col-md-8 col-sm-12 col-xl-7">
                              <div class="table_filter h-100 flex-wrap align-content-end d-grid">
                                <div class="row date_range_selector">
                                  <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5 date_range">
                                    <div className="date_selector d-flex" onClick={(el) => this.setState({ isStart: true, isEnd: false, el })} >
                                      <Dates setDate={state.start}/>
                                    </div>
                                  </div>
                                  <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5 date_range">
                                    <div className="date_selector d-flex" onClick={(el) => this.setState({ isEnd: true, isStart: false, el })}>
                                      <Dates setDate={state.end} />
                                    </div>
                                  </div>
                                  <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 text-center d-grid flex-wrap align-content-end">
                                  {
                                            loading ?
                                            <button className="download_button disabled" disabled><i className='fa fa-spinner fa-spin'></i> PDF</button>
                                            :
                                            <button className="download_button" onClick={(el) => this.handlePdfRequest(el)}> PDF</button>
                                        }
                                        {
                                            loading ?
                                            <button className="download_button btn-csv disabled"> <i className='fa fa-spinner fa-spin'></i> CSV</button>
                                                :
                                            <CSVLink className="building_csv_btn download_button" data={this.getCsvData()} filename={"ledger.csv"}>CSV</CSVLink>
                                        }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <div className='row d-flex flex-wrap align-content-end'>
                          <div class="row w-100">
                            <p>No Property found for this building.</p>
                          </div>
                        </div>
                      }
                </div>
                {/* <div className="dashboard-middle parentCell">

                    
                    
                    <div className="dash-form-bg detail-wrapper">

                      {
                          !props.match.params.id &&
                          <Overlay />
                      }
                      {
                        propertyList ? 
                        <List {... props} leasedOnly={state.leasedOnly} start={state.start} end={state.end}/>  
                        :
                        <p>No Property found for this building.</p>
                      }
                      
                      <div className="row date_range_selector">
                        <div className="col-sm-6 col-md-3">
                          <button className="_btn btn-lease shadowbutton" onClick={() => this.setState({leasedOnly: !state.leasedOnly})}>{ props.constants.BUILDING_FILTER_NAME[filterName]}</button>
                        </div>
                        <div className="col-sm-6 col-md-3">
                          <div className="date_selector d-flex" onClick={(el) => this.setState({ isStart: true, isEnd: false, el })} >
                            <Dates setDate={state.start}/>
                          </div>
                          <p>From</p>
                        </div>
                        <div className="col-sm-6 col-md-3">
                          <div className="date_selector d-flex" onClick={(el) => this.setState({ isEnd: true, isStart: false, el })}>
                            <Dates setDate={state.end} />
                          </div>
                          <p>To</p>
                        </div>
                        <div className="col-sm-6 col-md-3 text-center">
                          <button onClick={(el) => this.handlePdfRequest(el)} className="_btn btn-pdf"> PDF</button>
                          <button className="_btn btn-pdf-csv"> CSV</button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <DatePicker 
                    value={this.state.start} 
                    isOpen={this.state.isStart} 
                    isPopup={true} 
                    theme="android" 
                    confirmText="SET" 
                    cancelText="CANCEL" 
                    className="mobStart" 
                    onSelect={(time) => this.setState({ start: time, isStart: false }, () => { this.manageDates('start', time)})} 
                    onCancel={() => this.setState({ isStart: false })} 
                  />

                  <TransactionReport 
                      {...props} 
                      start={ moment(state.start).format('MMMM D YYYY')+'-'+ moment(state.end).format('MMMM D YYYY')}
                      show={state.modalShow}
                      handlePdfGenerat={() => this.handlePdfRender()}
                  />

                  <DatePicker 
                    value={this.state.end} 
                    isOpen={this.state.isEnd} 
                    theme="android" 
                    confirmText="SET" 
                    cancelText="CANCEL" 
                    className="mobEnd" 
                    onSelect={(time) => this.setState({ end: time, isEnd: false }, () => {this.manageDates('end', time)} ) } 
                    onCancel={() => this.setState({ isEnd: false })} 
                />
                {/* <Refer /> */}
            </div>
        )
    }

    handlePdfRequest(el)
    {
        el.preventDefault()

        const data = {
            pdfData: JSON.stringify( document.getElementsByClassName('pdf_box')[0].innerHTML )
        }
       
        this.props.generatePDF(data )
    }

    getCsvData() {

      const { propertyList, buildingLedgers } = this.props
      let csvData = [];
      try {
        Object.keys(buildingLedgers).map(index => {
          let propertyDetails  = propertyList?.filter( list => list?.property_id == index )?.values()?.next()?.value
          
          csvData.push({
            Property: propertyDetails?.address
          })
          buildingLedgers[index]?.map(option => {
            csvData.push({
              date: option?.date,
              description: option?.description,
              amount: option?.amount,
              balance: option?.balance
            })
          })
        })
      } catch (error) { }
      return csvData;
  }

    /**
     * close pdf generat rendering
     * 
     * @param {*} NA
     */
     handlePdfRender(){
        this.setState({
            modalShow:false
        })
      }

    /**
     * generate building view pdf
     * 
     * @param {*} NA
     * @return {*}
     */
    generatBuildingSummaryPdf(el)
    {
      el.preventDefault()
      const { props, state }      = this   
      const { match, authData }   = props

      let data  = {
          groupId: match.params.id,
          leasedOnly: state.leasedOnly,
          startDate: state.start,
          endDate: state.end,
          customerId: authData.user.customer_id
      }
      props.generateBuildingViewPdf(data)
    }

    /**
     * manage start and end dates 
     * 
     * @param {string} type 
     * @return {*}
     */
     manageDates(type)
     {
         const { start, end }    = this.state
         try {
             
             if( type === 'start' )
             {
                 if( moment(start).format('x') > moment(end).format('x') )
                     this.setState({ end: start}, () => {this.handleUpdateEvent() })
                 else
                     this.handleUpdateEvent()
             }
             else if( type === 'end' )
             {
                 if( moment(end).format('x') < moment(start).format('x') )
                     this.setState({ start : end }, () => {this.handleUpdateEvent() })
                 else
                     this.handleUpdateEvent()
             }
         } catch (error) {}
     }
    
    /**
     * handle property change event
     * 
     * @param{*} NA
     * @return {*} 
     */
    async handleUpdateEvent()
    {
        const { props, state }      = this   
        const { match, authData }   = props

        let data  = {
            groupId: match.params.id,
            leasedOnly: state.leasedOnly,
            startDate: moment(state.start).format('L'),
            endDate: moment(state.end).format('L'),
            customerId: authData.user.customer_id
        }

        await props.findBuildingProperty(data)
        await props.getLedgersList(data)
    }
}

/** validate function is or not */
Building.propTypes = {
  findOwnerBuilding: propTypes.func.isRequired,
  findBuildingProperty: propTypes.func.isRequired,
  generateBuildingViewPdf: propTypes.func.isRequired,
  generatePDF: propTypes.func.isRequired,
  getLedgersList: propTypes.func.isRequired
}

/**
 * sending all state to component as props
 *
 * @param {object} state
 */
const mapStateToProps = state => ({
    propertyList: state.dataPack.propertyList,
    helper: state.dataPack.helper,
    authData: state.dataPack.authData,
    buildings: state.dataPack.buildingList,
    buildingProperty: state.dataPack.buildingProperty,
    constants: state.dataPack.constants,
    buildingLedgers: state.dataPack.buildingLedgers,
    loading: state.dataPack?.processing
})

export default connect (mapStateToProps, {findOwnerBuilding, findBuildingProperty, generateBuildingViewPdf, generatePDF, getLedgersList})( Building )
