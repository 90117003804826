import React from "react";
import moment from 'moment'
import Transaction from './transaction'
class TransactionReport extends React.Component {
    
   
    render() {
        const { props } = this
        
        return (
            <div className="w-50 d-none">

                <div className="p-3 mt-3 border rounded pdf_box">
                    <div ref={summarycontainer => (this.summarycontainer = summarycontainer)} className="pdf_container">
                        { 
                            this.renderPdfHeader('SUMMARY REPORT')
                        }
                        <div className="height-auto mt-5">
                            <table className="table summaryTable">
                                <tr>
                                    <td>

                                        <table className="table subtable text-left">
                                            <tr>
                                                <th><div className="pl-2">Income</div></th>
                                                <th></th>
                                            </tr>
                                            
                                            {
                                                this.renderAdminFees()
                                            }
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="height-auto mt-4">
                            <h5 className="text-center pt-2">TRANSACTION REPORT</h5>
                            <div className="tenant-view-table text-center height-auto mt-2">
                                {
                                    this.renderTransactions(props)
                                }
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        );
    }

    renderTransactions(props)
    {
        let ledgers
        const { propertyList }     = this.props?.buildingProperty
        const { buildingLedgers }  = props

        if( propertyList )
        {
           return propertyList?.map( function(property){

                ledgers     = buildingLedgers[property.property_id]
                
                return (
                    <>
                        <h1 className="text-left">{property?.address}</h1>
                        <Transaction {...props} ledgers={ledgers} />
                    </>
                )
           })
        }
    }
    
    /**
     * getting paid rent
     * 
     * @param {*} NA
     * @return {Object}
     */
    renderPaidRent()
    {   
        const { props }                 = this
        const { ledgerList, constants } = props
        const { TYPES }                 = constants

        if( ledgerList )
        {
            return ledgerList.filter(list => list.rents).map(function(ledgers){
                
                return ledgers.rents.map(function(option, index){
                    return (
                        <tr key={index}>
                            <td>{moment(option.paid_on).format('MMM DD, YYYY')}</td>
                            <td className="text-right">{props.helper.currencySign(option.rent)}</td>
                        </tr>
                    )
                })
            })
        }
        return false
    }

    /**
     * getting paid rent
     * 
     * @param {*} NA
     * @return {Object}
     */
     renderTotalRent()
     {  
         let totalRent                  = 0
         const { propertyList }     = this.props?.buildingProperty
         if( propertyList )
         {
            propertyList?.map( function(property){

                property?.tenant_rent.filter(list => list.rent).map(function(rents){
                    
                    totalRent += parseFloat(rents.rent) 
                })
            })
         }
         return totalRent
     }

    /**
     * ledger list in summary view
     * 
     * @param {object} ledgerList
     * @return {Object} ledgerList
     */   
     ledgerDataFromat(ledgerList){

        let sortLedgerDate  = []
        ledgerList.forEach(element => {
            sortLedgerDate.push (element)
        });
    
        return sortLedgerDate.sort((a, b) => a.description.localeCompare(b.description));
    }


    renderAdminFees()
    {
        const { ledgerList, constants, buildingProperty } = this.props
        const { propertyList }                            = buildingProperty

        const { SUMMARY_TYPE }   = constants
        let noValue              = "0.00"

        if( propertyList )
        {
            let summaryData         = this.getSummaryData(constants, propertyList)
            let totalRents          = this.renderTotalRent()
            let paidInAuthTransfer  = (summaryData['authTransfer'] > 0) ? parseFloat(summaryData['authTransfer']) : 0;
            let netProceeds         =  (parseFloat(totalRents) + paidInAuthTransfer) - parseFloat(summaryData['totalExpensesNoFormat'])
            console.log(totalRents, 'totalRents');
            return (
                <>
                    {
                        (summaryData['authTransfer'] != noValue && parseFloat(summaryData['authTransfer']) > 0) && 
                        <tr key="Transfer">
                            <td>Transfers</td>
                            <td className="text-right">{this.props.helper.currencySign(summaryData['authTransfer'])}</td>
                        </tr>
                    }
                    <tr>
                        <td>Rents and Others</td>
                        <td className="text-right">{this.props.helper.currencySign(totalRents)}</td>
                    </tr>
                    <tr>
                        <th><div className="pl-2">Total</div></th>
                        <td className="text-right"><strong className="font-weight-bold">{ this.props.constants.CURRENCY_SIGN}{this.props.helper.currencySign(parseFloat(totalRents) + paidInAuthTransfer)}</strong></td>
                    </tr>
                    <tr>
                        <th colSpan="2"><hr/></th>
                    </tr>
                    <tr className="bt-1">
                        <th><div className="pl-2">Paid Out</div></th>
                        <th></th>
                    </tr>
                    {
                        summaryData[SUMMARY_TYPE.ADMIN_FEE] != noValue && 
                        <tr key={SUMMARY_TYPE.ADMIN_FEE}>
                            <td>{SUMMARY_TYPE.ADMIN_FEE}</td>
                            <td className="text-right">{summaryData[SUMMARY_TYPE.ADMIN_FEE]}</td>
                        </tr>
                    }
                    {
                        summaryData[SUMMARY_TYPE.MANAGEMENT_FEE] != noValue &&
                        <tr key={SUMMARY_TYPE.MANAGEMENT_FEE}>
                            <td>{SUMMARY_TYPE.MANAGEMENT_FEE}</td>
                            <td className="text-right">{summaryData[SUMMARY_TYPE.MANAGEMENT_FEE]}</td>
                        </tr>
                    }
                    {
                        summaryData[SUMMARY_TYPE.MAINTENANCE_FEE] != noValue &&
                        <tr key={SUMMARY_TYPE.MAINTENANCE_FEE}>
                            <td>{SUMMARY_TYPE.MAINTENANCE_FEE}</td>
                            <td className="text-right">{summaryData[SUMMARY_TYPE.MAINTENANCE_FEE]}</td>
                        </tr>
                    }
                    {
                        summaryData[SUMMARY_TYPE.LEASING_FEE] != noValue && 
                        <tr key={SUMMARY_TYPE.LEASING_FEE}>
                            <td>{SUMMARY_TYPE.LEASING_FEE}</td>
                            <td className="text-right">{summaryData[SUMMARY_TYPE.LEASING_FEE]}</td>
                        </tr>
                    }
                    {
                        summaryData[SUMMARY_TYPE.BOND_FEE] != noValue && 
                        <tr key={SUMMARY_TYPE.BOND_FEE}>
                            <td>{SUMMARY_TYPE.BOND_FEE}</td>
                            <td className="text-right">{summaryData[SUMMARY_TYPE.BOND_FEE]}</td>
                        </tr>
                    }
                    {
                        summaryData[SUMMARY_TYPE.MAINTENANCE_BILL] != noValue && 
                        <tr key={SUMMARY_TYPE.MAINTENANCE_BILL}>
                            <td>{SUMMARY_TYPE.MAINTENANCE_BILL}</td>
                            <td className="text-right">{summaryData[SUMMARY_TYPE.MAINTENANCE_BILL]}</td>
                        </tr>
                    }
                    {
                        summaryData[SUMMARY_TYPE.SALES_TAX] != noValue && 
                        <tr key={SUMMARY_TYPE.SALES_TAX}>
                            <td>{SUMMARY_TYPE.SALES_TAX}</td>
                            <td className="text-right">{summaryData[SUMMARY_TYPE.SALES_TAX]}</td>
                        </tr>
                    }
                    {
                        summaryData[SUMMARY_TYPE.NRTR_TAX] != noValue && 
                        <tr key={SUMMARY_TYPE.NRTR_TAX}>
                            <td>{SUMMARY_TYPE.NRTR_TAX}</td>
                            <td className="text-right">{summaryData[SUMMARY_TYPE.NRTR_TAX]}</td>
                        </tr>
                    }

                    {
                        (summaryData['authTransfer'] != noValue && parseFloat(summaryData['authTransfer']) < 0) && 
                        <tr key="Transfer">
                            <td>Transfers</td>
                            <td className="text-right">{this.props.helper.currencySign(summaryData['authTransfer'] * (-1))}</td>
                        </tr>
                    }
                        
                    <tr>
                        <th><div className="pl-2">Total</div></th>
                        <td className="text-right"><strong className="font-weight-bold">{(summaryData['totalExpences'])}</strong></td>
                    </tr>
                    <tr>
                        <th colSpan="2"><hr/></th>
                    </tr>
                    <tr className="bt-1">
                        <th><div className="pl-2">Net Proceeds</div></th>
                        <td className="text-right"><strong className="font-weight-bold">{this.props.helper.currencySign(netProceeds)}</strong></td>
                    </tr>
                </>
            )
        }
        return false
    }


    /**
     * summary view data summaries
     * 
     * @param {object} constants 
     * @param {object} propertyList 
     */
    getSummaryData(constants, propertyList)
    {
        const { props } = this
        let summaryData         = []
        let adminFee            = 0
        let managementFee       = 0
        let leasingFee          = 0
        let boondFee            = 0
        let maintenanceFee      = 0
        let sales               = 0
        let nrtrtax             = 0
        let maintenance         = 0
        let authTransfer        = 0
        const {SUMMARY_TYPE,ADMIN_LEDGER_NOT_IN, COMMA_SIGN, ALL_FEES_TYPES, BUILDING_AUTH_TRANSFER}    = constants
        try { 
            
            propertyList?.map((property) => {

                property?.ledgers.filter(list => !ADMIN_LEDGER_NOT_IN.includes((list.description)?.toLowerCase())).map((option, index) =>{
                  
                    if( BUILDING_AUTH_TRANSFER.includes((option.description)?.toLowerCase()) ){ 

                        if( propertyList?.filter(list => (list.property_id == option?.ledger?.transfer_from?.property_id || list.property_id == option?.ledger?.transfer_to?.property_id) ) )
                        {
                            console.log(option, 'option', option.amount);
                            if( option.type == "credit")
                                authTransfer     += parseFloat((option.amount).replace(COMMA_SIGN, ''))
                            else
                                authTransfer     -= parseFloat((option.amount).replace(COMMA_SIGN, ''))
                        }
                    }

                    if( ALL_FEES_TYPES.ADMIN_FEE.includes((option.description)?.toLowerCase())){ 
                        adminFee     += parseFloat((option.amount).replace(COMMA_SIGN, ''))
                    }
                    else if( ALL_FEES_TYPES.MANAGEMENT_FEE.includes((option.description)?.toLowerCase())){
                        managementFee     += parseFloat((option.amount).replace(COMMA_SIGN, ''))
                    }

                    else if( ALL_FEES_TYPES.LEASING_FEE.includes((option.description)?.toLowerCase()))
                        leasingFee     += parseFloat((option.amount).replace(COMMA_SIGN, ''))

                    else if( ALL_FEES_TYPES.BOND_FEE.includes((option.description)?.toLowerCase()))
                        boondFee     += parseFloat((option.amount).replace(COMMA_SIGN, ''))

                    else if( ALL_FEES_TYPES.MAINTENANCE_FEE.includes((option.description)?.toLowerCase()))
                        maintenanceFee     += parseFloat((option.amount).replace(COMMA_SIGN, ''))

                    else if( option?.work_order ){
                        maintenance     += parseFloat((option.amount).replace(COMMA_SIGN, ''))
                    }

                    else if( ALL_FEES_TYPES.SALES_TAX.includes((option.description)?.toLowerCase()))
                        sales     += parseFloat((option.amount).replace(COMMA_SIGN, ''))

                    else if( ALL_FEES_TYPES.MAINTENANCE_BILL.includes((option.description)?.toLowerCase()))
                        maintenance     += parseFloat((option.amount).replace(COMMA_SIGN, ''))

                    else if( ALL_FEES_TYPES.NRTR_TAX.includes((option.description)?.toLowerCase()))
                        nrtrtax     += parseFloat((option.amount).replace(COMMA_SIGN, ''))
                })
            })
        
            /** formatted data */
            summaryData[SUMMARY_TYPE.ADMIN_FEE]         = props.helper.currencySign(adminFee)

            summaryData[SUMMARY_TYPE.MANAGEMENT_FEE]    = props.helper.currencySign(managementFee)
            summaryData[SUMMARY_TYPE.MAINTENANCE_FEE]   = props.helper.currencySign(maintenanceFee)
            summaryData[SUMMARY_TYPE.LEASING_FEE]       = props.helper.currencySign(leasingFee)
            summaryData[SUMMARY_TYPE.BOND_FEE]          = props.helper.currencySign(boondFee)

            summaryData[SUMMARY_TYPE.SALES_TAX]         = props.helper.currencySign(sales)
            summaryData[SUMMARY_TYPE.MAINTENANCE_BILL]  = props.helper.currencySign(maintenance)
            summaryData[SUMMARY_TYPE.NRTR_TAX]          = props.helper.currencySign(nrtrtax)

            let totalAdminFees                          = (adminFee  + managementFee   + maintenanceFee + leasingFee + boondFee) 
            let totalPaidOut                            = (sales  + maintenance   + nrtrtax)
            let paidOutAuthTransfer                     = (authTransfer < 0) ? parseFloat(authTransfer) : 0
            summaryData['totalAdminFees']               = props.helper.currencySign((adminFee  + managementFee   + maintenanceFee + leasingFee + boondFee) + paidOutAuthTransfer) 
            summaryData['totalPaidOUt']                 = props.helper.currencySign((sales  + maintenance   + nrtrtax) + paidOutAuthTransfer) 
            summaryData['totalExpences']                = props.helper.currencySign(parseFloat(totalAdminFees) + parseFloat(totalPaidOut) - paidOutAuthTransfer)
            summaryData['totalExpensesNoFormat']        = (parseFloat(totalAdminFees) + parseFloat(totalPaidOut)) - paidOutAuthTransfer
            summaryData['authTransfer']                 = authTransfer

        } catch (error) {
        }
        return summaryData
    }
 
     
    renderPdfHeader(title) {

        const { props } = this

        const { buildings, start, buildingProperty }    = props
        const { propertyList }                          = buildingProperty
        
        if( propertyList && props?.match?.params?.id){

            const building   = buildings.filter( list => list.group_id == props?.match?.params?.id )
            const response   = propertyList.filter(list => list.property_id == props?.match?.params?.id)
            const dates      = start.split('-')
       
            if( response )
            {
                const propertyDetails = response[0]
                return (
                    <div>
                        <img src="images/login-logo.png" alt="RentPerks | Logo" className=""/>
                        <h5 className="text-left pt-2">{title}</h5>
                        <p className="text-left mt-3"><b>Building Address:</b> {building && building[0]?.group_name}</p>
                        <p className="text-left"><b>Period Start: </b>{dates[0]}</p>
                        <p className="text-left"><b>Period End:</b> {dates[1]}</p>
                    </div>
                )
            }
        }
    }
}

export default TransactionReport
