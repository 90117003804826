import React, {Component, useState} from 'react';
import propTypes from 'prop-types'
import { connect } from 'react-redux';
import {Button, Modal} from 'react-bootstrap'
import POP_IMG from './../../../../component/assets/img/account_reference.png'
import swal from 'sweetalert2'
import { findOwnerProperty, getProfile, addBankDetails, updateBankDetails, deleteBankDetails, selectBank, updateProfile, addSecondAuthority, deleteSecondAuthority, forRedirection, applyNonResident, copyToAll} from './../../../../modules/redux/actions/listActions'


class Bank extends Component{

    constructor(props)
    {
        super(props)
        this.state  = {
            creditType  : 'all',
            show : false,
            showPop : false,
            showEditPop : false,
            customer_account_id : '',
            account_name : '',
            fin_institution_no : '',
            account_number : '',
            transit : '',
            address: '',
            p_email: '',
            country: 0,
            state: 0,
            city:0,
            personal:false,
            authorization:false,
            banking:false, 
            isBankDetailsEdit: false,
            edit:false,
            cheque:false
        }
    }

    /**
     * mount component once render the initial component 
     * 
     * @param {*} NA
     * @return {*} NA
    */
    componentDidMount()
    {
        document.title = "Profile | RentPerks"
        const { match, authData } = this.props
        this.props.findOwnerProperty(authData.user.customer_id)
        let propId = this.props?.match?.params?.id
        let customer_id = authData?.user?.customer_id
        let type = 'country'
        this.props.getProfile(propId, type, customer_id)
        
        this.setState({
            country:this?.props?.proData?.userLocation?.country,
            state:this?.props?.proData?.userLocation?.state,
            city:this?.props?.proData?.userLocation?.city,
        })
    }

    componentWillMount() {
        const { match, authData } = this.props
        this.props.findOwnerProperty(authData.user.customer_id)
        let propId = this.props?.match?.params?.id
        let customer_id = authData?.user?.customer_id
        let type = 'country'
        this.props.getProfile(propId, type, customer_id)
    }

    componentDidUpdate(prevProps)
    {
        document.title = "Profile | RentPerks"
        const { props } = this
        const {match, authData} = this.props
        if( match?.params?.id != prevProps?.match?.params?.id){
            this.props.findOwnerProperty(authData.user.customer_id)
            let propId = this.props?.match?.params?.id
            let customer_id = authData?.user?.customer_id
            let type = 'country'
            this.props.getProfile(propId, type, customer_id)
        }

        let propCount = props?.propertyList?.length

        if(propCount >= 1 && props?.location?.pathname == '/profile'){
            let f_prop_id = props?.propertyList?.[0]?.property_id
            props.history.push('/profile/'+f_prop_id)
        }
    }

    render(){

        const { props, state } = this
        const { proData, constants, match } = props
        const { address, p_email  }  = state
        const accounts = props?.proData?.accounts
        const countries = props?.proData?.countries
        const states = props?.proData?.states    
        const allState = props?.proData?.allStates    
        const cities = props?.proData?.cities
        const allCities = props?.proData?.allCities
        const selected_bank = props?.proData?.property?.selected_bank
        const foreign_resident = props?.proData?.property?.foreign_resident
        const profile = props?.proData?.profile
        const location = props?.proData?.userLocation
        const customer_id = props?.proData?.profile?.customer_id
        const propId = this.props?.match?.params?.id
        const secondAuthority = props?.proData?.secondAuthority

        let isCheckedEmail = (profile?.details?.email_notify != 'N') ? true : false
        let isCheckedSms = (profile?.details?.sms_notify != 'N') ? true : false

        return (
            <div className='personal-section banking-section'>
                <div class="modal-header">
                    <div class="modal-title h4 banking-title">YOU CAN ASSIGN A BANK ACCOUNT TO ALL YOUR PROPERTIES OR TO INDIVIDUAL ONES. ADD OR DELETE OR EDIT BANK ACCOUNT DETAILS BELOW BUT IF YOU CHANGE A BANK ACCOUNT WITHIN 48 HOURS OF AN EFT BATCH BEING SENT THEN THAT CHANGE WILL NOT BE REGISTERED IN TIME.</div>
                    <button type="button" class="close" onClick={() => props?.onClose()}>
                        <span aria-hidden="true">×</span>
                        <span class="sr-only">Close</span>
                    </button>
                </div>
                <div class="modal-header">
                    <div class="modal-title h4 banking-title">GOT A CHEQUE? PRESS <a href="/" onClick={(el) => this?.handleChequeBoxEvent(el)} className="cheque-box"> HERE</a> TO WORK OUT YOUR BANK ACCOUNT DETAILS</div>
                </div>
                <div className='row'>
                    <div className='col-lg-12 col-xl-12 col-md-12 col-sm-12 text-center mb-3'>
                        <button onClick={() => this?.setState({show: true})} type="submit" class="btn btn-primary custom-btn">Add New</button>
                    </div>
                    <div className='col-lg-12 col-xl-12 col-md-12 col-sm-12 banking-account-section-add'>
                    {
                        this?.renderBankList(props)
                    }
                    </div>
                    <div className='col-lg-12 col-xl-12 col-md-12 col-sm-12 text-center mb-3'>
                        <button type="submit" class="btn btn-primary custom-btn">Assign to My Properties</button>
                    </div>
                </div>
                <form onSubmit={(el) => this?.handlePropertyBankAssociate(el, props)}>
                    <div className='row mt-3'>
                        {
                            this.renderProperty(props)
                        }
                    </div>
                    <div class="text-right mt-3 p-0">
                        <button type="submit" class="btn btn-primary">Save Changes</button>
                    </div>
                </form>

                <Modal show={state?.show} size="lg" className="bankDetails">
                    <Modal.Header>
                        <h2>ADD BANK DETAILS</h2>
                        <button className="close-btn-m" onClick={() => (this.setState({show:false}))}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="row d-flex justify-content-center" id="frm_add_bank_details" autoComplete="off" onSubmit={ async(el) =>{ 
                                await handleAddBankDetailsSubmitEvent(el, props, customer_id); 
                                this.setState({show: false});
                                this.props.getProfile(propId, 'country', customer_id)
                                }}
                        > 
                            <div className="col-md-10">  
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="form-group text-center">
                                            <input type="text" name="a_account_name" id="a_account_name" placeholder="Account Name *" className="field_text_style w-full" required />
                                            <span className="help-block with-errors"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group text-center">
                                            <input type="text" name="a_fin_institution_no" id="a_fin_institution_no"  placeholder="Fin Institution No *" className="field_text_style w-full" required />
                                            <span className="help-block with-errors"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group text-center">
                                        <input type="text" name="a_account_number" id="a_account_number"  placeholder="Account Number *" className="field_text_style w-full" required />
                                            <span className="help-block with-errors"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group text-center">
                                            <input type="text" name="a_transit" id="a_transit" placeholder="Transit / Branch *" className="field_text_style w-full" required autoComplete="off" />
                                            <span className="help-block with-errors"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-right">
                                        <button type="submit" className="custom-button">SAVE</button>
                                    </div>
                                </div>                      
                            </div>
                        </form>
                        <div className="bank-dtls-head row modal-header">
                            <div>
                                <h5>BANKING DETAILS FROM YOUR CHEQUE</h5>
                            </div>
                            <div className="pop-img-sect">
                                <img src={POP_IMG} className="img-fluid" />
                            </div>
                            <div className="pop-footer-sect">
                                <p>Account numbers are 7-12 digits, branch is 5 digits and financial institution is 3.</p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={state?.edit} size="lg" className="bankDetails">
                    <Modal.Header>
                        <h2>{ state?.isBankDetailsEdit ? "Edit" : "Add" } BANK DETAILS</h2>
                        <button className="close-btn-m" onClick={() => (this.setState({edit:false}))}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="row d-flex justify-content-center" id="frm_edit_bank_details" autoComplete="off" onSubmit={(el) => {handleUpdateBankDetailsSubmitEvent(el, props, customer_id)}}>                            
                            <div className="col-md-10">  
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="form-group text-center">
                                            <input onChange={(el) => this?.updateAccountName(el, state?.isBankDetailsEdit)} type="text" name="a_account_name" id="a_account_name" placeholder="Account Name *" className="field_text_style w-full" required value={state?.isBankDetailsEdit?.account_name} />
                                            <span className="help-block with-errors"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group text-center">
                                            <input onChange={(el) => this?.updateAccountRoute(el, state?.isBankDetailsEdit)} type="text" name="a_fin_institution_no" id="a_fin_institution_no"  placeholder="Fin Institution No *" className="field_text_style w-full" required value={state?.isBankDetailsEdit?.route} />
                                            <span className="help-block with-errors"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group text-center">
                                        <input onChange={(el) => this?.updateAccountNumber(el, state?.isBankDetailsEdit)} type="text" name="a_account_number" id="a_account_number"  placeholder="Account Number *" className="field_text_style w-full" required value={state?.isBankDetailsEdit?.account_number} />
                                            <span className="help-block with-errors"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group text-center">
                                            <input onChange={(el) => this?.updateAccountTransit(el, state?.isBankDetailsEdit)} type="text" name="a_transit" id="a_transit" placeholder="Transit / Branch *" className="field_text_style w-full" required value={state?.isBankDetailsEdit?.transit} autoComplete="off" />
                                            <span className="help-block with-errors"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-right">
                                        <button type="submit" className="custom-button">Update</button>
                                        <input type="hidden" name="customer_account_id" value={state?.isBankDetailsEdit?.customer_account_id} />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="bank-dtls-head row modal-header">
                            <div>
                                <h5>BANKING DETAILS FROM YOUR CHEQUE</h5>
                            </div>
                            <div className="pop-img-sect">
                                <img src={POP_IMG} className="img-fluid" />
                            </div>
                            <div className="pop-footer-sect">
                                <p>Account numbers are 7-12 digits, branch is 5 digits and financial institution is 3.</p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={state?.cheque} size="lg" className="bankDetails">
                    <Modal.Header>
                        <h5>BANKING DETAILS FROM YOUR CHEQUE</h5>
                        <button className="close-btn-m" onClick={() => (this.setState({cheque:false}))}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="bank-dtls-head row">
                            <div className="col-md-12 pop-img-sect">
                                <img src={POP_IMG} className="img-fluid" />
                            </div>
                            <div className="col-md-12 pop-footer-sect">
                                <p>Account numbers are 7-12 digits, branch is 5 digits and financial institution is 3.</p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

    handleChequeBoxEvent(el){
        el.preventDefault()
        this.setState({
            cheque:true
        })
    }

    updateAccountName(el, isBankDetailsEdit)
    {
        let isBankDetails  = isBankDetailsEdit
        isBankDetails['account_name'] = el.target.value
        this.setState({
            isBankDetailsEdit: isBankDetails
        })
    }

    updateAccountRoute(el, isBankDetailsEdit)
    {
        let isBankDetails  = isBankDetailsEdit
        isBankDetails['route'] = el.target.value
        this.setState({
            isBankDetailsEdit: isBankDetails
        })
    }

    updateAccountNumber(el, isBankDetailsEdit)
    {
        let isBankDetails  = isBankDetailsEdit
        isBankDetails['account_number'] = el.target.value
        this.setState({
            isBankDetailsEdit: isBankDetails
        })
    }

    updateAccountTransit(el, isBankDetailsEdit)
    {
        let isBankDetails  = isBankDetailsEdit
        isBankDetails['transit'] = el.target.value
        this.setState({
            isBankDetailsEdit: isBankDetails
        })
    }

    renderProperty(props){

        return props?.propertyList?.map((property, key) => {

            return (
                <>
                    <div className='col-lg-8 col-xl-8 col-md-8 col-sm-12'>
                        <input type="text" className='field_text_style' value={property?.address} />
                        <input type="hidden" name={`property-${key}`} ref={`property-${property?.property_id}`} value={property?.property_id} />
                    </div>
                    <div className='col-lg-4 col-xl-4 col-md-4 col-sm-12'>
                        <select name={`bank-${key}`} ref={`bank-${property?.customer_account_id}`} className="field_text_style bg-w" required>
                            <option value="">bank Account Name</option>
                            {
                                this?.renderBankAccount(props, property)
                            }
                        </select>
                    </div>
                </>
            )
        })
    }

    renderPropertyList(props, index)
    {
        return props?.propertyList?.map((property, key) => {

            let selected  = (index == key) ? "selected" : "";

            return (
                <option selected={selected} value={ property?.property_id }>{ property?.address } </option>
            )
        })
    }

    renderBankAccount(props, property)
    {
        const accounts = props?.proData?.accounts
        return accounts?.map(account => {

            let selected  = account?.customer_account_id == property?.customer_account_id ? "selected" : ""
            return (
                <option selected={selected} value={account?.customer_account_id}>{account?.account_name}</option>
            )
        })
    }

    renderBankList(props)
    {
        const accounts = props?.proData?.accounts
        return accounts?.map(account => {
            return (
                <>
                    <div className='col-lg-2 col-xl-2 col-md-6 col-sm-12'>
                        <input type="text" name="account_name" ref="account_name" placeholder="Account Name*" defaultValue={account?.account_name} className="field_text_style" required="" />
                    </div>
                    <div className='col-lg-2 col-xl-2 col-md-6 col-sm-12'>
                        <input type="text" name="account_number" ref="account_number" placeholder="Account Number*" defaultValue={account?.account_number} className="field_text_style" required="" />
                    </div>

                    <div className='col-lg-2 col-xl-2 col-md-6 col-sm-12'>
                        <input type="text" name="account_transit" ref="account_transit" placeholder="Account Transit*" defaultValue={account?.transit} className="field_text_style" required="" />
                    </div>
                    <div className='col-lg-2 col-xl-2 col-md-6 col-sm-12'>
                        <input type="text" name="account_route" ref="account_route" placeholder="Bank*" defaultValue={account?.route} className="field_text_style" required="" />
                    </div>
                    <div className='col-lg-2 col-xl-2 col-md-6 col-sm-12'>
                        <div className='form-group'>
                            <button onClick={() => this?.setState({isBankDetailsEdit: account, edit:true})} type="submit" class="btn btn-primary custom-button">Edit</button>
                        </div>
                    </div>
                    <div className='col-lg-2 col-xl-2 col-md-6 col-sm-12'>
                        <div className='form-group'>
                            <button onClick={ (el) => handleDeleteEvent(el, props, account?.customer_account_id) } type="submit" class="btn btn-primary custom-button-delete">Delete</button>
                        </div>
                    </div>
                </>
            )
        })
    }

    handlePropertyBankAssociate(el, props)
    {
        el.preventDefault();
        let formData    = new FormData();

        props?.propertyList?.map((property, key) => 
        {
            formData.append(el.target['property-'+key].value, el.target['bank-'+key].value);
        });
        props.selectBank(formData, 0, props) 
    }
}

const countriesSection = ( countries, location) => {
    return countries.map((item, index) => {
        return (
            <option key={index} value={item.location_id}>{item.name}</option>
        )
    });
}

const statesSection = ( states, location, state ) => {

    const country  = state?.country ?? location?.country
    return states?.filter(list => list?.parent  == country)?.map((item, index) => {
        return (
            <option key={index} value={item.location_id}>{item.name}</option>
        )
    });
}

const citiesSection = ( cities, location, state ) => {
    const province  = state?.state ?? location?.state

    console.log(province, 'province');
    return cities?.filter(list => list?.parent ==  province)?.map((item, index) => {
        return (
            <option key={index} value={item.location_id}>{item.name}</option>
        )
    });
}

const handleDeleteEvent = (el, props, id) => {

    swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
            let formData    = new FormData();
            formData.append("id", id);
            props.deleteBankDetails(formData, props)
            swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            )
        }else if (result.dismiss === "cancel") {
            swal.fire(
                "Cancelled",
                "Not Changed.",
                "error"
            )
        }
      })
}

/**
 * add bank details
 * 
 * @param {object} el 
 * @return {*}
 */
const handleAddBankDetailsSubmitEvent = async(el, props, customer_id) => {

    el.preventDefault();
    const { match, authData } = props

    let formData    = new FormData();
    formData.append("account_name", el.target.a_account_name.value);
    formData.append("eft_qid", el.target.a_fin_institution_no.value);
    formData.append("route", el.target.a_fin_institution_no.value);
    formData.append("account_number", el.target.a_account_number.value);
    formData.append("transit", el.target.a_transit.value);
    formData.append("customer_id", customer_id);
    formData.append("customer_account_id", el?.target?.customer_account_id?.value);

    formData.append("property_id", props?.match?.params?.id);
    formData.append("type", "country");

    await props.addBankDetails(formData)
    this?.setState({show:false})
} 

/**
 * add bank details
 * 
 * @param {object} el 
 * @return {*}
 */
const handleUpdateBankDetailsSubmitEvent = (el, props, customer_id) => {

    el.preventDefault();

    let formData    = new FormData();

    formData.append("account_name", el.target.a_account_name.value);
    formData.append("eft_qid", el.target.a_fin_institution_no.value);
    formData.append("route", el.target.a_fin_institution_no.value);
    formData.append("account_number", el.target.a_account_number.value);
    formData.append("transit", el.target.a_transit.value);
    formData.append("customer_id", customer_id);
    formData.append("customer_account_id", el?.target?.customer_account_id?.value);
    
    props.updateBankDetails(formData)
}

/** validate function is or not */
Bank.propTypes = {
    findOwnerProperty: propTypes.func.isRequired,
    getProfile: propTypes.func.isRequired,
    addBankDetails: propTypes.func.isRequired,
    updateProfile: propTypes.func.isRequired,
    updateBankDetails: propTypes.func.isRequired,
    deleteBankDetails: propTypes.func.isRequired,
    addSecondAuthority: propTypes.func.isRequired,
    forRedirection: propTypes.func.isRequired,
    deleteSecondAuthority: propTypes.func.isRequired,
    applyNonResident: propTypes.func.isRequired,
    selectBank: propTypes.func.isRequired,
    copyToAll: propTypes.func.isRequired,
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    authData: state.dataPack.authData,
    propertyList: state.dataPack.propertyList,
    proData: state.dataPack.profileData,
})
export default connect (mapStateToProps, {findOwnerProperty, getProfile, addBankDetails, updateBankDetails, deleteBankDetails, selectBank, updateProfile, addSecondAuthority, deleteSecondAuthority, forRedirection, applyNonResident, copyToAll})( Bank )