import React, { Component } from 'react'
import { connect } from 'react-redux';
import moment from 'moment'

import propTypes from 'prop-types'
import { findPropertyDocument, findPropertyBill, generateConditionReport } from './../../../../redux/actions/listActions'

class Tenant extends Component {

    constructor(props)
    {
        super(props)
        this.state = {

            applicationId: 0,
            applicationIdLease: 0
        }
    }
    
    componentDidMount()
    {
        const { leaseDetails }   = this.props

        if(leaseDetails){
            this.props.findPropertyDocument( leaseDetails.property_id )
            this.props.findPropertyBill( leaseDetails.property_id )
        }
    }

    gettenantList()
    {
        const { tenant }    = this.props.documentBills
        if( tenant && tenant.list )
        {
            return tenant.list.map( function(option){

                return (

                    <option data-application={option?.lease?.application_id} value={option?.customer?.customer_id}>{option?.customer?.first_name +' '+ option?.customer.last_name}</option>
                )
            })
        }
    }
    render() {

        const { state }     = this
        
        return (
            <div className="col-sm-6 col-md-4">
                <div className="_tenant_document">
                    <div className="headline">Tenant</div>
                    <div className="col-sm-12 col-md-12 _rows">
                        <div className="form-group">
                            <select name="application_type" className="field_text_style bg-w" required onChange={(el) => { this.setState({ applicationIdLease: el.target[el.target.selectedIndex].getAttribute('data-application'), applicationId:el.target.value })}}>
                                <option value="">Tenant Name</option>
                                { this.gettenantList()}
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 _rows text-center"><button className={"_leased _blue "+( !state.applicationId && 'opacity')} onClick={(el) => {this.handleLeaseDetailsEvent(el) }}>View Lease</button></div>
                    <div className="col-sm-12 col-md-12 left_docx_box">
                        <table className="table doc_table">
                            {   
                                state.applicationId ?
                                this.renderDocuments() : ''
                            }
                            {   
                                state.applicationId ?
                                this.renderBills() : ''
                            }
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    renderDocuments()
    {
        const { props, state }    = this
        const { documentBills }   = this.props
        const { MEDIAURLDEV }     = this.props.constants
        
        try {
            
            const {cr}                              = documentBills?.property?.ownerDocument
        
            const filterData                        = cr ? (cr.filter(list => (list.customer_id == state?.applicationId || list?.lease?.application?.applicant_id == state?.applicationId ))): []
            
            return filterData.map( function(option, index) { 
                console.log( option, 'optdfdsfion');
                return (

                    <tr key={index}>
                        <td><i className="fa fa-file-pdf-o" aria-hidden="true"></i> Condition Report</td>
                        <td>{moment(option.date_added).format('MMM  D, Y')}</td>
                        <td>
                            {
                                <a href="javascript:void(0)" className="dash-edit btn _leased _red plr-20" onClick={(el) => this.handlePdfEvents(el, option)}> View</a>
                            }
                        </td>
                    </tr>
                )
            }, this)
        } catch (error) {}
        return false
    }

    /**
     * get all property storage list
     * 
     * @param {*} NA 
     * @returns 
     */
     renderBills() {

        const { state } = this
        const { DATEFORMAT, MEDIAURLDEV } = this.props.constants
        const { documentBills }   = this.props
        
        try {
            const {storage} = documentBills?.property?.ownerDocument

            if( storage )
            {
                return storage?.filter(list => list.tenant_id && state?.applicationId == list.tenant_id)?.map((option) => {

                    return (
                        <tr>
                            <td className="w-30" title={option?.title}> <i className="fa fa-file-pdf-o" aria-hidden="true"></i>{ (option?.title).substr(0,40) }</td>
                            <td>{ moment(option?.media?.added_on).format( DATEFORMAT ) }</td>
                            <td><a href={MEDIAURLDEV+option?.media?.path + '/' + option?.media?.name} target="_blank" download class="btn _leased _red">View</a></td>
                        </tr>
                    )
                })
            }
        } catch (error) {
            
        }
        return false
    }

    handleLeaseDetailsEvent(el)
    {
        el.preventDefault();

        const { applicationId } = this.state
        const { applicationIdLease } = this.state
        
        const { MEDIAURL }  = this.props.constants
        if( !applicationIdLease )
        {
            alert('Please Select Tenant to open the lease details')
            return false;
        }
        window.open(MEDIAURL+"uploads/property_application/"+applicationIdLease+"/lease.pdf");
    }

    /**
     * handle link event to generate the pdf
     * 
     * @param {object} el 
    */
    handlePdfEvents(el, option)
    {
         el.preventDefault();
         
         const {constants} = this.props
         if( option?.entry_type == "C")
             window.open(constants?.MEDIAURLDEV + option?.signed_pdf?.path + '/' +option?.signed_pdf?.name);
         else
             window.open(constants?.MEDIAURLDEV + 'pub/get_condition_report_pdf/'+option?.condition_report_id);
    }
 

    /**
     * get property ledger list on property select
     *   
     * @param {object} el 
     * @return {*}
    */
     async handleEvents(el)
     {
        el.preventDefault();
    
        this.setState({

        creditType: el.target.getAttribute('creditType')
        }, () => {

            const { props, state }     = this
            const propertyId    = props.leaseDetails.property_id

            const data      = {
                propertyId  : propertyId,
                creditType  : state.creditType,
                startFrom   : "",
                endTo       : ""
            }
            props.findPropertyLedger(data)
        })
     }
}

/** validate function is or not */
Tenant.propTypes = {
    findOwnerProperty: propTypes.func.isRequired,
    findPropertyDocument: propTypes.func.isRequired,
    findPropertyBill: propTypes.func.isRequired,
    generateConditionReport: propTypes.func.isRequired,
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    propertyList: state.dataPack.propertyList,
    constants: state.dataPack.constants,
    helper: state.dataPack.helper,
    ledgerList: state.dataPack.ledgerList,
    leaseDetails: state.dataPack.leaseDetails,
    tenantList: state.dataPack.tenantList,
    propertyBill: state.dataPack.propertyBill.worOrder,
    documentBills: state.dataPack.documentBills,
    billDue: state.dataPack.propertyBill.billDue
})

export default connect (mapStateToProps, { findPropertyDocument, findPropertyBill, generateConditionReport })( Tenant )
