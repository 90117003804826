import React, { Component } from "react";
// import Menus from './../Menu/menus'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import propTypes from "prop-types";
import {} from "./../../../modules/redux/actions/listActions";

class Footer extends Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {};
  }
  /* component mount once elements initialized*/
  componentWillMount() {}
  render() {
    

    return (
      <footer>
       
      </footer>
    );
  }
}

/** validate */
Footer.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Footer);
