import React, {Component} from 'react';
import { connect } from 'react-redux';

import propTypes from 'prop-types'

import 'react-notifications-component/dist/theme.css'
import {findAutheenticateUser, alertTest} from './../../modules/redux/actions/listActions'

class Container extends Component { // eslint-disable-line react/prefer-stateless-function
    
    constructor(props){
        super(props)
    }  

    /**
     * call authenticated User data
     * 
     * @param {*} NA
     * @return {*}
     */
    componentDidMount()
    {
        const { authData }  = this.props
        const AUTH_USER     = this.props.helper.getAuthUser()

        if( !authData && AUTH_USER )
            this.props.findAutheenticateUser(AUTH_USER)
    }
 
  render() {

        const { props } = this

        return (
<>
                    
                    {   
                        props.children
                    }
            </>
        )
    }
}


/** validate function is or not */
Container.propTypes = {
    findAutheenticateUser: propTypes.func.isRequired,
    alertTest: propTypes.func.isRequired
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    authData: state.dataPack.authData,
    helper:state.dataPack.helper
})
export default connect (mapStateToProps, {findAutheenticateUser, alertTest})( Container )