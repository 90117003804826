import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import RUG, {DragArea, Card} from 'react-upload-gallery'
import 'react-upload-gallery/dist/style.css'


class Upload extends Component {

    constructor(props)
    {
        super(props)
        this.state  = {
            showUpload  : false,
            propertyImages  : [],
            propertyRequest: '',
            descLength  : 0,
            words: 0,
            accept: props?.constants?.FILEACCEPT
        }
    }

    render()
    {
        const { props }     = this
        let propertyIndex   = 0

        return (

            <Modal show={props.show} size="lg" className="folio_table manage_property_pop">
                <Modal.Header>
                    <Modal.Title>First Image will be lead image.</Modal.Title>
                    <Button className="cross-button" onClick={() => props.handleUploadPopup()}>
                        <i className="fa fa-times-circle fa-2x"></i>
                    </Button>
                </Modal.Header>
                
                <Modal.Body>
                    <RUG
                        autoUpload={false}
                        inOrder={true}
                        initialState={ props.propertyImages }
                        
                        onChange={(images) => {this.filterImages(props, images)} }
                        onDeleted={(currentImage, images) => {
                            props.handleStoreImages(images.filter(list => list?.uid != currentImage?.uid))} 
                        }
                        rules={{
                            limit: 20,
                            size: 200000,
                        }}
                        accept= {props.accept}
                        onWarning={(type, rules) => {
                            
                            console.log(type, rules, 'test');
                        }}
                    >
                        <DragArea
                            className="rug-items __card __sorting">
                            {(image) => (
                                <div className="rug-card">
                                    <div className="rug-card-name">
                                        <div className="text-center">{"Item "+ ++propertyIndex}
                                            
                                        </div>
                                    </div>
                                    <div className="rug-card-image" style={{backgroundImage: 'url('+image.source+')'}}></div>
                                    <div className="rug-card-upload-button">
                                        <svg viewBox="0 -5 32 52"><g><polyline points="1 19 1 31 31 31 31 19"></polyline>
                                        <polyline className="__arrow" points="8 9 16 1 24 9"></polyline>
                                        <line className="__arrow" x1="16" x2="16" y1="1" y2="25"></line></g></svg>
                                    </div>
                                    <div className="rug-card-remove" onClick={() => {image.remove()}}>
                                        <svg viewBox="0 0 40 40"><path stroke="current" stroke-linecap="round" stroke-width="4" d="M 10,10 L 30,30 M 30,10 L 10,30"></path></svg>
                                    </div>
                                </div>
                                )
                            }
                        </DragArea>
                    </RUG>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.handleUploadPopup()}>
                        Close
                    </Button>
                </Modal.Footer> 
            </Modal>
        )
    }

    
    /**
     * filter images type
     * @param {object} props 
     * @param {object} images 
     */
    async filterImages(props, images){
        console.log(images);
        const acceptedExtension = props?.accept
        let filteredImages = [], rejected = []
        try {
            images?.map(image => {

                if(image?.done)
                    filteredImages.push(image)

                else{
                    let explode = image?.name?.split('.');
                    let extention = explode[explode?.length - 1]

                    if( acceptedExtension.includes(extention) )
                        filteredImages.push(image)
                    else
                        rejected.push(image?.name)
                }
            })

            await props.handleStoreImages(filteredImages);
            if(rejected?.length)
                await props.warningMsg('Found unsupported file - ' + rejected?.join(', ') )
         
            this.forceUpdate()
            
        } catch (error) {
            console.log('test', error);
        }
    }
}
export default Upload
