import React, {Component} from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types'
import ItemsCarousel from 'react-items-carousel';
import {Link} from 'react-router-dom';
import Slider from "react-slick";
import NO_PROPERTY_IMAGE from './../../../component/assets/images/home.png'
import { forLease } from './../../../modules/redux/actions/listActions'
import DEFAULT_IMAGE from './../../../component/assets/img/owner-portal/default_image.png'

class ForLeaseSlider extends Component { // eslint-disable-line react/prefer-stateless-function

    constructor(props){

        super(props)

        this.state = {
            autoPlayDelay : 20000,
            chevronWidth : 0,
            noOfItems : 12,
            noOfCards : 4,
            activeItemIndex: 1,
            propertyId: 0
        }
    }
    
    componentDidMount()
    {   
        const { autoPlayDelay } = this.state
        this.interval = setInterval( this.tick , autoPlayDelay);
    }

    tick  = () => 
    { 
        const {noOfItems, noOfCards, activeItemIndex }  = this.state

        this.setState(prevState => ({
            activeItemIndex: (activeItemIndex + 1) % (noOfItems-noOfCards + 1),
        }));
    }

    render() {

        const { state, props }  = this
        const { activeItemIndex , chevronWidth} = state

        let propCount = props?.propertyList?.length

        if(propCount == 1 && this.props.location.pathname == '/for-lease'){
            let f_prop_id = props?.propertyList?.[0]?.property_id
            this.props.history.push('/for-lease/'+f_prop_id)
        }

        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            centerPadding: "20px",
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 820,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
        };

        return (
            <>
                <Slider {...settings}>
                    {
                        this.renderSliderItems(props)
                    }
                </Slider>
            </>
          );
    }

    

    /* slider items with images
     * 
     * @param {*} NA
     * @return { response } 
    */
    renderSliderItems_(props) 
    {
        const { state } = this
        const { propertyList, constants, match } = props
        const urlString  = props.helper.explode(match.path)
       
        return propertyList.map((item, index) => { 

            const mediaUrl  = (item?.property_media && item?.property_media.media ) ? item?.property_media?.media?.path + '/thumb/' + item?.property_media?.media?.name : ''

            return (
                
                // <Link key={index} to={'/'+urlString+'/'+item?.property_id} className={"item "+( (match?.params && item?.property_id == match?.params.id) ? "activeSlider" : '' )} title={item.address}  >
                //     <div className={"default-image dash-img "+( (item?.property_id == state?.propertyId) ? "activeSlider" : '' )}>
                //         <img onError={(el) => el.target.classList.add('hide')} src={constants?.MEDIAURL+mediaUrl} className="active" onLoad={() => {"Loading..."}}/>
                //     </div>
                //     <div className="dash-text"> <p>{item?.address}</p> </div>
                // </Link>
                <>
                    <Link key={index} to={'/'+urlString+'/'+item.property_id} className={"item "+( (match.params && item.property_id == match.params.id) ? "activeSlider" : '' )} title={item.address} >
                        <div className={"default-image dash-img"}>
                            <img onError={(el) => el.target.classList.add('hide')} src={constants.MEDIAURL+mediaUrl} alt="Title" className="active" onLoad={() => {"Loading..."}}/>
                        </div>
                        <div className="dash-text"> <p>{item.address}</p> </div>                    
                    </Link>

                    <div className="slider-bank-details">
                        <span>
                            <i className="fa fa-university" aria-hidden="true"></i> {item.account_number != null ? item.account_number : 'Not Selected'}
                        </span>
                        <div className="checkbox">
                            <label htmlFor={"nonResident-" + index}>
                                <input 
                                    id={"nonResident-" + index} 
                                    type="checkbox" 
                                    defaultChecked={item.foreign_resident == 1 ? 'checked' : ''} 
                                    onChange={ (el) => this.handleSetResidentEvent(el, el.target.checked, item.property_id) } 
                                /> non-resident
                            </label>
                        </div>
                    </div>
                </>
            )
        });
    }

    /* slider items with images
     * 
     * @param {*} NA
     * @return { response } 
    */
    renderSliderItems(props) 
    {
        const { state } = this
        const { propertyList, constants, match } = props
        const urlString  = props.helper.explode(match.path)

        return propertyList.map((item, index) => { 

            const mediaUrl  = (item?.property_media && item?.property_media.media ) ? item?.property_media?.media?.path + '/thumb/' + item?.property_media?.media?.name : ''

            return (
                <Link title={item.group_name} key={index} to={'/'+urlString+'/'+item.property_id} className={"item "+( (match.params && item.property_id == match.params.id) ? "activeSlider" : '' )} >
                    <div className='swiper-slide'>
                        <div className="slider-wrapper d-flex flex-column">
                            <div className="property-image">
                                <img src={constants.MEDIAURL+mediaUrl} onError={(el) => el.target.src = DEFAULT_IMAGE}/>
                            </div>
                            <div className="property_details">
                                <div className="property_title"><p title={item.address}>{(item.address).substring(0,24)}</p></div>
                                <div className="row property_details_list">
                                <div className="col-lg-6 col-md-6 property_details_heading">Monthly Rent</div>
                                <div className="col-lg-6 col-md-6 property_details_value">{item?.lease ? `$${props?.helper?.currencySign(item?.lease?.rent)}` : `${props?.helper?.currencySign(item?.rent)}`}</div>
                                <div className="col-lg-6 col-md-6 property_details_heading">Arrears</div>
                                <div className="col-lg-6 col-md-6 property_details_value">{item?.ar?.total_debt_rent ? `$${item?.ar?.total_debt_rent}` : "0.0"}</div>
                                <div className="col-lg-6 col-md-6 property_details_heading">rent Due Date</div>
                                <div className="col-lg-6 col-md-6 property_details_value">{
                                            item?.ar?.day_lease_start == 1 ?
                                            "1st" :
                                            (
                                                item?.ar?.day_lease_start == 2 ?
                                                "2nd" :
                                                (
                                                    item?.ar?.day_lease_start == 3 ?
                                                    "3rd"
                                                    :
                                                    item?.ar?.day_lease_start+'th'
                                                )
                                            )
                                        }</div>
                                </div>
                                <div className="row">
                                <div className="col-lg-12">
                                    <div className="property_details_button">
                                    <a href="">Site Link</a>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            )
        });
    }

    /**
     * get property ledger list on property select
     *   
     * @param {object} el 
     * @param {object} item
     * @return {*}
    */
    handleEvents = async(el, item) => 
     {
        el.preventDefault();

        const { props }     = this
        const propertyId    = item.property_id

        const data      = {
            propertyId  : propertyId,
        }

        this.setState({
            propertyId: propertyId
        }, async() => {
            await props.forLease( propertyId )
        })
     }
}

/** validate function is or not */
ForLeaseSlider.propTypes = {
    forLease: propTypes.func.isRequired,
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    helper: state.dataPack.helper,
    constants: state.dataPack.constants
})

export default connect (mapStateToProps, {forLease})( ForLeaseSlider )