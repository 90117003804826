import React, { Component } from 'react'
import { connect } from 'react-redux';
import Panel from './../panel'
import List from './list'
import Details from "./details"
import moment from 'moment'
import Dates from './../tenent/dates'
import Refer from './../panel/refer'
import propTypes from 'prop-types'
import DatePicker from 'react-mobile-datepicker';
import Overlay from './../../../../component/layout/overlay'

import { findOwnerBuilding, findFolioProperty, findRecentRents, getFolioLedgersList, userLogout, setNotificationsViewed } from './../../../redux/actions/listActions'

class FolioSnap extends Component {

  constructor(props) {
    super(props)

    this.state = {
      start: new Date(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')),
      end: new Date(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')),
      isStart: false,
      isENd: false,
      modalShow: false,
      leasedOnly: false
    }
  }
  /**
   * mount component once render the initial component 
   * 
   * @param {*} NA
   * @return {*} NA
  */
  async componentDidMount() {
    document.title = "Building View | RentPerks"
    const { props } = this
    const { user } = props.authData

    let data  = {
      customerId: user.customer_id
    }
    await props.findFolioProperty(data)
    await this.handleUpdateEvent()

    await props.findRecentRents()
    await props.getFolioLedgersList(user.customer_id)
  }

  /**
    * trigger apis on url param change
    * 
    * @param {object} prevProps
    * @return {*} 
   */
  componentDidUpdate(prevProps) {
    const { match } = this.props

    if (match.params.id != prevProps.match.params.id)
      this.handleUpdateEvent()
  }

  render() {

    const { props } = this
    const { folioData } = props

    return (
      <div className='row justify-content-center section_height'>
        <Panel {...props} />
        <div className='col-lg-10 col-md-10 col-sm-12 col-xl-10'>
          <Details {...props}/>
        </div>
        {/* <div className="dashboard-middle parentCell">
                    
                    <div className="dash-form-bg detail-wrapper">
                      {
                        folioData ? 
                        <Details {... props} />  
                        :
                        <p>No Property found for this building.</p>
                      }
                    </div>
                  </div>
                  
                <Refer /> */}
      </div>
    )
  }



  /**
   * handle property change event
   * 
   * @param{*} NA
   * @return {*} 
   */
  async handleUpdateEvent() {
    const { props, state } = this
    const { match, authData } = props

    let data = {
      groupId: match.params.id,
      leasedOnly: state.leasedOnly,
      startDate: moment(state.start).format('L'),
      endDate: moment(state.end).format('L'),
      customerId: authData.user.customer_id
    }

    await props.findFolioProperty(data)
  }
}

/** validate function is or not */
FolioSnap.propTypes = {
  findOwnerBuilding: propTypes.func.isRequired,
  findFolioProperty: propTypes.func.isRequired,
  findRecentRents: propTypes.func.isRequired,
  getFolioLedgersList: propTypes.func.isRequired,
  userLogout: propTypes.func.isRequired,
  setNotificationsViewed:propTypes.func.isRequired
}

/**
 * sending all state to component as props
 *
 * @param {object} state
 */
const mapStateToProps = state => ({
  propertyList: state.dataPack.propertyList,
  helper: state.dataPack.helper,
  authData: state.dataPack.authData,
  buildings: state.dataPack.buildingList,
  folioData: state.dataPack.folioData,
  constants: state.dataPack.constants,
  recentRent: state.dataPack.recentRent,
  folioLedgers: state.dataPack.folioLedgers,
  searchPropertyList: state.dataPack.searchPropertyList,
  userNotification: state.dataPack.userNotification,
})

export default connect(mapStateToProps, { setNotificationsViewed, findOwnerBuilding, findFolioProperty, findRecentRents, getFolioLedgersList, userLogout })(FolioSnap)
