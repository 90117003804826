import React, {Component} from 'react';
import { connect } from 'react-redux';
import RightImage from './../../../../component/assets/images/right-img.jpg'
import {Modal} from 'react-bootstrap'
import propTypes from 'prop-types'
import { findAllWCResources, sendReferral } from './../../../../modules/redux/actions/listActions'
import swal from 'sweetalert2'

class Refer extends Component { // eslint-disable-line react/prefer-stateless-function
    constructor(props){
        super(props)
        this.state  = {
            show : false,
            showReferal : false,
            fields: {},
            errors: {}
        }
    }

    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if(!fields["name"]){
            formIsValid = false;
            errors["name"] = "Please enter full name";
        }

        if(!fields["properties_no"]){
            formIsValid = false;
            errors["properties_no"] = "Please enter no of properties";
        } 

        if(!fields["address"]){
            formIsValid = false;
            errors["address"] = "Please enter address";
        } 

        if(!fields["email"]){
            formIsValid = false;
            errors["email"] = "Please enter your email";
        } 

        if(!fields["mobile"]){
            formIsValid = false;
            errors["mobile"] = "Please enter your mobile";
        } 

       this.setState({errors: errors});
       return formIsValid;
    }

    handleChange(field, e){         
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }

    contactSubmit(e){
        e.preventDefault();
        if(this.handleValidation()){
            let customer_id = this.props?.authData?.user?.customer_id
            let formData    = new FormData();
            formData.append("name", e.target.ref_name.value);
            formData.append("properties_no", e.target.ref_properties_no.value);
            formData.append("address", e.target.ref_address.value);
            formData.append("email", e.target.ref_email.value);
            formData.append("mobile", e.target.ref_mobile.value);
            formData.append("customer_id", customer_id);
            this.props.sendReferral(formData)
            this.setState({showReferal:false, fields: {}, errors:{}})
        }  
    }

    /**
     * mount component once render the initial component 
     * 
     * @param {*} NA
     * @return {*} NA
    */
    componentDidMount()
    {
        const { match, authData } = this.props
        this.props.findAllWCResources(this.props?.constants?.RESOURCE_TYPE?.OP_WHATS_COMING)
    }
 
    render() {

        const { props } = this
        const resource_c = props?.allResourcesWCData
        const { constants } = props
        const customer_id = props?.proData?.profile?.customer_id

        return (
            <>
                {
                    props?.refer &&
                    <div show={true} size="lg" className="folio_table transparent_box folio_table_box renting_table">
                        <Modal.Header>
                            <div className="modal-title h4">Refer a Friend</div>
                            <button className="cross-button custom_close" onClick={(el) => this?.props?.onReferClose(el)}><i class="fa fa-times" aria-hidden="true"></i></button>
                        </Modal.Header>
                        <Modal.Body>                                                  
                            <div className="refer-friend-body banking-title">
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-xl-12 text-center refer-friend-para">
                                        <p>If you have a colleague that might enjoy our services please complete
                                        below & he or she will be contacted by us. As a courtesy they will
                                        receive an initial email telling them you have referred them.</p>
                                        <p>Referral rewards are cash or discounted management fees to the
                                        value of <b>$500* per door managed.</b></p>
                                        <p>This is shared between <b>you and the new client.</b></p>
                                    </div>
                                </div>
                                <form autoComplete="off" onSubmit={this.contactSubmit.bind(this)} className="referForm">
                                    <div className="row d-flex justify-content-center align-item-middle mt-5">
                                        <div className="col-md-8 col-lg-8 col-xl-8">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12 col-xl-12">
                                                    <div className="form-group">
                                                        <input type="text" name="ref_name" placeholder="Name *" className="field_text_style w-full" onChange={this.handleChange.bind(this, "name")} required="" />
                                                        <span className="help-block with-errors">{this.state.errors["name"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="number" name="ref_properties_no" placeholder="Number of tenancies *" className="field_text_style w-full"  onChange={this.handleChange.bind(this, "properties_no")} required="" />
                                                        <span className="help-block with-errors">{this.state.errors["properties_no"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="text" name="ref_address" placeholder="Location *" className="field_text_style w-full" onChange={this.handleChange.bind(this, "address")} required="" />
                                                        <span className="help-block with-errors">{this.state.errors["address"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="email" name="ref_email" placeholder="Email *" className="field_text_style w-full" onChange={this.handleChange.bind(this, "email")} required="" />
                                                        <span className="help-block with-errors">{this.state.errors["email"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="tel" name="ref_mobile" placeholder="Phone *" className="field_text_style w-full" onChange={this.handleChange.bind(this, "mobile")} required="" />
                                                        <span className="help-block with-errors">{this.state.errors["mobile"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <button type="submit" className="btn btn-block btn-primary btn-lg">SEND</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Modal.Body>
                    </div>
                }
                {
                    props?.upcoming &&
                    <div show={true} size="lg" className="folio_table transparent_box folio_table_box renting_table">
                        <Modal.Header>
                            <div class="modal-title h4">What's Coming</div>
                            <button className="cross-button custom_close" onClick={(el) => this?.props?.onReferClose(el)}><i class="fa fa-times" aria-hidden="true"></i></button>
                        </Modal.Header>
                        <Modal.Body>                                                  
                            <div className="resources-middle">
                                <div className="row">
                                    { resourceLoop_c(resource_c, props) }
                                </div>
                            </div>
                        </Modal.Body>
                    </div>
                }
            </>
        )
    }
}

const resourceLoop_c = (resource_c, props) => {
    return resource_c.map((item, index) => {
        let media = ''
        let file_name = ''
        const mediaUrl  = (item?.media?.path) ? item?.media?.path : ''
        let file_name_original  = (item?.media?.name) ? item?.media?.name : ''
        file_name  = (item?.media?.name) ? item?.media?.name : ''
        if(item?.media?.description == 'pdf'){
            file_name = 'thumbs/' + file_name.substr(0, file_name.lastIndexOf(".")) + ".png";
        }
        media = <img className="card-img-top" src={props?.constants?.MEDIAURL+mediaUrl+file_name} />
        return (
            <div key={index} className="col-md-12">
                <a href={props?.constants?.MEDIAURL+mediaUrl+file_name_original} target="_blank">
                    <div className="card">
                    <div className="row">
                        <div className="col-md-3 card-media">{media}</div>
                        <div className="col-md-9 card-body">
                            <h5 className="card-title">{item?.name}</h5>
                            <p className="card-text">{item?.value}</p>
                        </div>
                    </div>
                    </div>
                </a>
            </div>
        )
    });
}

/** validate function is or not */
Refer.propTypes = {
    findAllWCResources: propTypes.func.isRequired,
    sendReferral: propTypes.func.isRequired,
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
 const mapStateToProps = state => ({
    allResourcesWCData: state.dataPack.allResourcesWCData,
    constants: state.dataPack.constants,
    authData: state.dataPack.authData,
})

export default connect (mapStateToProps, {findAllWCResources, sendReferral})( Refer )