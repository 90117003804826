import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types'
import moment from 'moment'
import ItemsCarousel from 'react-items-carousel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NO_PROPERTY_IMAGE from './../../../component/assets/images/home.png'
import DEFAULT_IMAGE from './../../../component/assets/img/owner-portal/default_image.png'

import { findPropertyLedger, findLeaseDetails, findPropertyDocument, findPropertyBill } from './../../../modules/redux/actions/listActions'


class BuildingSlider extends Component { // eslint-disable-line react/prefer-stateless-function

    constructor(props){

        super(props)

        this.state = {
            autoPlayDelay : 6000,
            chevronWidth : 0,
            noOfItems : 12,
            noOfCards : 4,
            activeItemIndex: 1,
            propertyId: 0
        }
    }
    
    componentDidMount()
    {   
        const { autoPlayDelay } = this.state
    }

    tick  = () => 
    { 
        const {noOfItems, noOfCards, activeItemIndex }  = this.state

        this.setState(prevState => ({
            activeItemIndex: (activeItemIndex + 1) % (noOfItems-noOfCards + 1),
        }));
    }

    render() {

        const { state, props }  = this
        const { activeItemIndex , chevronWidth} = state
        const { buildingList }   = props

        let propCount = props?.buildingList?.length
        
        if(propCount == 1 && this.props.location.pathname == '/building'){
            let f_prop_id = props?.buildingList?.[0]?.group_id
            this.props.history.push('/building/'+f_prop_id)
        }

        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            centerPadding: "20px",
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 820,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
        };
        
        return (
            <div className="dashbord-slider" style={{ padding: `0 ${chevronWidth}px` }}>
                {
                    buildingList && buildingList?.length ?
                    <Slider {...settings}>
                        {
                            this.renderSliderItems(props)
                        }
                    </Slider> :

                    <h1 className="text-center pt-5">No Building Found!</h1>
                }
            </div>
          );
    }

    

    /* slider items with images
     * 
     * @param {*} NA
     * @return { response } 
    */
    renderSliderItems(props) 
    {
        const { buildingList, constants, match }    = props
        const urlString                             = props.helper.explode(match.path)

        return buildingList.map((item, index) => { 

            const mediaUrl  = (item.details && item.details.media ) ? item.details.media.path + '/' +item.details.media.name : ''
            const propertyDetails = this?.getPropertyDetails(props, item?.group_id)

            return (
                <Link title={item.group_name} key={index} to={'/'+urlString+'/'+item.group_id} className={"item "+( (match.params && item.group_id == match.params.id) ? "activeSlider" : '' )} >
                    <div className='swiper-slide'>
                        <div className="slider-wrapper d-flex flex-column">
                            <div className="property-image">
                                <img src={constants.MEDIAURL+mediaUrl} onError={(el) => el.target.src = DEFAULT_IMAGE}/>
                            </div>
                            <div className="property_details">
                                <div className="property_title"><p title={item.group_name}>{(item.group_name).substring(0,24)}</p></div>
                                <div className="row property_details_list">
                                <div className="col-xl-6 col-lg-6 col-md-6 property_details_heading">Monthly Rent</div>
                                <div className="col-xl-6 col-lg-6 col-md-6 property_details_value">{propertyDetails?.rent ? `$${props?.helper?.currencySign(propertyDetails?.rent)}` : `0.0`}</div>
                                <div className="col-xl-6 col-lg-6 col-md-6 property_details_heading">Arrears</div>
                                <div className="col-xl-6 col-lg-6 col-md-6 property_details_value">{propertyDetails?.debt ? `$${propertyDetails?.debt}` : "0.0"}</div>
                                <div className="col-xl-7 col-lg-6 col-md-6 property_details_heading">rent Due Date</div>
                                <div className="col-xl-5 col-lg-6 col-md-6 property_details_value">1st</div>
                                </div>
                                <div className="row">
                                <div className="col-lg-12">
                                    <div className="property_details_button">
                                        <a href={`/building/${item?.slug}`} onClick={(el) => this.handleDocument(el)}target="_blank">Site Link</a>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            )
        });
    }

    getPropertyDetails(props, group_id)
    {
        const { buildingProperty } = props
        let rent = 0;
        let debt = 0;
        
        buildingProperty?.propertyList?.filter(list => list?.group_id == group_id)?.map( options => {
            rent    += parseFloat(options?.lease?.rent)
            debt    += parseFloat(options?.lease?.ar?.total_debt_rent)
        })
        return {
            rent: rent,
            debt:debt
        }
    }

    handleDocument(el)
    {
        el.preventDefault();
        let url = 'https://rentperks.com'+el?.target?.getAttribute('href');
        window.open(url, '_blank');
        return true
    }

    /**
     * get property ledger list on property select
     *   
     * @param {object} el 
     * @param {object} item
     * @return {*}
    */
    handleEvents = async(el, item) => 
     {
        el.preventDefault();

        const { props }     = this
        const propertyId    = item.property_id

        const data      = {
            propertyId  : propertyId,
            creditType  : props.creditType,
            startFrom   : moment(props.start).format('L'),
            endTo       : moment(props.end).format('L')
        }

        this.setState({
            propertyId: propertyId
        }, async() => {

            if( props.match.path  === '/tenancy_bills_docs'){

                await props.findPropertyDocument( propertyId )
                await props.findPropertyBill( propertyId )
            }
            else{
                await props.findPropertyLedger( data )
            }
            await props.findLeaseDetails( propertyId )
        })
     }
}

/** validate function is or not */
BuildingSlider.propTypes = {
    findPropertyLedger: propTypes.func.isRequired,
    findLeaseDetails: propTypes.func.isRequired,
    findPropertyDocument: propTypes.func.isRequired,
    findPropertyBill: propTypes.func.isRequired
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    helper: state.dataPack.helper,
    constants: state.dataPack.constants,
    buildingList: state.dataPack.buildingList
})

export default connect (mapStateToProps, {findPropertyLedger, findLeaseDetails, findPropertyDocument, findPropertyBill})( BuildingSlider )