import React, { Component } from 'react'
import { connect } from 'react-redux';

import Panel from './../../panel'
import Refer from './../../panel/refer'
import Tenant from './tenant'
import Property from './property'
import Slider from './../../../../../component/structure/slider'
import { findOwnerProperty, findPropertyDocument, findPropertyBill, findLeaseDetails } from './../../../../redux/actions/listActions'

import propTypes from 'prop-types'


class Index extends Component {

    constructor(props)
    {
        super(props)

        this.state  = {
            creditType : 'all',
            noBill     : props.constants.CURRENCY_SIGN+'0.00',
            applicationIdLease: 0
        }
    }
    /**
     * mount component once render the initial component 
     * 
     * @param {*} NA
     * @return {*} NA
    */
    componentDidMount()
     { 
        const {props}   = this
        const { authData, match } = props
        this.props.findOwnerProperty(authData.user.customer_id)

        let propertyId = match.params.id
        if( propertyId ){
            props.findPropertyDocument( propertyId )
        }
     }

    /**
     * trigger apis on url param change
     * 
     * @param {object} prevProps
     * @return {*} 
    */
    async componentDidUpdate(prevProps) 
    {   
        const { props }     = this
        const { match }     = props
        const propertyId    =  match.params.id

        if( propertyId != prevProps.match.params.id){
            await props.findLeaseDetails( propertyId )
            await props.findPropertyDocument( propertyId )
            await props.findPropertyBill( propertyId )
        }
    }
    
    render() {

        const { props, state }     = this
        
        return (
            <>
                <Panel {...props} />
                <div className="dashboard-middle">

                    <Slider {...props} creditType={ state.creditType } />

                    <div className="dash-form-bg detail-wrapper" id="home-1">
                        <div className="row tenant_panel">
                            <div className="col-sm-12">
                                <div className="_property_document">
                                    <div className="col-sm-12">
                                        <Property {...props} applicationId={state?.applicationId}/>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <br/>
                            {/* <Tenant {...props} /> */}
                            {/* <Property {...props} /> */}
                        </div>
                    </div>
                </div>
                <Refer />
            </>
        )
    }

    getTenantList()
    {
        const { tenant }    = this.props?.documentBills
        if( tenant && tenant.list )
        {
            return tenant.list.map( function(option){

                return (

                    <option data-application={option?.lease?.application_id} value={option?.customer?.customer_id}>{option?.customer?.first_name +' '+ option?.customer.last_name}</option>
                )
            })
        }
    }

    /**
     * get property ledger list on property select
     *   
     * @param {object} el 
     * @return {*}
    */
     async handleEvents(el)
     {
        el.preventDefault();
    
        this.setState({

        creditType: el.target.getAttribute('creditType')
        }, () => {

            const { props, state }     = this
            const propertyId    = props.leaseDetails.property_id

            const data      = {
                propertyId  : propertyId,
                creditType  : state.creditType,
                startFrom   : "",
                endTo       : ""
            }
            props.findPropertyLedger(data)
        })
     }
}

/** validate function is or not */
Index.propTypes = {
    findOwnerProperty: propTypes.func.isRequired,
    findPropertyDocument: propTypes.func.isRequired,
    findPropertyBill: propTypes.func.isRequired,
    findLeaseDetails: propTypes.func.isRequired
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    propertyList: state.dataPack.propertyList,
    constants: state.dataPack.constants,
    helper: state.dataPack.helper,
    ledgerList: state.dataPack.ledgerList,
    leaseDetails: state.dataPack.leaseDetails,
    authData: state.dataPack.authData,
    propertyBill: state.dataPack.propertyBill.worOrder,
    documentBills: state.dataPack.documentBills,
})

export default connect (mapStateToProps, {findOwnerProperty, findPropertyDocument, findPropertyBill, findLeaseDetails})( Index )
