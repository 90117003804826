const constants = {
    SERVER_URL:"https://api.owners.rentperks.com/",
    APP_URL: "https://api.owners.rentperks.com/api/",
    SANCTUM_URL: 'sanctum/csrf-cookie',
    CURRENCY_SIGN: "$",
    LEASED: "Leased",
    VACANT: 'FOR LEASE',
    STATUS: {
        LEASED: 1,
        VACANT: 0
    },
    MEDIAURL: process.env.REACT_APP_MEDIA_URL,
    MEDIAURLDEV: process.env.REACT_APP_MEDIA_URL,
    LOCALDEV: process.env.REACT_APP_MEDIA_URL,
    DOCTYPE: 
    {
        OWNERPAY: 1,
        TENANTPAY: 2,
        OTHERDOC: 3
    },
    RESOURCE_TYPE: 
    {
        OP_RESOURCE: 'op_resources',
        OP_WHATS_COMING: 'op_whats_coming',
    },
    DATEFORMAT: "DD MMM, YYYY",
    LEDGER_ENTRY_NOT_IN: ['transfer to landlord', 'transfer to lan', 'paid to you'],
    TYPES : {
        ALL : 'all',
        DEBIT : 'debit',
        CREDIT : 'credit',
        RENT : 'Rent'
    },
    DOCS : {
        1 : 'ADD BILL',
        2 : 'ADD TENANT BILL',
        3 : 'ADD DOCUMENT'
    },
    BUILDING_FILTER_NAME: [
        "Leased Only",
        "View All"
    ],
    DOCSBILL_TENANT_REQUIRE: [2],
    BUILDING_FEES_ENTRY: ['admin fee', 'monthly admin f', 'management fee', 'admin maintenance fee', 'monthly admin fee', 'lease fee', 'leasing fee', 'monthly fee'],
    BUILDING_AUTH_TRANSFER: ['auth transfer'],
    ADMIN_FEES_LEDGER: ['admin fee', 'management fee gst', 'monthly admin f', 'management fee', 'admin maintenance fee', 'monthly admin fee'],
    ADMIN_LEDGER_TRANSACTION: ['transfer from', 'transfer to'],
    ADMIN_LEDGER_NOT_IN: ['opening balance', "rent", 'card fee'],
    SUMMARY_TYPE: {
        ADMIN_FEE: 'Admin Fee',
        MANAGEMENT_FEE: 'Management Fee',
        LEASING_FEE: 'Leasing Fee',
        BOND_FEE: 'Bond Fee',
        MAINTENANCE_FEE: 'Maintenance Fee',

        MAINTENANCE_BILL: 'Maintenance Bill',
        SALES_TAX: "Sales Tax",
        NRTR_TAX: "NRTR TAX",
        OPENING_BALANCE: 'Opening Balance',
        RENT: 'Rent'
    },
    ALL_FEES_TYPES: {
        ADMIN_FEE: ['admin fee', 'monthly fee', 'monthly admin fee','monthly admin f'],
        MANAGEMENT_FEE: ['management fee'],
        LEASING_FEE: ['lease fee', 'leasing fee'],
        BOND_FEE: ['monthly bond Fee', 'monthly bond fe', 'monthly admin fee'],
        MAINTENANCE_FEE: ['admin maintenance fee'],
        SALES_TAX: ['management fee + sales tax', 'lease fee + lease tax', 'lease fee tax', 'lease sales tax', 'rent tax', 'tax', 'sales tax','leasing fee gst', 'management fee gst'],
        MAINTENANCE_BILL: ['maintenance bill'],
        NRTR_TAX: ['nrtr tax'],
    },
    COMMA_SIGN: ",",
    RECENTRENTID: '/folio-snap/recentPayment',
    AUTH_TRANSACTIONS: ['transfer from', 'transfer to', 'auth transfer'],
    BLOCK_PROPERTY:3,
    MEDIA_UPLOAD_URL: "https://www.rentperks.com/pub/UploadDocsBills/property_media",
    UTILITIES_NAME: {
        '10' : 'Water', '11' : 'Heat/Gas'
    },
    DEFAULT_LOACTION : {
        COUNTRY: 1,
        STATE: 6,
        CITY: 7
    },
    FILEACCEPT : ['jpg', 'jpeg', 'png', 'gif', 'bmp'],
    NOTSHOWUTILITIES: [1,4,5,6,7,8,9,10,11,12,13,14,15],
    TENANTINSURANCE: "Tenant insurance is mandatory for this tenancy and a condition of this lease. A valid tenant insurance policy will be held at all times and be presented on request.",
    MESSAGE_FREQUENCY: {
        ONE_TIME: 1,
        RECURRING: 2
    },
    CONDITION_REPORT_TYPE:{1 : 'ENTRY', 2 : 'EXIT', 3: 'Interim'}
};

export default constants
