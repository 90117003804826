import React, { Component } from 'react'
import { connect } from 'react-redux';
import Panel from './../panel'
import Refer from './../../../../modules/blocks/sections/panel/refer'
import SimpleReactValidator from 'simple-react-validator';
import Form from './form/update'
import Slider from './../../../../component/structure/slider/propertySlider'
import propTypes from 'prop-types'

import { 
    findPropertyList, getPropertyType, getPropertLocation, getStates, savePropertyInformation, getUtility, 
    findPropertyMedia, updatePropertyInformation, warningMsg 
} from './../../../redux/actions/listActions'

class Edit extends Component {

    constructor(props)
    {
        super(props)
        this.state  = {
            modalShow   : false
        }
        /** validator initialize */
        this.validator = new SimpleReactValidator();
    }

    /**
     * mount component once render the initial component 
     * 
     * @param {*} NA
     * @return {*} NA
    */
    componentDidMount()
    {
        document.title = "Propert Update | RentPerks"
        const { match, authData } = this.props        
        this.props.findPropertyList(authData.user.customer_id)
        this.props.getPropertyType()
        this.props.getPropertLocation('country', 'all')
        this.props.getUtility()
        this.props.findPropertyMedia(match?.params?.id)
    }

    componentDidUpdate(prevProps)
    {
        const { match } = this.props 
        if( match.params.id != prevProps.match.params.id )
            this.props.findPropertyMedia(match?.params?.id)
    }
     
    render() {

        const { props, state } = this
        return (
            <>
                <Panel {...props} />
                <div className="dashboard-middle property-add-edit">

                    <Slider {...props} creditType={ state.creditType } />
                    <Form {...props} />
                    
                </div>
                <Refer />
            </>
        )
    }
    
}
/** validate function is or not */
Edit.propTypes = {
    findPropertyList: propTypes.func.isRequired,
    getPropertyType: propTypes.func.isRequired,
    getPropertLocation: propTypes.func.isRequired,
    getStates: propTypes.func.isRequired,
    savePropertyInformation:  propTypes.func.isRequired,
    getUtility: propTypes.func.isRequired,
    findPropertyMedia: propTypes.func.isRequired,
    updatePropertyInformation: propTypes.func.isRequired,
    warningMsg: propTypes.func.isRequired,
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    propertyList: state.dataPack.propertyList,
    propertyTypeData: state.dataPack.propertyTypeData,
    propertyLocationData: state.dataPack.propertyLocationData,
    constants: state.dataPack.constants,
    helper: state.dataPack.helper,
    authData: state.dataPack.authData,
    stateCity: state.dataPack.stateCity,
    utilities: state.dataPack.utilities,
    media: state.dataPack.media,
})

export default connect (mapStateToProps, {
    findPropertyList, 
    getPropertyType, 
    getPropertLocation, 
    getStates, 
    savePropertyInformation, 
    getUtility, 
    findPropertyMedia, 
    updatePropertyInformation,
    warningMsg
})( Edit )
