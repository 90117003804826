import React, { Component } from 'react';
import { Redirect } from 'react-router'
import { connect } from 'react-redux';
import propTypes from 'prop-types'
import swal from 'sweetalert2';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import Enquiry from './../../../modules/blocks/sections/tenent/enquiry'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Autosuggest from 'react-autosuggest';
import { userLogout, simulateUser, getNotifications, setNotificationsViewed, getCustomerPendingSla, findOwnerProperty, findPropertyList, getCustomerSignedSla } from './../../../modules/redux/actions/listActions'

class Header extends Component { // eslint-disable-line react/prefer-stateless-function

    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            showMessage: false,
            showSingleMessage: false,
            isShowMsg: true,
            value: '',
            suggestions: [],
            propertyList: [],
            selectedProperty: false
        }

        // const { user } = props.authData
        // this.props?.findPropertyList(user?.customer_id)
    }

    /* component mount once elements initialized*/
    componentWillMount() {

        const { user_detail, user } = this.props.authData
        const { searchPropertyList, location } = this.props
        this.props.getNotifications(user, this);
        if( location?.pathname !==  '/owner/list')
        {
            /** get all customer pending SLA */
            this.props.getCustomerPendingSla(user, this)

            /** get customer signed SLA atleast one */
            this.props.getCustomerSignedSla(user, this)
        }

        if (!user?.simulate) {
            this.interval = setInterval(() => {
                this.props.getNotifications(user, this);
            }, 15000);
        }
        if( searchPropertyList?.length == 0 )
        {
            this.props?.findPropertyList(user?.customer_id)
        }
        else
        {
            const list = this.getPropertyList(searchPropertyList)

            this.setState({
                suggestions: list,
                propertyList:list 
            })
        }
    }
    componentDidMount()
    {
        const { user } = this.props.authData
        const { searchPropertyList } = this.props
        if( searchPropertyList?.length <= 0 )
        {
            this.props?.findOwnerProperty(user?.customer_id)
            this.props?.findPropertyList(user?.customer_id)
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    isSlaSigned(user_detail) {
        const { location, pendingSla, signedSla, authData } = this.props
        setTimeout(function() 
        { //Start the timer

            if( (pendingSla?.length == 0 && signedSla?.length == 0) && (location?.pathname != "/sign/sla" && location?.pathname != "/owner/list"))
            {
                swal.fire({
                    title: 'Notice',
                    html: "<p style='padding-bottom: 10px;margin-bottom: 20px;line-height: 22px;'>Hi. We cannot detect a signed Service Level Agreement (SLA) in place. Please contact <a href='mailto:support@rentperks.com'>support@rentperks.com</a> (M-F during business hours) and we will rectify this asap.</p>",
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    showConfirmButton: false,
                    preConfirm: () => {
                    },
                    preDeny: () => {
                    }
                }).then((_) => {

                    this.setState({
                        redirect: true
                    })
                });
            }
            else if (pendingSla?.length > 0 && location?.pathname != "/sign/sla" && location?.pathname != "/owner/list") {
                let user_sla = pendingSla[0]
                
                if ((user_sla?.is_sla_signed && user_sla?.is_sla_signed == "N")) {

                    swal.fire({
                        title: 'Notice',
                        text: "Hi. Please review and sign your SLA. It will apply to all your tenancies on this Owners’ Portal and enable access. Have a great day.",
                        allowOutsideClick: false,
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'YES',
                        preConfirm: () => {
                        },
                        preDeny: () => {
                        }
                    }).then((_) => {
                        this.setState({
                            redirect: true
                        })
                    });
                }
            }
            else if( signedSla?.length == 0 && location?.pathname != "/sign/sla" && location?.pathname != "/owner/list" )
            {
                swal.fire({
                    title: 'Notice',
                    text: "Hi. Please review and sign your SLA. It will apply to all your tenancies on this Owners’ Portal and enable access. Have a great day.",
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'YES',
                    preConfirm: () => {
                    },
                    preDeny: () => {
                    }
                }).then((_) => {

                    this.setState({
                        redirect: true
                    })
                });
            }
    }.bind(this), 5000)
    }

    /**
     * get user logout
     * 
     * @param {object} el 
     */
    getLogout(el) {
        el.preventDefault()
        this.props.userLogout()
        window.location.href = "/"
    }

    backSimulate(el, option) {
        el.preventDefault();

        const data = {
            customer: option?.customer_id,
            simulate: localStorage.getItem("simulate")
        }
        this.props.simulateUser(data)
    }
    onClick = () => {
        const {propertyList} = this.state
        const { searchPropertyList } = this.props
        if( propertyList?.length == 0)
        {
            const list = this.getPropertyList(searchPropertyList)

            this.setState({
                suggestions: list,
                propertyList:list 
            })
        }
    }
    onChange = (event, { newValue }) => {
        try {
            if( newValue )
            {
                let splitData = newValue?.split('(')
                this.setState({
                    value: newValue,
                    selectedProperty: (splitData.length > 1) ? (splitData[1]).replace(')', '') : false
                }, () => {

                    if( splitData[1] )
                    {
                        this.setState({
                            value: '',
                            selectedProperty: false
                        });
                    }
                });
            }
            else{
                this.setState({
                    value: '',
                    selectedProperty: false
                });
            }
        } catch (error) {
        }
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        const { propertyList } = this.state
        this.setState({
            suggestions: getSuggestions(value, propertyList)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };
    
    onInputChangeValue(e){
        if(e.target.value == "" || e.target.value.match(/^[1-9]\d*\.?\d*$/)) {
          this.setState({ [e.target.name]: e.target.value })
        }
    }

    render() {
        
        const { props, state } = this
        const { authData, match, location } = props
        const { value, suggestions} = state;
        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Please search property address',
            value,
            onChange: this.onChange,
            onClick: this.onClick
        };
        
        return (
            <header>
                {
                    state?.redirect &&
                    <Redirect to="/sign/sla" />
                }
                {/* <nav class="navbar navbar-expand-lg navbar-light nav-bg p-5px">
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <div class="form-inline my-2 my-lg-0  mr-auto  w-100">
                            <div className="w-100"> */}
                                {/* {
                                    location?.pathname != "/owner/list" && 
                                    <Autosuggest
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={inputProps}
                                    />
                                } */}
                                {/* {
                                    (!authData?.user?.simulate && localStorage.getItem("simulate")) &&
                                    <div className="simulate_container">You are logged in as <b>{authData?.user_detail?.first_name + ' ' + authData?.user_detail?.last_name}</b>.
                                        <a href="" className="" onClick={(el) => this.backSimulate(el, authData.simulate)}> Switch Owner</a>
                                    </div>
                                } */}
                            {/* </div>
                        </div> */}
                        {/* <ul class="navbar-nav">
                            {
                                location?.pathname != "/owner/list" && 
                                <>
                                    <li class="nav-item">
                                        <a className="auth-user nav-link color_gray_white transform_capitalize"  onClick={(el) => this.setNotificationRead(el)}>
                                            <i className="fa fa-envelope-o font-1-rem"></i>
                                        </a>
                                    </li>
                                    {/* <li class="nav-item">
                                        <a className="auth-user nav-link color_gray_white transform_capitalize" href="/" onClick={(el) => el.preventDefault()}>  
                                            <Enquiry {...props} />
                                        </a>
                                    </li> */}
                                {/* </>
                            }
                            
                            <li class="nav-item">
                                <span className="no_menu"></span>
                            </li> */} 
                            {/* {
                                location?.pathname != "/owner/list" ?
                                <li class="nav-item">
                                    <a className="auth-user nav-link color_gray_white transform_capitalize" href="/tenancy">
                                        {authData?.user_detail?.first_name} {authData?.user_detail?.last_name}
                                    </a>
                                </li>
                                :
                                <li class="nav-item">
                                    <a className="auth-user nav-link color_gray_white transform_capitalize" href="/owner/list">
                                        {authData?.user_detail?.first_name} {authData?.user_detail?.last_name}
                                    </a>
                                </li>
                            } */}
                            
                            {/* <li class="nav-item">
                                |
                            </li> */}
                            {/* <li class="nav-item">
                                <a class="nav-link transform_capitalize color_gray_white" href="#" onClick={(el) => this.getLogout(el)}>Logout</a>
                            </li>
                        </ul>
                    </div>
                </nav> */}
                {/* <a class="nav-link transform_capitalize color_gray_white" href="#" onClick={(el) => this.getLogout(el)}>Logout</a> */}
                {/* <Modal show={state.showMessage} size="lg" className="folio_table message_box transparent_modal_box">
                    <Modal.Header>
                        <Modal.Title>Recent Notification</Modal.Title>
                        <Button className="cross-button" variant="" onClick={() => this.setState({ showMessage: false })}>
                            <i className="fa fa-times-circle fa-2x"></i>
                        </Button>
                    </Modal.Header>

                    <Modal.Body>
                        <ul class="list-group list-group-flush">
                            {
                                this.renderNotifications()
                            }
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showMessage: false })}>
                            Close
                        </Button>
                    </Modal.Footer>

                </Modal>
                <Modal show={state.showSingleMessage} size="lg" className="folio_table message_box wide-popup transparent_modal_box">
                    <Modal.Header>
                        <Modal.Title>Recent Notification</Modal.Title>
                        <Button className="cross-button" variant="" onClick={() => this.setState({ showSingleMessage: false })}>
                            <i className="fa fa-times-circle fa-2x"></i>
                        </Button>
                    </Modal.Header>

                    <Modal.Body className="transparent_modal_box">
                        <ul class="list-group list-group-flush">
                            {
                                this.showMessageDetails()
                            }
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showSingleMessage: false })}>
                            Close
                        </Button>
                    </Modal.Footer>

                </Modal> */}
            </header>
        )
    }

    renderNotifications() {

        const { props } = this
        const { userNotification, constants } = this.props

        if (!userNotification?.length) return <h2>No Notification Found!</h2>

        return userNotification?.map(option => {

            let getMsgLen = props.helper.getReadMoreText(option?.message, '120')

            let isReccMsg = (!option?.is_viewed) ? 'msg_recc' : ''

            return (
                <li class={"list-group-item " + isReccMsg}>
                    <i className="fa fa-hand-o-right"></i>
                    {
                        getMsgLen ?
                            <>
                                <p onClick={(el) => this.setMessageDetails(el, option?.message_id)}>{getMsgLen}</p>
                            </>
                            :
                            <>
                                <p onClick={(el) => this.setMessageDetails(el, option?.message_id)}>{option?.message}</p>
                            </>
                    }
                    {
                        <p className="message_date">{moment(option?.created_at).format('MMM DD, YYYY')}</p>
                    }
                </li>
            )
        })
    }

    showMessageDetails() {
        const { props, state } = this
        const { userNotification } = props

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
        };

        try {

            let index = userNotification?.findIndex(list => list?.message_id == state?.showSingleMessage)
            if (index >= 0) {
                return (
                    <>
                        {userNotification[index]?.message}
                        <div className="render_image">
                            <Slider {...settings}>
                                {this.get_images(userNotification[index]?.notification)}
                            </Slider>
                        </div>
                    </>
                )
            }

        } catch (error) {

        }
        return false
    }

    get_images(notification_img) {
        try {

            const { constants } = this?.props

            if (notification_img) {
                return notification_img.map(element => {
                    return (

                        <div><img src={constants?.MEDIAURLDEV + element?.media?.path + '/' + element?.media?.name} className="msg_img" /></div>
                    )
                });
            }
        } catch (error) {

        }
    }

    setViewedMsg() {
        const { state } = this
        let len = this.notifiedMessages()

        if (len && state?.isShowMsg) {
            this.setState({
                showMessage: true,
                isShowMsg: false
            })
        }
    }

    setMessageDetails(el, message_id) {
        const { props } = this

        this.setState({
            showSingleMessage: message_id
        }, () => {
            props?.setNotificationsViewed(props?.authData?.user, props, this, message_id)
        })
    }

    activeMessages() {
        const { props } = this
        try {

            return props?.userNotification?.filter(list => !list?.is_viewed)?.length
        } catch (error) {

        }
    }

    notifiedMessages() {
        const { props } = this
        try {

            return props?.userNotification?.filter(list => list?.is_show)?.length
        } catch (error) {

        }
    }
    setNotificationRead(el) {
        el.preventDefault()

        const { props } = this

        this.setState({
            showMessage: true
        })
    }

    getPropertyList(searchPropertyList)
    {
        let suggestions = []

        try {
            if( searchPropertyList?.length )
            {
                searchPropertyList?.map( (option) => {
                    suggestions.push({
                        'id': option?.property_id,
                        'name': option?.address
                    })
                })
            }
        } catch (error) {
        }
        return suggestions
    }
}

/** validate */
Header.propTypes = {
    userLogout: propTypes.func.isRequired,
    simulateUser: propTypes.func.isRequired,
    getNotifications: propTypes.func.isRequired,
    setNotificationsViewed: propTypes.func.isRequired,
    getCustomerPendingSla: propTypes.func.isRequired,
    getCustomerSignedSla: propTypes.func.isRequired,
    findOwnerProperty: propTypes.func.isRequired
}

const mapStateToProps = state => ({
    userLogout: state.dataPack.userLogout,
    authData: state.dataPack.authData,
    userNotification: state.dataPack.userNotification,
    constants: state.dataPack.constants,
    helper: state.dataPack.helper,
    pendingSla: state.dataPack.pendingSla,
    signedSla: state.dataPack.signedSla,
    searchPropertyList: state.dataPack.searchPropertyList
})

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value, propertyList) => {
    try {
        
        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            return [];
        }

        const regex = new RegExp('^' + escapedValue, 'i');

        return propertyList.filter(language => regex.test(language.name));

    } catch (error) {
    }
};

const getSuggestionValue = suggestion => suggestion.name+' ('+suggestion.id+')';

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <div>
        {suggestion.name}
    </div>
);
export default connect(mapStateToProps, { userLogout, simulateUser, getNotifications, setNotificationsViewed, getCustomerPendingSla, findOwnerProperty, findPropertyList, getCustomerSignedSla })(Header)