import React, {Component, useState} from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types'
import Refer from './../../../modules/blocks/sections/panel/refer'
import Panel from './../../../modules/blocks/sections/panel'
import Slider from './../../../component/structure/slider'
import {Button, Modal} from 'react-bootstrap'
import { findOwnerProperty, getProfile, addBankDetails, updateBankDetails, deleteBankDetails, selectBank, updateProfile, addSecondAuthority, deleteSecondAuthority, forRedirection, applyNonResident, copyToAll} from './../../../modules/redux/actions/listActions'
import POP_IMG from './../../../component/assets/images/bank-pop-img.png'
import swal from 'sweetalert2'
import Personal from './components/Personal';
import Bank from './components/Bank';
import Foreign from './components/Foreign';
import Authorization from './components/Authorization';

class Profile extends Component {

    constructor(props)
    {
        super(props)
        const query = new URLSearchParams(props.location.search);
        this.state  = {
            creditType  : 'all',
            show : false,
            showPop : false,
            showEditPop : false,
            customer_account_id : '',
            account_name : '',
            fin_institution_no : '',
            account_number : '',
            transit : '',
            address: '',
            p_email: '',
            country: 0,
            state: 0,
            city:0,
            personal:false,
            authorization:false,
            banking:false,
            foreign:false
        }
    }

    /**
     * mount component once render the initial component 
     * 
     * @param {*} NA
     * @return {*} NA
    */
    componentDidMount()
    {
        document.title = "Profile | RentPerks"
        const { match, authData } = this.props
        this.props.findOwnerProperty(authData.user.customer_id)
        let propId = this.props?.match?.params?.id
        let customer_id = authData?.user?.customer_id
        let type = 'country'
        this.props.getProfile(propId, type, customer_id)
        
        this.setState({
            country:this?.props?.proData?.userLocation?.country,
            state:this?.props?.proData?.userLocation?.state,
            city:this?.props?.proData?.userLocation?.city,
        })
    }

    componentWillMount() {
        const { match, authData } = this.props
        this.props.findOwnerProperty(authData.user.customer_id)
        let propId = this.props?.match?.params?.id
        let customer_id = authData?.user?.customer_id
        let type = 'country'
        this.props.getProfile(propId, type, customer_id)
    }

    componentDidUpdate(prevProps)
    {
        document.title = "Profile | RentPerks"
        const { props } = this
        const {match, authData} = this.props
        if( match.params.id != prevProps.match.params.id){
            this.props.findOwnerProperty(authData.user.customer_id)
            let propId = this.props?.match?.params?.id
            let customer_id = authData?.user?.customer_id
            let type = 'country'
            this.props.getProfile(propId, type, customer_id)
        }

        let propCount = props?.propertyList?.length

        if(propCount >= 1 && props.location.pathname == '/profile'){
            let f_prop_id = props?.propertyList?.[0]?.property_id
            props.history.push('/profile/'+f_prop_id)
        }
    }
    
    render() {
        const { props, state } = this

        const { proData, constants, match } = props
        const { address, p_email  }  = state
        const accounts = props?.proData?.accounts
        const countries = props?.proData?.countries
        const states = props?.proData?.states    
        const allState = props?.proData?.allStates    
        const cities = props?.proData?.cities
        const allCities = props?.proData?.allCities
        const selected_bank = props?.proData?.property?.selected_bank
        const foreign_resident = props?.proData?.property?.foreign_resident
        const profile = props?.proData?.profile
        const location = props?.proData?.userLocation
        const customer_id = props?.proData?.profile?.customer_id
        const propId = this.props?.match?.params?.id
        const secondAuthority = props?.proData?.secondAuthority

        let isCheckedEmail = (profile?.details?.email_notify != 'N') ? true : false
        let isCheckedSms = (profile?.details?.sms_notify != 'N') ? true : false
              
        console.log(state, 'country');
        return (
            <div className='row section_height'>
                <Panel {...props} />
                <div className="col-lg-10 col-md-10 col-sm-12 col-xl-10 d-flex align-content-between flex-wrap justify-content-center section_height profile-page-p">
                    {
                        (!state?.personal && !state?.banking && !state?.authorization) &&
                        <>
                            <div className='col-lg-4 col-xl-4 col-md-4 col-sm-12 h-full'>
                                <div className='profile-category profile-category-personal' onClick={() => this.setState({personal:true})}>
                                    <p>My Personal Information</p>
                                </div>
                            </div>
                            <div className='col-lg-4 col-xl-4 col-md-4 col-sm-12 h-full'>
                                <div className='profile-category profile-category-auth' onClick={() => this.setState({authorization:true})}>
                                    <p>Authorizations</p>
                                </div>
                            </div>
                            <div className='col-lg-4 col-xl-4 col-md-4 col-sm-12 h-full'>
                                <div className='profile-category profile-category-bank' onClick={() => this.setState({banking:true})}>
                                    <p>Bank Accounts</p>
                                </div>
                            </div>
                        </>
                    }
                    {
                        (state?.personal && !state?.foreign) &&
                        <Personal onClose={() => this.handleModalClose()} onForeignOpen={(el) => this?.handleForeignEvent(el)}/>
                    }
                    {
                        state?.banking &&
                        <Bank onClose={() => this.handleModalClose()} />
                    }
                    {
                        state?.foreign &&
                        <Foreign handleForeignEventClose={(el) => this.handleForeignClose(el)} />
                    }
                    {
                        state?.authorization &&
                        <Authorization onClose={() => this.handleModalClose()} onOpen={(el) => this?.handleOpen(el)} />
                    }
                </div>
            </div>
        )
    }

    handleOpen(el)
    {
        el?.preventDefault();
        this.setState({authorization: true})
    }

    handleModalClose(){
        this.setState({
            personal:false,
            authorization:false,
            banking:false,
            foreign:false
        })
    }

    handleForeignEvent(el)
    {
        this.setState({
            foreign:true
        })
    }

    handleForeignClose()
    {
        this.setState({
            foreign:false,
            personal:true
        })
    }
}

const accountsSection = ( obj, accounts, props) => {
    return accounts.map((item, index) => {
        return (
            <tr key={index}>
                <th className="wh-bg">{item?.account_name}</th>
                <td className="wh-bg">{item?.account_number}</td>
                <td className="wh-bg">{item?.transit}</td>
                <td className="wh-bg">{item?.route}</td>
                <td className="wh-bg text-center">
                    <input 
                        type="radio" 
                        name="customer_account_id" 
                        checked={ item?.customer_account_id == props?.proData?.selectedBank ? true : false }
                        onChange={ (el) => handleSetBankEvent(obj, props, props?.match?.params?.id, item?.customer_account_id) }
                        value={item.customer_account_id}
                    />
                </td>
                
                <td className="wh-bg text-center">
                    {
                        item?.customer_account_id == props?.proData?.selectedBank &&
                        <input type="checkbox" name="copyToAll" onChange={ (el) => handleCopyToAllEvent(el, props, props?.match?.params?.id, item?.customer_account_id) }/>
                    }
                </td>
                <td className="dash-action text-right">
                    <a href="#!" onClick={ (el) => handleDeleteEvent(el, props, item?.customer_account_id) } className="dash-delete">Delete</a>
                </td>
            </tr>
        )
    });
}

/**
 * update profile
 * 
 * @param {object} el 
 * @return {*}
 */
 const handleSaveProfileSubmitEvent = (el, props, propId, customer_id) => {

    el.preventDefault();

    swal.fire({
        title: 'Continue saving changes?',
        text: "NOTE: If any property has had a bank account change it will take 48 hours to reflect - any payments to you in transit during that time cannot be altered.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'YES',
        cancelButtonText: 'NO',
    }).then((result) => {
        
        if (result.value) {
            let formData = new FormData(); 
            formData.append("p_username", el?.target?.p_username?.value);
            formData.append("p_first_name", el?.target?.p_first_name?.value);
            formData.append("p_last_name", el?.target?.p_last_name?.value);
            formData.append("p_password", el?.target?.p_password?.value);
            formData.append("p_mobile", el?.target?.p_mobile?.value);
            formData.append("p_email", el?.target?.p_email?.value);
            formData.append("p_address", el?.target?.p_address?.value);
            formData.append("p_unit", el?.target?.p_unit?.value);
            formData.append("p_city_id", el?.target?.p_city_id?.value);
            formData.append("p_pincode", el?.target?.p_pincode?.value);
            formData.append("email_notify", el.target?.email_notify?.checked ? el?.target?.email_notify?.value : "N");
            formData.append("sms_notify", el.target?.sms_notify?.checked ? el?.target?.sms_notify?.value : "N");
    
            formData.append("second_auth_id", el?.target?.second_auth_id?.value);
            formData.append("s_username", el?.target?.s_username?.value);        
            formData.append("s_first_name", el?.target?.s_first_name?.value);
            formData.append("s_last_name", el?.target?.s_last_name?.value);
            formData.append("s_password", el?.target?.s_password?.value);
            formData.append("s_mobile", el?.target?.s_mobile?.value);
            formData.append("s_email", el?.target?.s_email?.value);
            formData.append("pre_auth", el?.target?.pre_auth?.value);            
    
            formData.append("property_id", propId);
            formData.append("customer_id", customer_id);
            formData.append("customer_account_id", el?.target?.customer_account_id?.value);
            
            props.updateProfile(formData, props)

            swal.fire(
                "Success!",
                "Successfully Changed.", 
                "success"
            ).then((res) => {                            
                
            })                                               
        }
    });
}

/**
 * add bank details
 * 
 * @param {object} el 
 * @return {*}
 */
 const handleAddBankDetailsSubmitEvent = (el, props, customer_id) => {

    el.preventDefault();

    let formData    = new FormData();
    formData.append("account_name", el.target.a_account_name.value);
    formData.append("eft_qid", el.target.a_fin_institution_no.value);
    formData.append("route", el.target.a_fin_institution_no.value);
    formData.append("account_number", el.target.a_account_number.value);
    formData.append("transit", el.target.a_transit.value);
    formData.append("customer_id", customer_id);
    
    props.addBankDetails(formData)
} 

/**
 * add bank details
 * 
 * @param {object} el 
 * @return {*}
 */
 const handleUpdateBankDetailsSubmitEvent = (el, props, customer_id) => {

    el.preventDefault();

    let formData    = new FormData();
    formData.append("customer_account_id", el.target.e_customer_account_id.value);
    formData.append("account_name", el.target.e_account_name.value);
    formData.append("eft_qid", el.target.e_fin_institution_no.value);
    formData.append("route", el.target.e_fin_institution_no.value);
    formData.append("account_number", el.target.e_account_number.value);
    formData.append("transit", el.target.e_transit.value);
    formData.append("customer_id", customer_id);
    
    props.updateBankDetails(formData)
}

/**
 * add second authority details
 * 
 * @param {object} el 
 * @return {*}
 */
 const handleAddSecondAuthority = (el, obj, props, customer_id) => {

    el.preventDefault();

    let formData    = new FormData();
    formData.append("first_name", el.target.a_first_name.value);
    formData.append("last_name", el.target.a_last_name.value);
    formData.append("username", el.target.a_uname.value);
    formData.append("email", el.target.a_email.value);
    formData.append("mobile", el.target.a_mobile.value);
    formData.append("password", el.target.a_passw.value);
    formData.append("customer_id", customer_id);
    
    props.addSecondAuthority(formData, props)
    obj.setState({showPop:false})
}

const handleDeleteEvent = (el, props, id) => {

    swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
            let formData    = new FormData();
            formData.append("id", id);
            props.deleteBankDetails(formData, props)
            swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            )
        }else if (result.dismiss === "cancel") {
            swal.fire(
                "Cancelled",
                "Not Changed.",
                "error"
            )
        }
      })
}

/**
 * delete second authority details
 * 
 * @param {object} el 
 * @return {*}
 */
const handleDeleteSecondAuthorityEvent = (el, props, customer_id) => {

    swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.value) {
            let formData    = new FormData();
            formData.append("customer_id", customer_id);
            props.deleteSecondAuthority(formData, props)
            swal.fire(
                'Deleted!',
                'Second Authority has been deleted.',
                'success'
            )
            // window.location.reload();
        } else if (result.dismiss === "cancel") {
            swal.fire(
                "Cancelled",
                "Not Deleted.",
                "error"
            )
        }
    })
}

const handleSetBankEvent = (obj, props, property_id, customer_account_id) => {

    if(property_id == undefined){
        swal.fire(
            "Warning !",
            "Please select Property first.",
            "error"
        )
    }

    swal.fire({
        title: 'Are you sure?',
        text: "NOTE: If any property has had a bank account change it will take 48 hours to reflect - any payments to you in transit during that time cannot be altered.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'YES',
        cancelButtonText: 'NO',
    }).then((result) => {
        if (result.value) {
            let formData    = new FormData();
            formData.append("property_id", property_id);
            formData.append("customer_account_id", customer_account_id);
            props.selectBank(formData, property_id, props) 
            swal.fire(
                "Success!",
                "Successfully Changed.",
                "success"
            )
        } else if (result.dismiss === "cancel") {
            swal.fire(
                "Cancelled",
                "Not Changed.",
                "error"
            )
        }
    });
}

const handleCopyToAllEvent = (el, props, property_id, customer_account_id) => {

    if(property_id == undefined){
        swal.fire(
            "Warning !",
            "Please select Property first.",
            "error"
        )
    }
    swal.fire({
        title: 'Are you sure?',
        text: "This will copy selected bank account to all tenancies",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'YES',
        cancelButtonText: 'NO',
    }).then((result) => {
        if (result.value) {
            let formData    = new FormData();
            formData.append("copy_to_all", (el.target.checked ? 1 : 0));
            formData.append("customer_id", props?.proData?.profile?.customer_id);
            formData.append("customer_account_id", customer_account_id);
            props.copyToAll(formData, property_id, props) 
            swal.fire(
                "Success!",
                "Successfully Changed.",
                "success"
            )
        } else if (result.dismiss === "cancel") {
            swal.fire(
                "Cancelled",
                "Not Changed.",
                "error"
            )
            el.target.checked = false
        }
    });
}

const countriesSection = ( countries, location) => {
    return countries.map((item, index) => {
        return (
            <option key={index} value={item.location_id}>{item.name}</option>
        )
    });
}

const statesSection = ( states, location, state ) => {

    const country  = state?.country ?? location?.country
    return states?.filter(list => list?.parent  == country)?.map((item, index) => {
        return (
            <option key={index} value={item.location_id}>{item.name}</option>
        )
    });
}

const citiesSection = ( cities, location, state ) => {
    const province  = state?.state ?? location?.state

    console.log(province, 'province');
    return cities?.filter(list => list?.parent ==  province)?.map((item, index) => {
        return (
            <option key={index} value={item.location_id}>{item.name}</option>
        )
    });
}

/** validate function is or not */
Profile.propTypes = {
    findOwnerProperty: propTypes.func.isRequired,
    getProfile: propTypes.func.isRequired,
    addBankDetails: propTypes.func.isRequired,
    updateProfile: propTypes.func.isRequired,
    updateBankDetails: propTypes.func.isRequired,
    deleteBankDetails: propTypes.func.isRequired,
    addSecondAuthority: propTypes.func.isRequired,
    forRedirection: propTypes.func.isRequired,
    deleteSecondAuthority: propTypes.func.isRequired,
    applyNonResident: propTypes.func.isRequired,
    selectBank: propTypes.func.isRequired,
    copyToAll: propTypes.func.isRequired,
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    authData: state.dataPack.authData,
    propertyList: state.dataPack.propertyList,
    proData: state.dataPack.profileData,
})
export default connect (mapStateToProps, {findOwnerProperty, getProfile, addBankDetails, updateBankDetails, deleteBankDetails, selectBank, updateProfile, addSecondAuthority, deleteSecondAuthority, forRedirection, applyNonResident, copyToAll})( Profile )