import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'
import TransactionReport from './transactionReport'
import {Modal} from 'react-bootstrap'


class BillDue extends Component {

    constructor(props)
    {
        super(props)

        this.state  = {
        }
    }
    
    render() {

        const { props }     = this
        
        return (
            
            <>
                <div className="property-ledger-table auto_scroll_bill_due_table">
                    <button type="button" class="close close-absolute" onClick={() => props?.onClose()}>
                        <span aria-hidden="true">×</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th tabindex="0">DESCRIPTION</th>
                                <th tabindex="0">Amount</th>
                                <th tabindex="0">Added Date</th>
                                <th tabindex="0">Due Date</th>
                                <th tabindex="0">Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.renderDocuments()}
                        </tbody>
                    </table>
                </div> 
            </>
        )
    }

    renderDocuments()
    {
        const { props }                 = this
        const { billDueLedger, constants }   = props
        
        if( !billDueLedger?.length )
        {
            return (
                <tr>
                    <td colSpan="4" className='text-center'>No Data Found!</td>
                </tr>
            )
        }
        return billDueLedger.map( function(option, index) { 
            
            return (

                <tr key={index}>
                    <td width="50%" className="text-left">{option?.utility?.utilitie_name} - {option?.notes}</td>
                    <td className="text-right">${option.total_bill_amount}</td>
                    <td className="text-right">{moment(option?.added_on).format('MMM DD, YYYY')}</td>
                    <td className="text-right">{moment(option?.due_date).format('MMM DD, YYYY')}</td>
                    <td className="text-center">
                        <a target="_blank" href={constants?.MEDIAURL+option?.media?.path+'/'+option?.media?.name} download className="download_button btn _leased _red plr-20 text-center"><i className="fa fa-share-square-o" aria-hidden="true"></i> View</a>
                    </td>
                </tr>
            )
        })
    }
}

export default BillDue
