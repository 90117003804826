import React, {Component, useState} from 'react';
import propTypes from 'prop-types'
import { connect } from 'react-redux';
import { findOwnerProperty, getProfile, addBankDetails, updateBankDetails, deleteBankDetails, selectBank, updateProfile, addSecondAuthority, deleteSecondAuthority, forRedirection, applyNonResident, copyToAll, successMessage} from './../../../../modules/redux/actions/listActions'
import swal from 'sweetalert2'

class Personal extends Component{

    constructor(props)
    {
        super(props)
        this.state  = {
            creditType  : 'all',
            show : false,
            showPop : false,
            showEditPop : false,
            customer_account_id : '',
            account_name : '',
            fin_institution_no : '',
            account_number : '',
            transit : '',
            address: '',
            p_email: '',
            country: 0,
            state: 0,
            city:0,
            personal:false,
            authorization:false,
            banking:false,
            contact_phone:false,
            contact_email: false,
            foreign_resident:false
        }
    }

    /**
     * mount component once render the initial component 
     * 
     * @param {*} NA
     * @return {*} NA
    */
    componentDidMount()
    {
        document.title = "Profile | RentPerks"
        const { match, authData } = this.props
        this.props.findOwnerProperty(authData.user.customer_id)
        let propId = this.props?.match?.params?.id
        let customer_id = authData?.user?.customer_id
        let type = 'country'
        this.props.getProfile(propId, type, customer_id)
        
        console.log(this?.props?.proData, 'this?.props?.proData')
        this.setState({
            country:this?.props?.proData?.userLocation?.country,
            state:this?.props?.proData?.userLocation?.state,
            city:this?.props?.proData?.userLocation?.city,
            foreign_resident:this?.props?.proData?.property?.foreign_resident,
            p_email: this?.props?.proData?.profile?.email
        })
    }

    componentWillMount() {
        const { match, authData } = this.props
        this.props.findOwnerProperty(authData.user.customer_id)
        let propId = this.props?.match?.params?.id
        let customer_id = authData?.user?.customer_id
        let type = 'country'
        this.props.getProfile(propId, type, customer_id)
    }

    componentDidUpdate(prevProps)
    {
        document.title = "Profile | RentPerks"
        const { props } = this
        const {match, authData} = this.props
        if( match?.params?.id != prevProps?.match?.params?.id){
            this.props.findOwnerProperty(authData.user.customer_id)
            let propId = this.props?.match?.params?.id
            let customer_id = authData?.user?.customer_id
            let type = 'country'
            this.props.getProfile(propId, type, customer_id)
        }

        let propCount = props?.propertyList?.length

        if(propCount >= 1 && props?.location?.pathname == '/profile'){
            let f_prop_id = props?.propertyList?.[0]?.property_id
            props.history.push('/profile/'+f_prop_id)
        }
    }

    render(){

        const { props, state } = this
        const { proData, constants, match } = props
        const { address, p_email  }  = state
        const accounts = props?.proData?.accounts
        const countries = props?.proData?.countries
        const states = props?.proData?.states    
        const allState = props?.proData?.allStates    
        const cities = props?.proData?.cities
        const allCities = props?.proData?.allCities
        const selected_bank = props?.proData?.property?.selected_bank
        const foreign_resident = props?.proData?.property?.foreign_resident
        const profile = props?.proData?.profile
        const location = props?.proData?.userLocation
        const customer_id = props?.proData?.profile?.customer_id
        const propId = this.props?.match?.params?.id
        const secondAuthority = props?.proData?.secondAuthority

        let isCheckedEmail = (profile?.details?.email_notify != 'N') ? true : false
        let isCheckedSms = (profile?.details?.sms_notify != 'N') ? true : false

        return (
            <div className='personal-section'>
                <div class="modal-header">
                    <div class="modal-title h4">Personal Details</div>
                    <button type="button" class="close" onClick={() => props?.onClose()}>
                        <span aria-hidden="true">×</span>
                        <span class="sr-only">Close</span>
                    </button>
                </div>
                <form autoComplete="off" id="frm_profile" method="POST" onSubmit={(el) => handleSaveProfileSubmitEvent(el, props, propId, customer_id, state)}>
                    <div className='row'>
                        <div className='col-lg-4 col-xl-4 col-md-6 col-sm-12'>
                            <div className='form-group'>
                                <input type="text" name="p_first_name" ref="p_first_name" placeholder="First Name*" defaultValue={profile?.details?.first_name} className="field_text_style" required />
                            </div>
                        </div>
                        <div className='col-lg-4 col-xl-4 col-md-6 col-sm-12'>
                            <div className='form-group'>
                                <input type="text" name="p_last_name" ref="p_last_name" placeholder="Last Name*" defaultValue={profile?.details?.last_name} className="field_text_style" required />
                            </div>
                        </div>
                        <div className='col-lg-4 col-xl-4 col-md-6 col-sm-12'>
                            <div className='form-group'>
                                <div className='form-group'>
                                    <input 
                                        type="email" 
                                        name="p_email" placeholder="Email*" value={p_email ? p_email : profile?.email} className="field_text_style" required onChange={(e) => this.setState({p_email: e.target?.value})} />
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-xl-4 col-md-6 col-sm-12'>
                            <div className='form-group'>
                                <input type="password" name="p_password" ref="p_password" placeholder="Password" className="field_text_style" autoComplete="off" />
                            </div>
                        </div>
                        <div className='col-lg-4 col-xl-4 col-md-6 col-sm-12'>
                            <div className='form-group'>
                                <input type="text" name="p_mobile" ref="p_mobile" placeholder="Phone Number*" defaultValue={profile?.mobile} className="field_text_style" required />
                            </div>
                        </div>
                        <div className='col-lg-4 col-xl-4 col-md-6 col-sm-12'>
                            <div className='form-group'>
                                <input type="text" name="p_username" ref="p_username" placeholder="Username*" defaultValue={profile?.username} className="field_text_style" required />
                            </div>
                        </div>
                    </div>

                    <div class="modal-header">
                        <div class="modal-title h4">Address</div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-8 col-xl-8 col-md-12 col-sm-12'>
                            <div className='form-group'>
                                <input type="text" name="p_address" ref="p_address" placeholder="Address - Number - Street*" value={ address ? address : profile?.details?.address} onChange={(el) => this.setState({address: el.target.value})} className="field_text_style" required />
                            </div>
                        </div>
                        <div className='col-lg-4 col-xl-4 col-md-6 col-sm-12'>
                            <div className='form-group'>
                                <input type="text" name="p_unit" ref="p_unit" placeholder="Unit" defaultValue={profile?.details?.unit} className="field_text_style" required maxLength={3}/>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-3 col-xl-3 col-md-6 col-sm-12'>
                            <div className='form-group'>
                            {
                                location?.country &&
                                <select name="p_country" ref="p_country" className="field_text_style bg-w" required="" value={state?.country ?? location?.country} onChange={(el) => this.setState({country: el.target.value, state: 0})}>
                                    <option value="">Country</option>
                                    { props?.proData?.countries && countriesSection(countries, location)}
                                </select>
                            }
                            </div>
                        </div>
                        <div className='col-lg-3 col-xl-3 col-md-6 col-sm-12'>
                            <div className='form-group'>
                                {
                                    location?.state &&
                                    <select name="p_state" ref="p_state" className="field_text_style bg-w" required onChange={(el) => this.setState({state: el.target.value, city: 0})} value={state?.state ?? location?.state}>
                                        <option value="">State</option>
                                        { props?.proData?.states && statesSection(allState, location, state)}
                                    </select>
                                }
                            </div>
                        </div>
                        <div className='col-lg-3 col-xl-3 col-md-6 col-sm-12'>
                            <div className='form-group'>
                                {
                                    location?.city &&
                                    <select name="p_city_id" ref="p_city_id" className="field_text_style bg-w" required value={state?.city ?? location?.city} onChange={(el) => this.setState({city: el.target.value})}>
                                        <option value="">City</option>
                                        { props?.proData?.cities && citiesSection(allCities, location, state)}
                                    </select>
                                }
                            </div>
                        </div>
                        <div className='col-lg-3 col-xl-3 col-md-6 col-sm-12'>
                            <div className='form-group'>
                                <input type="text" name="p_pincode" ref="p_pincode" placeholder="Zip Code" defaultValue={profile?.details?.pincode} className="field_text_style" required />
                            </div>
                        </div>
                    </div>

                    <div class="modal-header">
                        <div class="modal-title h4">Contact Preference</div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3 col-xl-3 col-md-6 col-sm-12'>
                            <div className='form-group'>
                                <div class="checkbox d-flex justify-content-evenly">Phone <label for="sms_notify"><input name="sms_notify" defaultChecked={ isCheckedSms } type="checkbox" value="Y" /></label></div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-xl-3 col-md-6 col-sm-12'>
                            <div className='form-group'>
                                <div class="checkbox d-flex justify-content-evenly">Email <label for="email_notify"><input name="email_notify" defaultChecked={ isCheckedEmail } type="checkbox" value="Y" /></label></div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-header">
                        <div class="modal-title h4">Foreign Resident</div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3 col-xl-3 col-md-6 col-sm-12'>
                            <div className='form-group'>
                                <div class="checkbox d-flex justify-content-evenly">Yes <label for="sms_notify"><input onClick={(el) => {
                                     this.setState({foreign_resident: "Y"}, () => {
                                        props?.onForeignOpen(el);
                                     })
                                }} name="foreign_yes" type="checkbox" value="Y" checked={state?.foreign_resident == "Y" ? true : false} /></label></div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-xl-3 col-md-6 col-sm-12'>
                            <div className='form-group'>
                                <div class="checkbox d-flex justify-content-evenly">No 
                                    <label for="foreign_no">
                                        <input name="foreign_no" type="checkbox" value="N" checked={state?.foreign_resident == "N" ? true : false} onClick={() => this.setState({foreign_resident: "N"})}/>
                                    </label></div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary">Save Changes</button>
                    </div>
                </form>
            </div>
        )
    }
}

/**
 * update profile
 * 
 * @param {object} el 
 * @return {*}
 */
const handleSaveProfileSubmitEvent = (el, props, propId, customer_id, state) => {

    el.preventDefault();

    let formData = new FormData(); 
    formData.append("p_username", el?.target?.p_username?.value);
    formData.append("p_first_name", el?.target?.p_first_name?.value);
    formData.append("p_last_name", el?.target?.p_last_name?.value);
    formData.append("p_password", el?.target?.p_password?.value);
    formData.append("p_mobile", el?.target?.p_mobile?.value);
    formData.append("p_email", el?.target?.p_email?.value);
    formData.append("p_address", el?.target?.p_address?.value);
    formData.append("p_unit", el?.target?.p_unit?.value);
    formData.append("p_city_id", el?.target?.p_city_id?.value);
    formData.append("p_pincode", el?.target?.p_pincode?.value);
    formData.append("email_notify", el.target?.email_notify?.checked ? el?.target?.email_notify?.value : "N");
    formData.append("sms_notify", el.target?.sms_notify?.checked ? el?.target?.sms_notify?.value : "N");

    formData.append("second_auth_id", el?.target?.second_auth_id?.value);
    formData.append("s_username", el?.target?.s_username?.value);        
    formData.append("s_first_name", el?.target?.s_first_name?.value);
    formData.append("s_last_name", el?.target?.s_last_name?.value);
    formData.append("s_password", el?.target?.s_password?.value);
    formData.append("s_mobile", el?.target?.s_mobile?.value);
    formData.append("s_email", el?.target?.s_email?.value);
    formData.append("pre_auth", el?.target?.pre_auth?.value);            

    formData.append("property_id", propId);
    formData.append("customer_id", customer_id);
    formData.append("customer_account_id", el?.target?.customer_account_id?.value);
    
    props.updateProfile(formData, props)
};
const countriesSection = ( countries, location) => {
    return countries.map((item, index) => {
        return (
            <option key={index} value={item.location_id}>{item.name}</option>
        )
    });
}

const statesSection = ( states, location, state ) => {

    const country  = state?.country ?? location?.country
    return states?.filter(list => list?.parent  == country)?.map((item, index) => {
        return (
            <option key={index} value={item.location_id}>{item.name}</option>
        )
    });
}

const citiesSection = ( cities, location, state ) => {
    const province  = state?.state ?? location?.state

    console.log(province, 'province');
    return cities?.filter(list => list?.parent ==  province)?.map((item, index) => {
        return (
            <option key={index} value={item.location_id}>{item.name}</option>
        )
    });
}

/** validate function is or not */
Personal.propTypes = {
    findOwnerProperty: propTypes.func.isRequired,
    getProfile: propTypes.func.isRequired,
    addBankDetails: propTypes.func.isRequired,
    updateProfile: propTypes.func.isRequired,
    updateBankDetails: propTypes.func.isRequired,
    deleteBankDetails: propTypes.func.isRequired,
    addSecondAuthority: propTypes.func.isRequired,
    forRedirection: propTypes.func.isRequired,
    deleteSecondAuthority: propTypes.func.isRequired,
    applyNonResident: propTypes.func.isRequired,
    selectBank: propTypes.func.isRequired,
    copyToAll: propTypes.func.isRequired,
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    authData: state.dataPack.authData,
    propertyList: state.dataPack.propertyList,
    proData: state.dataPack.profileData,
})
export default connect (mapStateToProps, {findOwnerProperty, getProfile, addBankDetails, updateBankDetails, deleteBankDetails, selectBank, updateProfile, addSecondAuthority, deleteSecondAuthority, forRedirection, applyNonResident, copyToAll})( Personal )