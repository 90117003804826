import {
        GET_PROPERTY, 
        LEDGER_LIST, LEASE_DETAILS, PROPERTY_BILLS, TENANT_LIST, GET_PROFILE, DOCUMENTS_BILLS, LOADING_COMPONENT, GET_FORLEASE_PROPERTY, PROFILE_PROPERTY, FOR_LEASE, AUTH_USER, FORGOT_PASSWORD, CHANGE_PASSWORD, GET_LOGOUT,
        GET_BUILDING, 
        GET_BUILDING_PROPERTY, 
        ADD_BANK_DETAILS, 
        UPDATE_BANK_DETAILS, 
        USER_REDIRECT, 
        FOLIO_DATA, 
        OWNER_LIST, 
        APPLY_NON_RESIDENT, 
        GET_RESOURCES, 
        GET_WC_RESOURCES,
        BUILDING_LEDGERS,
        RECENT_RENT,
        FOLIO_LEDGERS,
        CLEAN_UP_DATA,
        CHECK_SLA_SIGNED,
        GET_PROPERTY_TYPE, 
        GET_PROPERTY_LOCATION,
        STATE_CITY,
        PROPERTY_UTILITY,
        PROPERTY_MEDIA,
        USER_NOTIFICATION,
        PENDING_SLA,
        SIGNED_SLA
    } from './../actions/types'
/** common helper instance */
import Helper from './../../../component/helper'

/** constants instance */
import Constants from './../../../component/constants'
/** helper class */
let helper        = new Helper()

/** reduceer initial storage */
const initialState = {
  
  constants             : Constants,
  helper                : helper,
  propertyList          : [],
  searchPropertyList    : [],
  ledgerList            : [],
  leaseDetails          : false,
  propertyBill          : [],
  tenantList            : [],
  documentBills         : [],
  processing            : false,
  authData              : helper.getAuthUser(),
  buildingList          : [],
  buildingProperty      : [],
  forLeaseData          : [],
  forgotPasswordData    : [],
  changePasswordData    : [],
  forLeasePropertyList  : [],
  profilePropertyList   : [],
  profileData           : [],
  addBankDetailsData    : [],
  updateBankDetailsData : [],
  isRedirection         : false,
  folioData             : [],
  ownerList             : [],
  applyNonResidentData  : [],
  allResourcesData      : [],
  allResourcesWCData    : [],
  buildingLedgers       : [],
  recentRents           : [],
  folioLedgers          : [],
  isSlaSigned           : "N",
  checkRun              : 0,
  propertyTypeData      : [],
  propertyLocationData  : [],
  stateCity             : [],
  utilities             : [],
  media                 : [],
  userNotification      : [],
  pendingSla            : 0,
  signedSla             : 0
}

let checkRun = 0

export default function(state = initialState, actions){

    switch(actions.type){

        case AUTH_USER:
            return {
            ...state,
            authData: actions.payload,
            isSlaSigned: actions.payload?.user_detail?.is_sla_signed
        }

        case FORGOT_PASSWORD:
            return {
            ...state,
            forgotPasswordData: actions.payload
        }

        case CHANGE_PASSWORD:
            return {
            ...state,
            changePasswordData: actions.payload
        }

        case GET_PROFILE:
            return {
            ...state,
            profileData: actions.payload
        }

        case GET_LOGOUT:
            return {
            ...state,
            authData: actions.payload
        }

        case GET_PROPERTY:
            return {
            ...state,
            propertyList: actions.payload,
            searchPropertyList: actions.payload
        }

        /*** ledger list */
        case LEDGER_LIST:
            return {
                ...state,
                ledgerList: actions.payload
            }

        /*** lease details */
        case LEASE_DETAILS:
            return {
                ...state,
                leaseDetails: actions.payload
            }

        /*** lease details */
        case PROPERTY_BILLS:
            return {
                ...state,
                propertyBill: actions.payload
            }

             /*** tenant list details */
        case TENANT_LIST:
            return {
                ...state,
                tenantList: actions.payload
            }

        case DOCUMENTS_BILLS : 
            return {
                ...state,
                documentBills: actions.payload
            }

        case LOADING_COMPONENT : 
            return {
                ...state,
                processing: actions.payload
            }

        case GET_BUILDING : 
            return {
                ...state,
                buildingList: actions.payload
            }
        case GET_BUILDING_PROPERTY : 
            return {
                ...state,
                buildingProperty: actions.payload
            }
        case FOR_LEASE : 
            return {
                ...state,
                forLeaseData: actions.payload
            }
        case GET_FORLEASE_PROPERTY : 
            return {
                ...state,
                forLeasePropertyList: actions.payload
            }
        case PROFILE_PROPERTY : 
            return {
                ...state,
                profilePropertyList: actions.payload
            }
        case ADD_BANK_DETAILS:
            return {
            ...state,
            addBankDetailsData: actions.payload
        }
        case UPDATE_BANK_DETAILS:
            return {
            ...state,
            updateBankDetailsData: actions.payload
        }

        case USER_REDIRECT:
            return {
            ...state,
            isRedirection: actions.payload
        }

        case FOLIO_DATA : 
            return {
                ...state,
                folioData: actions.payload,
                searchPropertyList: actions?.payload?.propertyList
            }

        case OWNER_LIST : 
            return {
                ...state,
                ownerList: actions.payload
            }
        
        case APPLY_NON_RESIDENT:
            return {
            ...state,
            applyNonResidentData: actions.payload
        }

        case GET_RESOURCES:
            return {
            ...state,
            allResourcesData: actions.payload
        }

        case GET_WC_RESOURCES:
            return {
            ...state,
            allResourcesWCData: actions.payload
        }

        case BUILDING_LEDGERS:
            return {
            ...state,
            buildingLedgers: actions.payload
        }

        case RECENT_RENT:
            return {
            ...state,
            recentRent: actions.payload
        }

        case FOLIO_LEDGERS:
            return {
            ...state,
            folioLedgers: actions.payload
        }

        case CLEAN_UP_DATA:
            return {
            ...state,
            propertyList          : [],
            ledgerList            : [],
            propertyBill          : [],
            tenantList            : [],
            documentBills         : [],
            folioLedgers          : [],
            buildingList          : [],
            buildingProperty      : [],
            forLeaseData          : [],
            forgotPasswordData    : [],
            forLeasePropertyList  : [],
            profilePropertyList   : [],
            profileData           : [],
            addBankDetailsData    : [],
            updateBankDetailsData : [],
            folioData             : [],
            ownerList             : [],
            applyNonResidentData  : [],
            allResourcesData      : [],
            allResourcesWCData    : [],
            buildingLedgers       : [],
            recentRents           : [],
            folioLedgers          : []
        }

        case CHECK_SLA_SIGNED: {
            return {
                ...state,
                isSlaSigned: actions.payload,
                checkRuns: checkRun++
            }
        }

        case GET_PROPERTY_TYPE:
            return {
            ...state,
            propertyTypeData: actions.payload
        }

        case GET_PROPERTY_LOCATION:
            return {
            ...state,
            propertyLocationData: actions.payload
        }

        case STATE_CITY:
            return {
            ...state,
            stateCity: actions.payload
        }

        case PROPERTY_UTILITY:
            return {
            ...state,
            utilities: actions.payload
        }

        case PROPERTY_MEDIA:
            return {
            ...state,
            media: actions.payload
        }

        case USER_NOTIFICATION:
            return {
            ...state,
            userNotification: actions.payload
        }

        case PENDING_SLA:
            return {
            ...state,
            pendingSla: actions.payload ? actions.payload : null
        }

        case SIGNED_SLA:
            return {
            ...state,
            signedSla: actions.payload ? actions.payload : null
        }

        /** default action */
        default:
            return {...state}
    }
}