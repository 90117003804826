import moment from 'moment';
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const linkFormatter = (cell, option, formatExtraData, next) => {

    const {props}   = next
    const invoice   = ( option?.ledger ) ? findLedgerBill( props, option?.ledger ) : false
    return (
      <>
        { 
          (invoice && invoice.work_order) &&
          <a target="_blank" href={props?.constants?.MEDIAURL+invoice?.work_order?.invoice?.path+'/'+invoice?.work_order?.invoice?.name} download class="pdf_button">pdf</a>
        }
      </>
    )
}

const amountFormatter = (cell, option, formatExtraData, next) => {

  const {props}   = next
  try {
      let formattedValue  = props?.helper?.currencySign((option?.amount).replace(',', ''), false)
      
      return (
          <span className={option?.type == "debit" ? "ledger_red" : ""}>
            {
              (option?.amount == '-' || option?.amount == '$0') ? '0.00' : formattedValue
            }
          </span>
        )
  } catch (error) {
    
  }
    return option?.amount
}

const balanceFormatter = (cell, option, formatExtraData, next) => {

  const {props}   = next
  try {
      let formattedValue  = props?.helper?.currencySign((option?.balance).replace(',', ''), false)
      
      return (option?.balance == '-' || option?.balance == '$0') ? '0.00' : ( option?.balance == '' ? '-' : formattedValue )
  } catch (error) {
    
  }
    return option?.balance
}


const findLedgerBill = ( props, ledgerData ) => {

  const { propertyBill } = props
  let LedgerInvoice     = false
 
  if( propertyBill && Array.isArray(propertyBill) )
  {
      propertyBill.map(function( invoiceData, niddle ){

          if( invoiceData?.ledger_entry_id === ledgerData?.ledger_entry_id )
            LedgerInvoice = invoiceData
      })
  }
  return LedgerInvoice
}

const descriptionFormatter = (cell, option) => {

  if( option?.ledger?.transfer_to && option?.type == "credit" )
      return option?.description + " " + `(${option?.ledger?.transfer_to?.property_from?.address ?? null})`  

  else if( option?.ledger?.transfer_from && option?.type == "debit" )
      return option?.description + " " + `(${option?.ledger?.transfer_from?.property_to?.address ?? null})`  
      
  else
    return option.description
}

const dateFormatter = (cell, option) => {
    return option.added_on ? moment(option.added_on)?.format('D.MM.Y') : "Date"
}

const defaultSorted = [{

    dataField: 'added_on',
    order: 'asc'
}];

const sortDates = (a, b, order, dataField, rowA, rowB) => {
 try {
      let colA  = rowA?.index
      let colB  = rowB?.index
      if (order === 'asc')
      {
        return colA - colB
      }
    else if (order === 'desc') {
        return  colB - colA 
    }
 } catch (error) {
   
 }
}
const Ledger = (props) => { console.log(props, 'props');

  const columns = [{

      dataField: 'date',
      text: `DATE`,
      sort: true,
      sortFunc: sortDates ,
      classes: 'dash-action',
      formatter: dateFormatter, formatExtraData: { props }
    }, {
      dataField: 'description',
      text: 'DESCRIPTION',
      formatter: descriptionFormatter
    },
    {
      dataField: 'amount',
      text: 'AMOUNT',
      formatter: amountFormatter, formatExtraData: { props }

    }, {
      dataField: 'balance',
      text: 'BALANCE',
      formatter: balanceFormatter, formatExtraData: { props }

  }, {
    dataField: 'ledger.invoice',
    text: '',
    classes: 'dash-action',
    formatter: linkFormatter, formatExtraData: { props }
  }];
    return (

        <BootstrapTable 
            classes={`table fixTableHead property-ledger-table _ledger_table _ledger_table_thead_heading  ${ !props.ledgerList?.length ? "" : ""}`}
            bootstrap4
            keyField='ledger_entry_id' 
            data={ props.ledgerList } 
            columns={ columns } 
            defaultSorted={ defaultSorted } 
            noDataIndication="No Ledger Found!"
            rowClasses={"w-full"}
        />
    )
} 

export default Ledger
