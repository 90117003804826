import React, {Component, useState} from 'react';
import propTypes from 'prop-types'
import { connect } from 'react-redux';
import {Button, Modal} from 'react-bootstrap'
import POP_IMG from './../../../../component/assets/img/account_reference.png'
import CHECKED from './../../../../component/assets/img/checked.png'
import UPDOWN from './../../../../component/assets/img/up-down.png'
import swal from 'sweetalert2'
import { findOwnerProperty, getProfile, addBankDetails, updateBankDetails, deleteBankDetails, selectBank, updateProfile, addSecondAuthority, deleteSecondAuthority, forRedirection, applyNonResident, copyToAll} from './../../../../modules/redux/actions/listActions'


class Foreign extends Component{

    constructor(props)
    {
        super(props)
        this.state  = {
            creditType  : 'all',
            show : false,
            showPop : false,
            showEditPop : false,
            customer_account_id : '',
            account_name : '',
            fin_institution_no : '',
            account_number : '',
            transit : '',
            address: '',
            p_email: '',
            country: 0,
            state: 0,
            city:0,
            personal:false,
            authorization:false,
            banking:false, 
            isBankDetailsEdit: false,
            edit:false
        }
    }

    /**
     * mount component once render the initial component 
     * 
     * @param {*} NA
     * @return {*} NA
    */
    componentDidMount()
    {
        document.title = "Profile | RentPerks"
        const { match, authData } = this.props
        this.props.findOwnerProperty(authData.user.customer_id)
        let propId = this.props?.match?.params?.id
        let customer_id = authData?.user?.customer_id
        let type = 'country'
        this.props.getProfile(propId, type, customer_id)
        
        this.setState({
            country:this?.props?.proData?.userLocation?.country,
            state:this?.props?.proData?.userLocation?.state,
            city:this?.props?.proData?.userLocation?.city,
        })
    }

    componentWillMount() {
        const { match, authData } = this.props
        this.props.findOwnerProperty(authData.user.customer_id)
        let propId = this.props?.match?.params?.id
        let customer_id = authData?.user?.customer_id
        let type = 'country'
        this.props.getProfile(propId, type, customer_id)
    }

    componentDidUpdate(prevProps)
    {
        document.title = "Profile | RentPerks"
        const { props } = this
        const {match, authData} = this.props
        if( match?.params?.id != prevProps?.match?.params?.id){
            this.props.findOwnerProperty(authData.user.customer_id)
            let propId = this.props?.match?.params?.id
            let customer_id = authData?.user?.customer_id
            let type = 'country'
            this.props.getProfile(propId, type, customer_id)
        }

        let propCount = props?.propertyList?.length

        if(propCount >= 1 && props?.location?.pathname == '/profile'){
            let f_prop_id = props?.propertyList?.[0]?.property_id
            props.history.push('/profile/'+f_prop_id)
        }
    }

    render(){

        const { props, state } = this
        const { proData, constants, match } = props
        const { address, p_email  }  = state
        const accounts = props?.proData?.accounts
        const countries = props?.proData?.countries
        const states = props?.proData?.states    
        const allState = props?.proData?.allStates    
        const cities = props?.proData?.cities
        const allCities = props?.proData?.allCities
        const selected_bank = props?.proData?.property?.selected_bank
        const foreign_resident = props?.proData?.property?.foreign_resident
        const profile = props?.proData?.profile
        const location = props?.proData?.userLocation
        const customer_id = props?.proData?.profile?.customer_id
        const propId = this.props?.match?.params?.id
        const secondAuthority = props?.proData?.secondAuthority

        let isCheckedEmail = (profile?.details?.email_notify != 'N') ? true : false
        let isCheckedSms = (profile?.details?.sms_notify != 'N') ? true : false

        return (
            <div className='foreign-section'>
                <div class="modal-header">
                    <div class="modal-title h4 foreign-title">Foreign Resident</div>
                    <button class="close-button" onClick={() => props?.handleForeignEventClose()}><i class="fa fa-times" aria-hidden="true"></i></button>
                </div>
                
                <form name="foreign-resident" encType='formData/multipart' onSubmit={(el) => handleForeignCheckEvent(el, props)}>
                    <div className='row property-list-section'>
                        <div className='col-lg-10 col-xl-10 col-md-12 col-sm-12'>
                            <div className='row'>
                                {
                                    this?.renderProperty(props)
                                }
                                <div className='col-lg-2 col-xl-2 col-md-2 col-sm-2'></div>
                                <div className='col-lg-2 col-xl-2 col-md-2 col-sm-2'></div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-10 col-xl-10 col-md-12 col-sm-12'>
                            <div class="text-center mt-3 p-0">
                                <button type="submit" class="custom-button">Save Changes</button>
                            </div>
                        </div>
                    </div>
                </form>                
            </div>
        )
    }

    renderProperty(props){

        return props?.propertyList?.map((property, key) => {

            return (
                <>
                    <div className='col-lg-8 col-xl-8 col-md-12 col-sm-12'>
                        <input type="text" className='property-address' name="" value={property?.address} />
                        <input type="hidden" name={`property-address-${property?.property_id}`} value={property?.property_id} />
                    </div>
                    <div className='col-lg-2 col-xl-2 col-md-2 col-sm-2 text-center'>
                        <input type="checkbox" name={`property-checked-${property?.property_id}`} defaultChecked={property?.foreign_resident ? true : false} className='property-checked-box' />
                    </div>
                </>
            )
        })
    }
}

/**
 * add bank details
 * 
 * @param {object} el 
 * @return {*}
 */
const handleAddBankDetailsSubmitEvent = (el, props, customer_id) => {

    el.preventDefault();

    let formData    = new FormData();
    formData.append("account_name", el.target.a_account_name.value);
    formData.append("eft_qid", el.target.a_fin_institution_no.value);
    formData.append("route", el.target.a_fin_institution_no.value);
    formData.append("account_number", el.target.a_account_number.value);
    formData.append("transit", el.target.a_transit.value);
    formData.append("customer_id", customer_id);
    formData.append("customer_account_id", el?.target?.customer_account_id?.value);
    
    props.addBankDetails(formData)
} 

/**
 * add bank details
 * 
 * @param {object} el 
 * @return {*}
 */
const handleUpdateBankDetailsSubmitEvent = (el, props, customer_id) => {

    el.preventDefault();

    let formData    = new FormData();

    formData.append("account_name", el.target.a_account_name.value);
    formData.append("eft_qid", el.target.a_fin_institution_no.value);
    formData.append("route", el.target.a_fin_institution_no.value);
    formData.append("account_number", el.target.a_account_number.value);
    formData.append("transit", el.target.a_transit.value);
    formData.append("customer_id", customer_id);
    formData.append("customer_account_id", el?.target?.customer_account_id?.value);
    
    props.updateBankDetails(formData)
}

const handleForeignCheckEvent = async(el, props) => {

    el.preventDefault();
    let formData    = new FormData();

    props?.propertyList?.map((property, key) => 
    {
        formData.append(el?.target[`property-address-${property?.property_id}`]?.value, el?.target[`property-checked-${property?.property_id}`]?.checked);
    });

    await props?.applyNonResident(formData);
    props?.handleForeignEventClose()
} 

/** validate function is or not */
Foreign.propTypes = {
    findOwnerProperty: propTypes.func.isRequired,
    getProfile: propTypes.func.isRequired,
    addBankDetails: propTypes.func.isRequired,
    updateProfile: propTypes.func.isRequired,
    updateBankDetails: propTypes.func.isRequired,
    deleteBankDetails: propTypes.func.isRequired,
    addSecondAuthority: propTypes.func.isRequired,
    forRedirection: propTypes.func.isRequired,
    deleteSecondAuthority: propTypes.func.isRequired,
    applyNonResident: propTypes.func.isRequired,
    selectBank: propTypes.func.isRequired,
    copyToAll: propTypes.func.isRequired,
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    authData: state.dataPack.authData,
    propertyList: state.dataPack.propertyList,
    proData: state.dataPack.profileData,
})
export default connect (mapStateToProps, {findOwnerProperty, getProfile, addBankDetails, updateBankDetails, deleteBankDetails, selectBank, updateProfile, addSecondAuthority, deleteSecondAuthority, forRedirection, applyNonResident, copyToAll})( Foreign )