import React from 'react';
import moment from 'moment'



const Ledger = (props) => {

    const {setDate}   = props

    const dates     = moment(setDate);
    const days      = dates.format('DD')
    const month     = dates.format('MM')
    const year      = dates.format('YY')

    return (
        <>
            <div className="col-sm-4 col-md-4 col-lg-4 date_selector_year"><span>{month[0]}</span><span>{month[1]}</span></div>
            <div className="col-sm-4 col-md-4 col-lg-4 date_selector_year"><span>{days[0]}</span><span>{days[1]}</span></div>
            <div className="col-sm-4 col-md-4 col-lg-4 date_selector_year"><span>{year[0]}</span><span>{year[1]}</span></div>
        </>
    )
} 

export default Ledger