import React, {Component, useState} from 'react';
import {Link} from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types'
import moment from 'moment'
import ItemsCarousel from 'react-items-carousel';
import NO_PROPERTY_IMAGE from './../../../../../component/assets/images/home.png'
import PIGGY_BANK_ICON from './../../../../../component/assets/images/piggy-bank.svg'
import swal from 'sweetalert2';
import {Modal} from 'react-bootstrap'
import ReactDOM from 'react-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DEFAULT_IMAGE from './../../../../../component/assets/img/owner-portal/default_image.png'
import { findPropertyLedger, findLeaseDetails, findPropertyDocument, findPropertyBill, applyNonResident } from './../../../../../modules/redux/actions/listActions'

class PropertySlider extends Component { // eslint-disable-line react/prefer-stateless-function
    constructor(props){
        super(props)
        this.state = {
            autoPlayDelay : 6000,
            chevronWidth : 0,
            noOfItems : 4,
            noOfCards : 4,
            activeItemIndex: -1,
            propertyId: 0,
            showNonResiPop: false,
            showDiv : false,
            propIdForResi : false
        }
    }
    /**
     * slider rotate
     */
    tick  = () => 
    { 
        const {noOfItems, noOfCards, activeItemIndex }  = this.state
        this.setState(prevState => ({
            activeItemIndex: (activeItemIndex + 1) % (noOfItems-noOfCards + 1),
        }));
    }
    render() {

        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            centerPadding: "20px",
            responsive: [
              {
                breakpoint: 1366,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 820,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
        };

        const { state, props }  = this
        const { activeItemIndex , chevronWidth} = state
        let index              = props?.propertyList?.findIndex( list => list?.property_id == props?.match?.params?.id)
        let propCount = props?.propertyList?.length

        if(propCount == 1 && this.props.location.pathname == '/tenancy'){
            let f_prop_id = props?.propertyList?.[0]?.property_id
            this.props.history.push('/tenancy/'+f_prop_id)
        }
        return (
            <>

                <Slider {...settings}>
                    {
                        this.renderSliderItems(props)
                    }
                </Slider>
                {/* <div className="dashbord-slider" style={{ padding: `0 ${chevronWidth}px` }}>
                    <ItemsCarousel
                        requestToChangeActive={(el) => this.setState({ activeItemIndex: el })}
                        activeItemIndex={(activeItemIndex >= 0) ? activeItemIndex : index}
                        numberOfCards={4}
                        activePosition={"center"}
                        gutter={20}
                        leftChevron={<button className="ant-btn ant-btn-circle">{'<'}</button>}
                        rightChevron={<button className="ant-btn ant-btn-circle">{'>'}</button>}
                        outsideChevron={true}
                        chevronWidth={chevronWidth}
                        infiniteLoop={true}
                    >
                    {/* {this.renderSliderItems(props)} 
                    </ItemsCarousel>
                </div> */}

                <Modal show={this.state.showNonResiPop} size="lg">
                    <Modal.Header>
                        <h2>NON RESIDENT FOR THIS TENANCY</h2>
                        <button className="close-btn-m" onClick={() => (this.setState({showNonResiPop:false}))}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </Modal.Header>
                    <Modal.Body className="non-resident-pop">
                        <div className="row non-resi-header">
                            <div className="col-md-12">
                                <p>HI. YOU ARE DECLARING YOURSELF AS NON RESIDENT FOR THIS TENANCY. THAT WILL RESULT IN TAX WITH-HOLDING. PLEASE SELECT FROM BELOW:</p>
                            </div>
                        </div>
                        <form className="row" id="frm_non_resident" autoComplete="off" onSubmit={(el) => handleApplyNonResident(el, this, props, 483)}>                            
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="checkbox">
                                        <label htmlFor={"standard-rate"}>
                                            <input 
                                                name="tax_type" 
                                                ref="tax_type" 
                                                id={"standard-rate"} 
                                                type="radio" 
                                                value="STANDARD_RATE" 
                                                onChange={ (el) => this.handleShowInputFields(el) }
                                            /> STANDARD RATE OF WITH-HOLDING
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="checkbox">
                                        <label htmlFor={"reduce-rate"}>
                                            <input 
                                                name="tax_type" 
                                                ref="tax_type" 
                                                id={"reduce-rate"} 
                                                type="radio" 
                                                value="REDUCED_RATE"
                                                onChange={ (el) => this.handleShowInputFields(el) }
                                            /> REDUCED RATE
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={this.state.showDiv== false ? "col-md-12 hide" : 'col-md-12 show'}>
                                <p className="non-resi-note">PLS ATTACH YOUR OFFICIAL TAX AGENCY FORM SHOWING THE REDUCED AMOUNT ENTERED. IF YOU DON’T ATTACH A FILE WE CANNOT REDUCE THE TAX AMOUNT UNTIL APPROVED. WE WILL CONTACT YOU IN THAT CASE.</p>
                            </div>

                            <div className={this.state.showDiv== false ? "col-md-12 hide" : 'col-md-12 show'}>
                                <div className="form-group">
                                    <input type="file" name="document" ref="document" className="form-control-file field_text_style" required="" />
                                    <span className="help-block with-errors"></span>
                                </div>
                            </div>
                            <div className={this.state.showDiv== false ? "col-md-12 hide" : 'col-md-12 show'}>
                                <div className="form-group">
                                    <input type="number" name="tax_rate" ref="tax_rate" placeholder="Enter tax amount (eg: $125.50)" className="field_text_style" required="" />
                                    <span className="help-block with-errors"></span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-danger btn-sm pop-save-btn">SAVE</button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
          );
    }

    /* slider items with images
     * 
     * @param {*} NA
     * @return { response } 
    */
    renderSliderItems(props) 
    {
        const { url, params } = props.match
        const { state } = this
        const { propertyList, constants, match } = props
        const urlString  = props.helper.explode(match.path)
        return propertyList.map((item, index) => {
            
            let lease_start_day = moment(item?.lease?.lease_start).format('D')
            const mediaUrl  = (item.property_media && item.property_media.media ) ? item.property_media.media.path + '/thumb/' + item.property_media.media.name : ''
            
            return (
                <>
                    <Link key={index} to={'/'+urlString+'/'+item.property_id} className={"item "+( (match.params && item.property_id == match.params.id) ? "activeSlider" : '' )} title={item.address} >

                        {/* <div className={"default-image dash-img"}>
                            <img onError={(el) => el.target.src = DEFAULT_IMAGE} src={constants.MEDIAURL+mediaUrl} alt="Title" className="active" onLoad={() => {"Loading..."}}/>
                        </div> */}
                        <div className='swiper-slide'>
                            <div className="slider-wrapper d-flex flex-column">
                                <div className="property-image">
                                    <img src={constants.MEDIAURL+mediaUrl} onError={(el) => el.target.src = DEFAULT_IMAGE}/>
                                </div>
                                <div className="property_details">
                                    <div className="property_title"><p>{item?.address?.substring(0, 25)}</p></div>
                                    <div className="row property_details_list">
                                    <div className="col-xl-6 col-lg-6 col-md-6 property_details_heading">Monthly Rent</div>
                                    <div className="col-xl-6 col-lg-5 col-md-6 property_details_value">{item?.lease ? `$${props?.helper?.currencySign(item?.lease?.rent)}` : `${props?.helper?.currencySign(item?.rent)}`}</div>
                                    <div className="col-xl-6 col-lg-7 col-md-6 property_details_heading">Rent Arrears</div>
                                    <div className="col-xl-6 col-lg-5 col-md-6 property_details_value">{item?.ar?.total_debt_rent ? `$${item?.ar?.total_debt_rent}` : "0.0"}</div>
                                    <div className="col-xl-7 col-lg-7 col-md-6 property_details_heading">rent Due Date</div>
                                    <div className="col-xl-5 col-lg-5 col-md-6 property_details_value">
                                        {
                                            lease_start_day == 1 ?
                                            "1st" :
                                            (
                                                lease_start_day == 2 ?
                                                "2nd" :
                                                (
                                                    lease_start_day == 3 ?
                                                    "3rd"
                                                    :
                                                    lease_start_day+'th'
                                                )
                                            )
                                        }
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-lg-12">  
                                    
                                        <div className="property_details_button" ><div key={index} link={constants?.LOCALDEV+`pub/service_agreement/find_property_sla/${item?.property_id}/${item?.customer_id}/${item?.service_type?.parent_id}`} onClick={(el) => this.handleDocument(el)}>My SLA</div></div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>               
                    </Link>
                    {/* {
                        url.includes('profile') &&
                        
                        <div className="slider-bank-details">
                            <span>
                                <i className="fa fa-university" aria-hidden="true"></i> {item.account_number != null ? item.account_number : 'Not Selected'}
                            </span>
                            <div className="checkbox">
                                <label htmlFor={"nonResident-" + index}>
                                    <input 
                                        id={"nonResident-" + index} 
                                        type="checkbox" 
                                        defaultChecked={item.foreign_resident == 1 ? 'checked' : ''} 
                                        onChange={ (el) => this.handleSetResidentEvent(el, el.target.checked, item.property_id) } 
                                    /> non-resident
                                </label>
                            </div>
                        </div>
                    } */}
                </>
            )
        });
    }
    /* slider items with images
     * 
     * @param {*} NA
     * @return { response } 
    */
    // renderSliderItems(props) 
    // {
    //     const { url, params } = props.match
    //     const { state } = this
    //     const { propertyList, constants, match } = props
    //     const urlString  = props.helper.explode(match.path)
    //     return propertyList.map((item, index) => {
    //         const mediaUrl  = (item.property_media && item.property_media.media ) ? item.property_media.media.path + '/thumb/' + item.property_media.media.name : ''
    //         return (
    //             <>
    //                 <Link key={index} to={'/'+urlString+'/'+item.property_id} className={"item "+( (match.params && item.property_id == match.params.id) ? "activeSlider" : '' )} title={item.address} >

    //                     {/* <div className={"default-image dash-img"}>
    //                         <img onError={(el) => el.target.src = DEFAULT_IMAGE} src={constants.MEDIAURL+mediaUrl} alt="Title" className="active" onLoad={() => {"Loading..."}}/>
    //                     </div> */}
    //                     <div class="slider-wrapper d-flex flex-column">
    //                         <div class="property-image"><img src="./assets/img/owner-portal/default_image.png" onError={(el) => el.target.src = DEFAULT_IMAGE}/></div>
    //                         <div class="property_details">
    //                             <div class="property_title">3501 Centre B Street, NW</div>
    //                             <div class="row property_details_list">
    //                             <div class="col-lg-6 property_details_heading">Monthly Rent</div>
    //                             <div class="col-lg-6 property_details_value">$2,200</div>
    //                             <div class="col-lg-6 property_details_heading">Arrears</div>
    //                             <div class="col-lg-6 property_details_value">$2,200</div>
    //                             <div class="col-lg-6 property_details_heading">rent Due Date</div>
    //                             <div class="col-lg-6 property_details_value">12th</div>
    //                             </div>
    //                             <div class="row">
    //                             <div class="col-lg-12">
    //                                 <div class="property_details_button">
    //                                 <a href="">My SLA</a>
    //                                 </div>
    //                             </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className="dash-text"> <p>{item.address}</p> </div>                    
    //                 </Link>
    //                 {
    //                     url.includes('profile') &&
                        
    //                     <div className="slider-bank-details">
    //                         <span>
    //                             <i className="fa fa-university" aria-hidden="true"></i> {item.account_number != null ? item.account_number : 'Not Selected'}
    //                         </span>
    //                         <div className="checkbox">
    //                             <label htmlFor={"nonResident-" + index}>
    //                                 <input 
    //                                     id={"nonResident-" + index} 
    //                                     type="checkbox" 
    //                                     defaultChecked={item.foreign_resident == 1 ? 'checked' : ''} 
    //                                     onChange={ (el) => this.handleSetResidentEvent(el, el.target.checked, item.property_id) } 
    //                                 /> non-resident
    //                             </label>
    //                         </div>
    //                     </div>
    //                 }
    //             </>
    //         )
    //     });
    // }

    handleDocument(el)
    {
        el.preventDefault();
        let url = el?.target?.getAttribute('link');
        // window.location.href = url;
        window.open(url, '_blank');
        return true
    }
    /**
     * get property ledger list on property select
     *   
     * @param {object} el 
     * @param {object} item
     * @return {*}
    */
    handleEvents = async(el, item) => 
     {
        el.preventDefault();
        const { props }     = this
        const propertyId    = item.property_id
        const data      = {
            propertyId  : propertyId,
            creditType  : props.creditType,
            startFrom   : moment(props.start).format('L'),
            endTo       : moment(props.end).format('L')
        }
        this.setState({
            propertyId: propertyId
        }, async() => {
            if( props.match.path  === '/tenancy_bills_docs'){
                await props.findPropertyDocument( propertyId )
                await props.findPropertyBill( propertyId )
            }
            else{
                await props.findPropertyLedger( data )
            }
            await props.findLeaseDetails( propertyId )
        })
     }

    handleSetResidentEvent = (el, checked, propId) => {
        if(el.target.checked){
            this.setState({showNonResiPop:true, propIdForResi: propId})
        } else {
            el.target.checked = false
        }
    }

    handleShowInputFields = (el) => {
        if(el.target.checked && el.target.value == 'REDUCED_RATE'){
            this.setState({showDiv:true})
        } else {
            this.setState({showDiv:false})
        }
    }
}

/**
 * apply non resident for reducing tax
 * 
 * @param {object} el 
 * @return {*}
 */
 const handleApplyNonResident = (el, obj, props, property_id) => {

    el.preventDefault();

    let formData    = new FormData();
    formData.append("property_id", property_id);
    formData.append("tax_type", el.target.tax_type.value);
    formData.append("tax_rate", el.target.tax_rate.value);
    formData.append("document", el.target.document.value);
    
    props.applyNonResident(formData)

    obj.setState({showNonResiPop:false})
}

/** validate function is or not */
PropertySlider.propTypes = {
    findPropertyLedger: propTypes.func.isRequired,
    findLeaseDetails: propTypes.func.isRequired,
    findPropertyDocument: propTypes.func.isRequired,
    findPropertyBill: propTypes.func.isRequired,
    applyNonResident: propTypes.func.isRequired
}
/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    helper: state.dataPack.helper,
    constants: state.dataPack.constants,
})
export default connect (mapStateToProps, {findPropertyLedger, findLeaseDetails, findPropertyDocument, findPropertyBill, applyNonResident})( PropertySlider )