import React, { Component } from 'react'
import { connect } from 'react-redux';
import moment from 'moment'
import NO_PROPERTY_IMAGE from './../../../../component/assets/images/home.png'
import { CSVLink } from "react-csv";
import {
  Link
} from "react-router-dom";


class List extends Component {
    
    constructor(props)
    { 
        super(props)
        
        this.state  = {
        }
        this._totalProperty      = 0
        this._transfer           = 0;
        this._paid               = 0;
        this._rent               = 0
        this._fee                = 0
        this._bank               = 0
        this._held               = 0
        this._arrears            = 0
        this.csvData            = []
    }

    /**
     * mount component once render the initial component 
     * 
     * @param {*} NA
     * @return {*} NA
    */
     componentDidMount()
     { 
       this._buildingFee        = 0
       this._transfer           = 0;
       this._paid               = 0;
       this._rent               = 0
       this._fee                = 0
       this._bank               = 0
       this._held               = 0
       this._arrears            = 0
       this.csvData             = []
     }

    /**
     * render proeprty table and it details
     * 
     * @param {*} NA
     * @return {obejct}
    */
    renderPropertyGroup(propertyGroup)
    {
      const { props } = this
          
      try {
        return propertyGroup.map( (option) => {

          const { constants } = this.props
          const mediaUrl      = option?.details?.media.path + '/thumb/' + option?.details?.media.name
          let tenancyLink     = `/building/${option.group_id}?start=${moment(props.start).format('L')}&end=${moment(props.end).format('L')}`

          let buildingStatus        = props.helper.buildingStatus( option )
          const {fee, paid, arrears, banked}         = this.getBuildingData(option)

          return (

            <tr>
              <td className="no-padding"><img onError={(el) => el.target.src=NO_PROPERTY_IMAGE} src={constants.MEDIAURL+mediaUrl} alt="Title" className="active" onLoad={() => {"Loading..."}}/></td>
              <td><Link to={tenancyLink}>{option.group_name.substring(0, 6)}</Link></td>
              <td className="no-padding"><div className={"doc_icon building_status_white " + (buildingStatus == 100 ? 'building_leased' : '') }>{ (buildingStatus < 100 ) ? buildingStatus : '' }</div></td>
              <td className="no-padding"><div className="">{ props.helper.currencySign(option.total_rent) }</div></td>
              <td className="no-padding"><div className="">{ fee }</div></td>
              <td className="no-padding"><div className="">{ props.helper.currencySign(paid) }</div></td>
              <td className="no-padding"><div className="">-</div></td>
              <td className="no-padding"><div className="">-</div></td>
              <td className="no-padding"><div className="">{props.helper.currencySign(arrears)}</div></td>
            </tr>
          ) 
        })
      } catch (error) { console.log(error, 'eororo');
        return 'No List found!'
      }
    }  

    /**
     * render proeprty table and it details
     * 
     * @param {*} NA
     * @return {obejct}
    */
     renderPropertyDetails(propertyGroup)
     {
       const { props } = this
           
       try {

        return propertyGroup.map( (option) => {
 
          const { constants } = this.props
          const mediaUrl  = option?.property_media?.media.path + '/thumb/' + option?.property_media?.media.name

          let fees          = props.helper.currencySign(this.fees(option, constants))
          let paid          = this.paid(option,constants)
          let held          = this.held(option, constants)
          let income        = this.banked(option)
          let totalIncome   = parseFloat(income) + parseFloat(held.credit)
          let balance       = parseFloat( totalIncome ) - parseFloat(held.debit)
          let arrears       = this.arrears(option, constants)
          let tenancyLink   = `/tenancy/${option.property_id}?start=${moment(props.start).format('L')}&end=${moment(props.end).format('L')}`

           return (
 
             <tr>
               <td className="no-padding"><img onError={(el) => el.target.src=NO_PROPERTY_IMAGE} src={constants.MEDIAURL+mediaUrl} alt="Title" className="active" onLoad={() => {"Loading..."}}/></td>
               <td><Link to={tenancyLink}>{option.address.substring(0, 6)}</Link></td>
               <td className="no-padding"><div className={"doc_icon building_status_white " + props.helper.buildingLeaseStatus( props,  option )}></div></td>
               <td className="no-padding"><div className="">{ props.helper.currencySign(option.rent) }</div></td>
               <td className="no-padding"><div className="">{ fees }</div></td>
               <td className="no-padding"><div className="">{ props.helper.currencySign(paid)}</div></td>
               <td className="no-padding"><div className="">{ props.helper.currencySign(totalIncome) }</div></td>
               <td className="no-padding"><div className="">{ props.helper.currencySign(balance, true) }</div></td>
               <td className="no-padding"><div className="">{ props.helper.currencySign(arrears)}</div></td>
             </tr>
           ) 
         })
       } catch (error) { console.log(error, 'eroror');
         return 'No List found!'
       }
     }

    render() {

      const { props } = this
        const { propertyList, group }  = props.folioData
        
        return (
          <>
            <div className="folio-title">
                <h1>Your Portfolio: <strong>{ this.getTotalProperty() } Doors</strong></h1>
            </div>
            <div className="folio_view folio_container_size parentCell">
            
              <table className="table table-bordered w98  height100 no-border">
                <thead>
                  <tr>
                    <th><div class="p-3 border no-right-border">Prop</div></th>
                    <th><div class="p-3 border">Address</div></th>
                    <th><div class="p-3 border">Status</div></th>
                    <th><div class="p-3 border no-right-border">Rent</div></th>
                    <th><div class="p-3 border no-right-border">Fees</div></th>
                    <th><div class="p-3 border no-right-border">Paid</div></th>
                    <th><div class="p-3 border">Banked</div></th>
                    <th><div class="p-3 border">Held</div></th>
                    <th><div class="p-3 border">Arrears</div></th>
                  </tr>
                </thead>
                <tbody>
                    { this.renderPropertyGroup(group) }
                </tbody>

                <tbody>
                    { this.renderPropertyDetails(propertyList) }
                </tbody>

                <tfoot>
                  <tr>
                    <th className="no-border">Total</th>
                    <th className="no-border"></th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>

                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="building_view_details">
              <CSVLink className="building_csv_btn" data={this.csvData} filename={"ledger.csv"}></CSVLink>
            </div>
          </>
        )
    }

    /**
     * find all building data
     * 
     * @param {object} option 
     */
    getBuildingData(option)
    { 
      let fee  = 0, paid   = 0, arrears = 0, banked = 0

      const { constants }  = this.props

      option?.property.map( property => {

          fee       += this.fees(property, constants)
          paid      += this.paid(property, constants)
          arrears   += this.arrears(property)
          banked    += this.banked(property)

      }, this)

      return { fee: fee, paid: paid, arrears: arrears, banked: banked }
    }

    /**
     * net transaction total 
     * 
     * @param {object} option 
     * @param {object} constants 
     * @returns 
     */
    transactions(option, constants)
    {
      
      const { ledgers }       = option
      let netBalance          = 0

      try {

        ledgers.forEach(element => {
          if(constants.BUILDING_AUTH_TRANSFER.includes((element.description).toLowerCase()))
          {
              if( element.type.toLowerCase() == constants.TYPES.DEBIT )
                netBalance  -= parseFloat(element.amount)
              else
                netBalance  += parseFloat(element.amount)
                
          } 
        });
        this._transfer   +=  netBalance
        return  netBalance
      } catch (error) {}

      return netBalance
    }

    /**
     * total fees
     * 
     * @param {object} option 
     * @param {object} constants 
     * @returns 
     */
     fees(option, constants)
     {
       const { ledgers } = option
       let netBalance    = 0
       
       try {
 
         ledgers.forEach(element => {
           
            if(constants.BUILDING_FEES_ENTRY.includes((element.description).toLowerCase()))
                netBalance  += parseFloat(element.amount)
         });

         /** total fees by the end of the calendar */
         this._fee  += netBalance

         return netBalance
       } catch (error) {}
 
       return netBalance
     }

    /**
     * total transfer to landloard
     * 
     * @param {object} option 
     * @param {object} constants 
     * @returns 
    */
    banked(option)
    {
      const { tenant_rent } = option
      let netBalance    = 0
      
      try {

        tenant_rent.forEach(element => {
         
            netBalance  += parseFloat(element.amount)
        });

        /** total banked by the end of the calendar */
        this._bank  += netBalance

        return netBalance
      } catch (error) { }

      return netBalance
    }

    /**
     * net paid total 
     * 
     * @param {object} option 
     * @param {object} constants 
     * @returns 
     */
     paid(option, constants)
     {
       const { work_order } = option
       let netBalance    = 0
       try {
 
        work_order.forEach(element => {
            if( element.txn_type.toLowerCase() == constants.TYPES.DEBIT )
              netBalance  = parseFloat(netBalance) + parseFloat(element.amount)
         });
         
         return netBalance
       } catch (error) {}
 
       return netBalance
     }

    /**
     * net past due fr last month
     * 
     * @param {object} option 
     * @param {object} constants 
     * @returns 
    */
    arrears(option)
     {
        try {

          if( option.lease && option.lease.ar )
              return option.lease.ar.total_debt_rent
        } catch (error) {
          return option.lease.ar.total_debt_rent
        }
        return '0.00';
     }

    /**
     * net past due fr last month
     * 
     * @param {object} option 
     * @param {object} constants 
     * @returns 
    */
    held(option, constants)
    {
      const { work_order } = option
      let totalDebit  =  0
      let totalCredit =  0
      try {

        work_order.forEach(element => {
          
          if( element.txn_type.toLowerCase() == constants.TYPES.DEBIT )
            totalDebit  += parseFloat(element.amount)
          else
            totalCredit  += parseFloat(element.amount)
        });

        return { debit: totalDebit, credit: totalCredit }
      } catch (error) {}

      return { debit: totalDebit, credit: totalCredit }
    }

    /**
     * life cycle method
     * @param {*} NA
     */
    componentDidUpdate(){
      
      this._transfer           = 0;
      this._paid               = 0;
      this._rent               = 0
      this._fee                = 0
      this._bank               = 0
      this._held               = 0
      this._arrears            = 0
      this.csvData              = []
    }

    /**
     * get total property count
     * 
     * @returns {*} NA
     */
    getTotalProperty(){

        const { propertyList, group }  = this.props.folioData

        let totalProperty   = propertyList?.length

        group?.map( option => {

            totalProperty += option?.property?.length
        })

        return totalProperty
    }
}


/** validate function is or not */
List.propTypes = {
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
})

export default connect (mapStateToProps, {})( List )

