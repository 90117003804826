import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import moment from 'moment'
import Panel from './../panel'
import Refer from './../panel/refer'
import Ledger from './ledger'
import Dates from './dates'
import { CSVLink } from "react-csv";
import DatePicker from 'react-mobile-datepicker';
import TransactionReport from './transactionReport'
import BillDue from './billDue'
import Overlay from './../../../../component/layout/overlay'
import Slider from './../../../../component/structure/slider'
import propTypes from 'prop-types'
import Enquiry from './enquiry'
import Upload from './bills/upload'
import Property from './bills/property'

import USERS from './../../../../component/assets/img/owner-portal/users.png'
import DOCUMENT from './../../../../component/assets/img/owner-portal/document.png'
import HOME_BLACK from './../../../../component/assets/img/owner-portal/home_black.png'
import HOME_WHITE from './../../../../component/assets/img/owner-portal/home_white.png'
import LINK_IMAGE from './../../../../component/assets/img/owner-portal/link.png'
import LINK_WHITE  from './../../../../component/assets/img/owner-portal/link_white.png'


import { findOwnerProperty, findPropertyLedger, findPropertyBill, findLeaseDetails, generatePDF, findPropertyDocument } from './../../../redux/actions/listActions'
import PropertySlider from './components/PropertySlider';

class Tenant extends Component {

    constructor(props) {
        super(props)
        const query = new URLSearchParams(props.location.search);

        this.state = {
            creditType: 'all',
            noBill: '0.00',
            start: query.get('start') ? new Date(query.get('start')) : new Date(moment().subtract(3, 'months').subtract(1, 'days').format('YYYY-MM-DD')),
            end: query.get('end') ? new Date(query.get('end')) : new Date(moment().add(1, 'days').format('YYYY-MM-DD')),
            isStart: false,
            isENd: false,
            el: false,
            modalShow: false,
            modalBillDueShow: false,
            enquiry: false,
            bills: false, 
            title: false,
            documents:false,
            dues:false,
            billPay:false
        }
    }
    /**
     * mount component once render the initial component 
     * 
     * @param {*} NA
     * @return {*} NA
    */
    componentDidMount() {
        document.title = "Tenancy View | RentPerks"
        const { match, authData } = this.props

        this.props.findOwnerProperty(authData.user.customer_id)
        this.handleDateFilter()
        this.props.findLeaseDetails(match.params.id)
    }

    /**
     * trigger apis on url param change
     * 
     * @param {object} prevProps
     * @return {*} 
    */
    componentDidUpdate(prevProps) {
        const { props } = this
        const { match } = this.props

        if (match.params.id != prevProps.match.params.id) {
            this.handleDateFilter()
            this.props.findLeaseDetails(match.params.id)
            this.props.findPropertyDocument( match.params.id )
        }
        let propCount = props?.propertyList?.length

        if (propCount >= 1 && props.location.pathname == '/tenancy') {
            let f_prop_id = props?.propertyList?.[0]?.property_id
            props.history.push('/tenancy/' + f_prop_id)
        }
    }

    /**
     * get total due bill
     * 
     * @param {object} billDueLedger 
     */
    totalDueBill(billDueLedger) {
        let total = 0
        if (billDueLedger) {
            billDueLedger.map(option => {
                total += parseFloat(option.amount)
            })
        }

        return total
    }

    findBillDueList() {
        const { state } = this
        const { billDue } = this.props
        let billDueLedger = []
        let totalDueBill = 0

        try {
            billDueLedger = billDue
            totalDueBill = this.totalDueBill(billDueLedger)
        } catch (error) {

        }

        return { totalDueBill: totalDueBill, billDueLedger: billDueLedger }
    }

    /**
     * get total arrease 
     * @param {object} props
     * 
     * @return 
     */
    getTotalArrease(props) {
        let arrease = 0

        try {
            const { ars } = props
            ars?.ars?.map((ar) => {

                if (ar?.total_debt_rent)
                    arrease += parseFloat(ar?.total_debt_rent)

                if (ar?.total_debt_bill)
                    arrease += parseFloat(ar?.total_debt_bill)
            })
        } catch (error) {
        }
        return arrease
    }

    render() {

        const { props, state } = this
        const { constants, leaseDetails, match, billDue, propertyList, loading } = props
        const ref = React.createRef();

        const _dueBill = this.findBillDueList()

        let billDueLedger = _dueBill.billDueLedger
        let totalDueBill = _dueBill.totalDueBill
        let leaseStatus = props.helper.leaseStatus(props)
        let totalArreases = this.getTotalArrease(props)

        
        return (
            <div className='row justify-content-center section_height'>
                <Panel {...props} />
                <div className='col-lg-10 col-md-10 col-sm-12 col-xl-10'>
                    <div className=''>
                        <PropertySlider {...props} creditType={state.creditType} />
                    </div>

                    {
                        state?.dues &&
                        <div class="d-flex flex-wrap align-content-end height-60">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                <BillDue 
                                    onClose={() => this.handleCloseEvent()} 
                                    title={state?.title} 
                                    constants={constants}
                                    show={state.modalBillDueShow} 
                                    start={state.start}
                                    end={state.end}
                                    billDueLedger={billDueLedger}
                                    subtitle={moment(state.start).format('MMMM D YYYY') +' - ' + moment(state.end).format('MMMM D YYYY')}
                                    handleBillDueRender={() => this.handleBillDueRender()}
                                />
                            </div>
                        </div>
                    }

                    <div class="row d-flex flex-wrap align-content-end">
                    {
                        (!state?.enquiry && !state?.bills && !state?.documents && !state?.dues) &&
                        <div class="col-lg-5 col-md-5 col-sm-12 col-xl-5 d-flex flex-wrap-reverse align-content-end action_section">  
                            <div onClick={() => this.setState({bills: true, title: "Add Document", billPay:false})} class="cursor-pointer details_box bg_black_opacity d-flex justify-content-center align-items-center flex-column">
                                <img src={LINK_IMAGE} class="details_box_img" alt="" />
                                <p class="details_box_text color_white">Add Document</p>
                            </div>
                            <div onClick={() => this.setState({bills: true, title: "Submit Bill", billPay:true})} class="cursor-pointer details_box bg_black_opacity d-flex justify-content-center align-items-center flex-column">
                                <img src={HOME_WHITE} class="details_box_img" alt="" />
                                <p class="details_box_text color_white">Submit Bills</p>
                            </div>
                            <div onClick={() => this.setState({enquiry: true})} class="cursor-pointer details_box bg_gold_opacity d-flex justify-content-center align-items-center flex-column">
                                <img src={HOME_BLACK} class="details_box_img" alt="" />
                                <p class="details_box_text color_black">Owner Inquiry</p>
                            </div>
                            <div onClick={() => this.setState({documents: true})} class="cursor-pointer details_box bg_gray_opacity d-flex justify-content-center align-items-center flex-column">
                                <img src={USERS} class="details_box_img" alt="" />
                                <p class="details_box_text color_black">Tenant File</p>
                            </div>
                            <div onClick={() => this.setState({dues: true})} class="cursor-pointer details_box bg_gray_opacity d-flex justify-content-center align-items-center flex-column">
                                <img src={DOCUMENT} class="details_box_img" alt="" />
                                <p class="details_box_text color_black">Bills Due</p>
                            </div>
                        </div>
                    }
                    {
                        state?.enquiry &&
                        <div class="col-lg-5 col-md-5 col-sm-12 col-xl-5 d-flex flex-wrap align-content-end">
                            <Enquiry onClose={() => this.handleCloseEvent()} list={propertyList} property_id={match.params.id}/>
                        </div>
                    }
                    {
                        state?.bills &&
                        <div class="col-lg-5 col-md-5 col-sm-12 col-xl-5 d-flex flex-wrap align-content-end">
                            <Upload onClose={() => this.handleCloseEvent()} title={state?.title}  billPay={state?.billPay}/>
                        </div>
                    }
                    {
                        state?.documents &&
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                            <Property 
                                start={state.start} 
                                end={state.end}
                                onClose={() => this.handleCloseEvent()} 
                                constants={constants} 
                                title={state?.title}
                            />
                        </div>
                    }
                        
                    {
                        (!state?.dues && !state?.documents) &&
                        <div class="col-lg-7 col-md-7 col-sm-12 col-xl-7  d-flex flex-wrap align-content-between h-full">
                            <div className="tenant-view-table">
                                <div ref={ref}>
                                    {
                                        props.match.params.id &&
                                        <Ledger {...props} />
                                    }
                                </div> 
                            </div> 
                            <div class="table_filter w-full">
                                {/* <div class="row date_range_selector text-end">
                                    
                                </div> */}
                                <div class="row date_range_selector">
                                    
                                    <div class="col-sm-12 col-md-10 col-lg-10 col-xl-10">
                                        <div class="d-flex justify-content-between">
                                            <div className='w-49'>
                                            <button className={"dash-report d3button paid_button "+(state.creditType === 'all' && 'active')} creditType="all" onClick={(el) => this.handleEvents(el)}>All</button>
                                            <button className={"dash-report d3button paid_button "+(state.creditType === 'credit' && 'active')} creditType="credit" onClick={(el) => this.handleEvents(el)}>$ To ME</button>
                                            </div>
                                            <div className='w-49'>
                                            { this.preDefinedDates() }
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 date_range">
                                                <div class="date_selector d-flex" onClick={(el) => this.setState({ isStart: true, isEnd: false, el })}>
                                                    <Dates setDate={state.start} />
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 date_range flex-wrap justify-content-end">
                                                {/* { this.preDefinedDates() } */}
                                                <div class="date_selector d-flex" onClick={(el) => this.setState({ isEnd: true, isStart: false, el })}>
                                                    <Dates setDate={state.end} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 text-center d-grid flex-wrap align-content-end">
                                        {
                                            loading ?
                                            <button class="download_button disabled" disabled> <i className='fa fa-spinner fa-spin'></i> PDF</button>
                                            :
                                            <button class="download_button" onClick={(el) => this.handlePdfRequest(el)}> PDF</button>
                                        }
                                        {
                                            loading ?
                                            <button className="download_button btn-csv disabled"> <i className='fa fa-spinner fa-spin'></i> CSV</button>
                                                :
                                            <CSVLink className="download_button btn-csv" data={this.getCsvData(props.ledgerList)} filename={"ledger.csv"}>CSV</CSVLink>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <DatePicker 
                        value={this.state.start} 
                        isOpen={this.state.isStart} 
                        isPopup={true} 
                        theme="android" 
                        confirmText="SET" 
                        cancelText="CANCEL" 
                        className="mobStart" 
                        onSelect={(time) => this.setState({ start: time, isStart: false }, () => { this.manageDates('start', time)})} 
                        onCancel={() => this.setState({ isStart: false })} 
                    />

                    <DatePicker 
                        value={this.state.end} 
                        isOpen={this.state.isEnd} 
                        theme="android" 
                        confirmText="SET" 
                        cancelText="CANCEL" 
                        className="mobEnd" 
                        onSelect={(time) => this.setState({ end: time, isEnd: false }, () => {this.manageDates('end', time)} ) } 
                        onCancel={() => this.setState({ isEnd: false })} 
                    />
                    <TransactionReport 
                        {...props} 
                        creditType={state?.creditType}
                        start={ moment(state.start).format('MMMM D YYYY')+'-'+ moment(state.end).format('MMMM D YYYY')}
                        show={state.modalShow}
                        handlePdfGenerat={() => this.handlePdfRender()}
                    />
            </div>
        )
    }

    handleCloseEvent(){

        console.log('tes');
        this.setState({
            enquiry:false,
            bills:false,
            documents:false,
            dues: false
        })
    }
    handlePdfRequest(el) {
        el.preventDefault()

        const data = {
            pdfData: JSON.stringify(document.getElementsByClassName('pdf_box')[0].innerHTML)
        }

        this.props.generatePDF(data)
    }
    getCsvData(data) {

        const { props } = this
        let csvData = [];
        try {
            data.map((option) => {

                csvData.push({
                    date: option?.date,
                    description: option?.description,
                    amount: option?.amount,
                    balance: option?.balance
                })
            })
        } catch (error) { }
        return csvData;
    }

    preDefinedDates() {

        const { state } = this
        const dates = [
            moment().subtract(3, 'months'),
            moment().subtract(2, 'months'),
            moment().subtract(1, 'months')
        ]

        return dates.map(function (option, index) {
            return <button className="dash-report d3button month_button" onClick={() => { this.refineFilter(option) }}>{option.format('MMM')}</button>
        }, this)
    }

    refineFilter(option) {
        this.setState({
            start: new Date(option.startOf('month')),
            end: new Date(option.endOf('month'))
        }, () => {
            this.handleDateFilter()
        })
    }

    /**
     * close pdf generat rendering
     * 
     * @param {*} NA
     */
    handlePdfRender() {
        this.setState({
            modalShow: false
        })
    }

    /**
     * close bill due component generat rendering
     * 
     * @param {*} NA
     */
    handleBillDueRender() {
        this.setState({
            modalBillDueShow: false
        })
    }

    /**
     * manage start and end dates 
     * 
     * @param {string} type 
     * @return {*}
     */
    manageDates(type) {
        const { start, end } = this.state
        try {

            if (type === 'start') {
                if (moment(start).format('x') > moment(end).format('x'))
                    this.setState({ end: start }, () => { this.handleDateFilter() })
                else
                    this.handleDateFilter()
            }
            else if (type === 'end') {
                if (moment(end).format('x') < moment(start).format('x'))
                    this.setState({ start: end }, () => { this.handleDateFilter() })
                else
                    this.handleDateFilter()
            }
        } catch (error) { }
    }

    /**
     * get property ledger list on property select
     *   
     * @param {object} el 
     * @return {*}
    */
    async handleDateFilter() {
        const { props, state } = this
        const propertyId = props.match.params.id
        const data = {
            propertyId: propertyId,
            creditType: state.creditType,
            startFrom: moment(state.start).format('L'),
            endTo: moment(state.end).format('L')
        }
        await props.findPropertyLedger(data)
        await props.findPropertyBill(propertyId)
    }

    /**
     * get property ledger list on property select
     *   
     * @param {object} el 
     * @return {*}
    */
    async handleEvents(el) {
        el.preventDefault();

        this.setState({

            creditType: el.target.getAttribute('creditType')
        }, () => {

            const { props, state } = this
            const propertyId = props.match.params.id

            const data = {
                propertyId: propertyId,
                creditType: state.creditType,
                startFrom: moment(state.start).format('L'),
                endTo: moment(state.end).format('L')
            }
            props.findPropertyLedger(data)
        })
    }

    handleLeaseDetailsEvent(el) {
        el.preventDefault();

        try {
            const { lease } = this.props?.ars
            const applicationIdLease = lease?.application_id

            const { MEDIAURL } = this.props.constants

            if (!applicationIdLease) {
                alert('No Lease Found!')
                return false;
            }
            window.open(MEDIAURL + "uploads/property_application/" + applicationIdLease + "/lease.pdf");
        } catch (error) {

        }
    }
}

/** validate function is or not */
Tenant.propTypes = {
    findOwnerProperty: propTypes.func.isRequired,
    findPropertyBill: propTypes.func.isRequired,
    findLeaseDetails: propTypes.func.isRequired,
    generatePDF: propTypes.func.isRequired,
    findPropertyDocument:propTypes.func.isRequired
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    propertyList: state.dataPack.propertyList,
    constants: state.dataPack.constants,
    helper: state.dataPack.helper,
    ledgerList: state.dataPack.ledgerList,
    leaseDetails: state.dataPack.leaseDetails,
    propertyBill: state.dataPack.propertyBill.wordOrder,
    authData: state.dataPack.authData,
    billDue: state.dataPack.propertyBill.billDue,
    ars: state.dataPack.propertyBill.property,
    loading:state.dataPack.processing
})

export default connect(mapStateToProps, { findPropertyDocument, findOwnerProperty, findPropertyLedger, findPropertyBill, findLeaseDetails, generatePDF })(Tenant)
