import React from "react";
import moment from 'moment'
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import Transaction from './transaction'
import TransactionFilter from './transactionReportFilter'
import Logo from './../../../../component/assets/images/login-logo.png'
class TransactionReport extends React.Component {
    
    componentDidUpdate()
    {
        const { show, creditType } = this.props
       
        if( show )
            this.exportPDFWithMethod(this.props)
    }
    render() {
        const { props } = this
        
        return (
            <div className="w-0">

                <div className="p-3 mt-3 border rounded pdf_box">
                    <div ref={summarycontainer => (this.summarycontainer = summarycontainer)} className="pdf_container">
                        { 
                            this.renderPdfHeader('SUMMARY REPORT')
                        }
                        <div className="height-auto mt-5">
                            <table className="table summaryTable">
                                <tr>
                                    <td>

                                        <table className="table subtable text-left">
                                            <tr>
                                                <th><div className="pl-2">Income</div></th>
                                                <th></th>
                                            </tr>
                                            {
                                                props?.creditType != "credit" &&
                                                <>
                                                    <tr>
                                                        <th><div className="pl-2">Total</div></th>
                                                        <td className="text-right"><strong className="font-weight-bold">{ props.constants.CURRENCY_SIGN}{this.props.helper.currencySign(this.renderTotalRent())}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan="2"><hr/></th>
                                                    </tr>
                                                </>
                                            }
                                            
                                            <tr className="bt-1">
                                                <th><div className="pl-2">Paid Out</div></th>
                                                <th></th>
                                            </tr>
                                            {
                                                this.renderAdminFees()
                                            }
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="height-auto mt-4">
                            <h5 className="text-left pt-2">TRANSACTION REPORT</h5>
                            <div className="tenant-view-table text-center height-auto mt-2">
                                {
                                    props?.creditType == "credit" ?
                                    <TransactionFilter {...props} />
                                    :
                                    <Transaction {...props} />
                                }
                            </div>
                        </div>
                    </div>
                {/* </PDFExport> */}
            </div>
        </div>
        );
    }
    
    /**
     * getting paid rent
     * 
     * @param {*} NA
     * @return {Object}
     */
    renderPaidRent()
    {   
        const { props }                 = this
        const { ledgerList, constants } = props
        const { TYPES }                 = constants

        if( ledgerList )
        {
            return ledgerList.filter(list => list.rents).map(function(ledgers){
                
                return ledgers.rents.map(function(option, index){
                    return (
                        <tr key={index}>
                            <td>{moment(option.paid_on).format('MMM DD, YYYY')}</td>
                            <td className="text-right">{props.helper.currencySign(option.rent)}</td>
                        </tr>
                    )
                })
            })
        }
        return false
    }

    /**
     * getting paid rent
     * 
     * @param {*} NA
     * @return {Object}
     */
     renderTotalRent()
     {  
         let totalRent                   = 0
         const { ledgerList } = this.props
 
         if( ledgerList )
         {
            ledgerList.filter(list => list.rents).map(function(ledgers){
                
                ledgers.rents.map(function(option){
                    totalRent += parseFloat(option.rent) 
                })
            })
         }
         return totalRent
     }

    renderPaidOut()
    {
        const { ledgerList, constants } = this.props
        const { SUMMARY_TYPE }   = constants

        if( ledgerList )
        {
            const sortedLedgerList = this.ledgerDataFromat(ledgerList)
            let summaryData         = this.getSummaryData(constants, sortedLedgerList)

            return (
                <>
                    <tr key={SUMMARY_TYPE.MAINTENANCE_BILL}>
                        <td>{SUMMARY_TYPE.MAINTENANCE_BILL}</td>
                        <td className="text-right">{summaryData[SUMMARY_TYPE.MAINTENANCE_BILL]}</td>
                    </tr>
                    <tr key={SUMMARY_TYPE.SALES_TAX}>
                        <td>{SUMMARY_TYPE.SALES_TAX}</td>
                        <td className="text-right">{summaryData[SUMMARY_TYPE.SALES_TAX]}</td>
                    </tr>
                    <tr key={SUMMARY_TYPE.NRTR_TAX}>
                        <td>{SUMMARY_TYPE.NRTR_TAX}</td>
                        <td className="text-right">{summaryData[SUMMARY_TYPE.NRTR_TAX]}</td>
                    </tr>
                    <tr>
                        <th>Total</th>
                        <td className="text-right"><strong className="font-weight-bold">{summaryData['totalPaidOUt']}</strong></td>
                    </tr>
                </>
            )
        }
        return false
    }

    /**
     * ledger list in summary view
     * 
     * @param {object} ledgerList
     * @return {Object} ledgerList
     */   
     ledgerDataFromat(ledgerList){

        let sortLedgerDate  = []
        ledgerList.forEach(element => {
            sortLedgerDate.push (element)
        });
    
        return sortLedgerDate.sort((a, b) => a.description.localeCompare(b.description));
    }


    renderAdminFees()
    {
        const { props } = this
        const { ledgerList, constants } = this.props
        const { SUMMARY_TYPE }   = constants
        let noValue              = "0.00"

        if( ledgerList )
        {
            const sortedLedgerList = this.ledgerDataFromat(ledgerList)
            let summaryData         = this.getSummaryData(constants, sortedLedgerList)
           
            let netProceeds         =  parseFloat(this.renderTotalRent()) - parseFloat(summaryData['totalExpensesNoFormat'])

            return (
                <>
                    {
                        summaryData[SUMMARY_TYPE.ADMIN_FEE] != noValue && 
                        <tr key={SUMMARY_TYPE.ADMIN_FEE}>
                            <td>{SUMMARY_TYPE.ADMIN_FEE}</td>
                            <td className="text-right">{summaryData[SUMMARY_TYPE.ADMIN_FEE]}</td>
                        </tr>
                    }
                    {
                        summaryData[SUMMARY_TYPE.MANAGEMENT_FEE] != noValue &&
                        <tr key={SUMMARY_TYPE.MANAGEMENT_FEE}>
                            <td>{SUMMARY_TYPE.MANAGEMENT_FEE}</td>
                            <td className="text-right">{summaryData[SUMMARY_TYPE.MANAGEMENT_FEE]}</td>
                        </tr>
                    }
                    {
                        summaryData[SUMMARY_TYPE.MAINTENANCE_FEE] != noValue &&
                        <tr key={SUMMARY_TYPE.MAINTENANCE_FEE}>
                            <td>{SUMMARY_TYPE.MAINTENANCE_FEE}</td>
                            <td className="text-right">{summaryData[SUMMARY_TYPE.MAINTENANCE_FEE]}</td>
                        </tr>
                    }
                    {
                        summaryData[SUMMARY_TYPE.LEASING_FEE] != noValue && 
                        <tr key={SUMMARY_TYPE.LEASING_FEE}>
                            <td>{SUMMARY_TYPE.LEASING_FEE}</td>
                            <td className="text-right">{summaryData[SUMMARY_TYPE.LEASING_FEE]}</td>
                        </tr>
                    }
                    {
                        summaryData[SUMMARY_TYPE.BOND_FEE] != noValue && 
                        <tr key={SUMMARY_TYPE.BOND_FEE}>
                            <td>{SUMMARY_TYPE.BOND_FEE}</td>
                            <td className="text-right">{summaryData[SUMMARY_TYPE.BOND_FEE]}</td>
                        </tr>
                    }
                    {
                        summaryData[SUMMARY_TYPE.MAINTENANCE_BILL] != noValue && 
                        <tr key={SUMMARY_TYPE.MAINTENANCE_BILL}>
                            <td>{SUMMARY_TYPE.MAINTENANCE_BILL}</td>
                            <td className="text-right">{summaryData[SUMMARY_TYPE.MAINTENANCE_BILL]}</td>
                        </tr>
                    }
                    {
                        summaryData[SUMMARY_TYPE.SALES_TAX] != noValue && 
                        <tr key={SUMMARY_TYPE.SALES_TAX}>
                            <td>{SUMMARY_TYPE.SALES_TAX}</td>
                            <td className="text-right">{summaryData[SUMMARY_TYPE.SALES_TAX]}</td>
                        </tr>
                    }
                    {
                        summaryData[SUMMARY_TYPE.NRTR_TAX] != noValue && 
                        <tr key={SUMMARY_TYPE.NRTR_TAX}>
                            <td>{SUMMARY_TYPE.NRTR_TAX}</td>
                            <td className="text-right">{summaryData[SUMMARY_TYPE.NRTR_TAX]}</td>
                        </tr>
                    }
                        
                    <tr>
                        <th><div className="pl-2">Total</div></th>
                        <td className="text-right"><strong className="font-weight-bold">{props?.creditType == "credit" ? props.helper.currencySign(summaryData['paidAmount']) : props.helper.currencySign(summaryData['totalExpensesNoFormat'])}</strong></td>
                    </tr>
                    <tr>
                        <th colSpan="2"><hr/></th>
                    </tr>
                    <tr className="bt-1">
                        <th><div className="pl-2">Net Proceeds</div></th>
                        <td className="text-right"><strong className="font-weight-bold">{props?.creditType == "credit" ? props.helper.currencySign(summaryData['paidAmount']) : this.props.helper.currencySign(netProceeds)}</strong></td>
                    </tr>
                </>
            )
        }
        return false
    }


    /**
     * summary view data summaries
     * 
     * @param {object} constants 
     * @param {object} sortedLedgerList 
     */
    getSummaryData(constants, sortedLedgerList)
    {
        const { props } = this
        let summaryData         = []
        let adminFee            = 0
        let managementFee       = 0
        let leasingFee          = 0
        let boondFee            = 0
        let maintenanceFee      = 0
        let sales               = 0
        let nrtrtax             = 0
        let maintenance         = 0
        let paidAmount          = 0
        const {SUMMARY_TYPE,ADMIN_LEDGER_NOT_IN, COMMA_SIGN, ALL_FEES_TYPES}    = constants
        try { 
            sortedLedgerList.filter(list => !ADMIN_LEDGER_NOT_IN.includes((list.description).toLowerCase())).map((option, index) =>{ 
           
                if( ALL_FEES_TYPES.ADMIN_FEE.includes((option.description).toLowerCase()))
                    adminFee     += parseFloat((option.amount).replace(COMMA_SIGN, ''))

                else if( ALL_FEES_TYPES.MANAGEMENT_FEE.includes((option.description).toLowerCase()))
                    managementFee     += parseFloat((option.amount).replace(COMMA_SIGN, ''))

                else if( ALL_FEES_TYPES.LEASING_FEE.includes((option.description).toLowerCase()))
                    leasingFee     += parseFloat((option.amount).replace(COMMA_SIGN, ''))

                else if( ALL_FEES_TYPES.BOND_FEE.includes((option.description).toLowerCase()))
                    boondFee     += parseFloat((option.amount).replace(COMMA_SIGN, ''))

                else if( ALL_FEES_TYPES.MAINTENANCE_FEE.includes((option.description).toLowerCase()))
                    maintenanceFee     += parseFloat((option.amount).replace(COMMA_SIGN, ''))

                else if( parseFloat(option?.ledger?.wo_txn ))
                    maintenance     += parseFloat((option.amount).replace(COMMA_SIGN, ''))

                else if( ALL_FEES_TYPES.SALES_TAX.includes((option.description).toLowerCase()))
                    sales     += parseFloat((option.amount).replace(COMMA_SIGN, ''))

                else if( ALL_FEES_TYPES.MAINTENANCE_BILL.includes((option.description).toLowerCase()))
                    maintenance     += parseFloat((option.amount).replace(COMMA_SIGN, ''))

                else if( ALL_FEES_TYPES.NRTR_TAX.includes((option.description).toLowerCase()))
                    nrtrtax     += parseFloat((option.amount).replace(COMMA_SIGN, ''))

                paidAmount  += parseFloat((option.amount).replace(COMMA_SIGN, ''))
            })

           
            /** formatted data */
            summaryData[SUMMARY_TYPE.ADMIN_FEE]         = props.helper.currencySign(adminFee)

            summaryData[SUMMARY_TYPE.MANAGEMENT_FEE]    = props.helper.currencySign(managementFee)
            summaryData[SUMMARY_TYPE.MAINTENANCE_FEE]   = props.helper.currencySign(maintenanceFee)
            summaryData[SUMMARY_TYPE.LEASING_FEE]       = props.helper.currencySign(leasingFee)
            summaryData[SUMMARY_TYPE.BOND_FEE]          = props.helper.currencySign(boondFee)

            summaryData[SUMMARY_TYPE.SALES_TAX]         = props.helper.currencySign(sales)
            summaryData[SUMMARY_TYPE.MAINTENANCE_BILL]  = props.helper.currencySign(maintenance)
            summaryData[SUMMARY_TYPE.NRTR_TAX]          = props.helper.currencySign(nrtrtax)

            let totalAdminFees                          = (adminFee  + managementFee   + maintenanceFee + leasingFee + boondFee) 
            let totalPAidOut                            = (sales  + maintenance   + nrtrtax)
            summaryData['totalAdminFees']               = props.helper.currencySign(adminFee  + managementFee   + maintenanceFee + leasingFee + boondFee) 
            summaryData['totalPaidOUt']                 = props.helper.currencySign(sales  + maintenance   + nrtrtax) 
            summaryData['totalExpences']                = props.helper.currencySign(parseFloat(totalAdminFees) + parseFloat(summaryData['totalPaidOUt']))
            summaryData['totalExpensesNoFormat']        = (parseFloat(totalAdminFees) + parseFloat(totalPAidOut))
            summaryData['paidAmount']                   = paidAmount;
           
        } catch (error) {
        }
        return summaryData
    }
 
     
    renderPdfHeader(title) {

        const { leaseDetails, propertyList, start, constants }  = this.props
        if( leaseDetails ){

            const response   = propertyList.filter(list => list.property_id == leaseDetails.property_id)
            const dates      = start.split('-')
            
            if( response )
            {
                const propertyDetails = response[0]
                return (
                    <div>
                        <img src="images/login-logo.png" alt="RentPerks | Logo" className=""/>
                        <h5 className="text-left pt-2">{title}</h5>
                        <p className="text-left mt-3"><b>Property Address:</b> {propertyDetails && propertyDetails.address}</p>
                        <p className="text-left"><b>Period Start: </b>{dates[0]}</p>
                        <p className="text-left"><b>Period End:</b> {dates[1]}</p>
                    </div>
                )
            }
        }
    }
}

export default TransactionReport
