import React, {useState} from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import propTypes from 'prop-types'
import {Button, Modal} from 'react-bootstrap';
import {handleImageUpload, findPropertyDocument} from './../../../../redux/actions/listActions'

import 'react-notifications-component/dist/theme.css'
import { useEffect } from 'react';
import moment from 'moment';

const Upload = (props) => {

    const param             = useParams()
    const { constants }     = props

    const [doc, setDoc]     = useState(2);
    const [show, setShow]   = useState(false);
    const [count, setCount] = useState(false);
    const [start, setStart] = useState(moment()?.subtract(1,'months').startOf('month')?.format('YYYY-MM-DD'));
    const [end, setEnd] = useState(moment()?.subtract(1,'months').endOf('month')?.format('YYYY-MM-DD'));
    const [amount, setAmount] = useState(new Date());
    const [bill, setBill] = useState(constants.DOCTYPE.TENANTPAY);

    const handleClose = () => setShow(false);
    const handleShow = (doctype) => { setDoc(doctype); setShow(true)};

    useEffect(() => [

        props.findPropertyDocument( props?.leaseDetails.property_id )

    ], [props?.leaseDetails.property_id])
    return (
        <>
            <div className='transparent_box w-full'>
                <div class="modal-header">
                    <div class="modal-title h4">{props?.title}</div>
                    <button type="button" class="close" onClick={() => props?.onClose()}>
                        <i className='fa fa-times'></i>
                    </button>
                </div>
                <form id="ownerEnquiry" onSubmit={(el) => handleUploadEvent(el, doc, setShow, props, start, end, amount, param, bill)}>
                    <Modal.Body>
                        {
                            props?.billPay &&
                            <>
                                <div className="form-group modal-body-element">
                                    <label htmlFor="exampleInputEmail1">Bill Pay:</label>
                                    <select className="form-control" name="bill_pay" required onChange={(el) => setBill(el?.target?.value)}>
                                        <option value={constants.DOCTYPE.TENANTPAY}>Tenant Pay</option>
                                        <option value={constants.DOCTYPE.OWNERPAY}>Owner Pay</option>
                                    </select>
                                </div>
                                {
                                    bill == constants.DOCTYPE.TENANTPAY &&
                                    <div className="form-group modal-body-element">
                                        <label htmlFor="exampleInputEmail1">Select Tenant:</label>
                                        <select className="form-control" name="tenant" required>
                                            { getTenantList(props)}
                                        </select>
                                    </div>
                                }
                            </>
                        }
                        
                        <div className="form-group modal-body-element">
                            <label htmlFor="exampleInputEmail1">Comments:</label>
                            <textarea className="form-control" name="comments" placeholder="Your Comments here..." rows="3" required maxLength="160" onKeyUp={(el) => setCount( (el.target.getAttribute('maxLength') - el.target.value.length)) }></textarea>
                            <p className="char_counter">Char left <span className="count">{count}</span></p>
                        </div>
                        {
                            props?.billPay &&
                            <>
                            <div className="form-group modal-body-element d-flex justify-content-between">
                                <div>
                                    <label htmlFor="exampleInputEmail1">Start Date</label>
                                    <input type="date" name="startDate" className="form-control" value={(start)?.toString()} onChange={(el) => setStart(el.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor="exampleInputEmail1">End Date</label>
                                    <input type="date" name="startDate" className="form-control" value={end} onChange={(el) => setEnd(el.target.value)}/>
                                </div>
                            </div>
                            <div className="form-group modal-body-element">
                                <label htmlFor="exampleInputEmail1">Bill Amount:</label>
                                <input value={amount} onChange={(el) => setAmount(el.target.value)} type="number" name="bill_amount" className="form-control" placeHolder='Bill Amount'/>
                            </div>
                        </>

                        }
                        <div className="form-group modal-body-element">
                            <label htmlFor="exampleInputEmail1">Attachment:</label>
                            <input type="file" name="attachment" className="form-control" required/>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary">{props?.title}</button>
                    </Modal.Footer> 
                </form>
            </div>
       </>
    )
} 
/**
 * find all tenant for the property
 * 
 * @param {object} props
 * @return {*}
 */
const getTenantList = (props)  => 
{
    const { tenant }    = props.documentBills
    
    if( tenant && tenant.list )
    {
        return tenant.list.filter( list => (moment(list?.lease?.tenancy_end_date)?.format('YYYY-MM-DD') >= moment()?.subtract(30, "days")?.format('YYYY-MM-DD') || moment(list?.lease?.tenancy_end_date).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')))?.map( function(option){
            return (

                <option value={option.customer?.customer_id}>{option.customer?.first_name+' '+option.customer?.last_name}</option>
            )
        })
    }
}

/**
 * handle image upload
 * 
 * @param {object} el 
 * 
 * @return {*}
*/
const handleUploadEvent = (el, doc, setShow, props, start, end, amount, param, bill) => 
{
    el.preventDefault();
    let file                = el.target.attachment.files[0];

    var formdata = new FormData();
    formdata.append("document", file);
    formdata.append("documentType", bill);
    formdata.append("comments", el.target.comments.value);
    formdata.append("propertyId", param?.id);
    formdata.append("tenantId", (el.target.tenant ? el.target.tenant.value : 0));
    formdata.append('userId', props?.authData?.user?.customer_id)
    formdata.append('start_date', start)
    formdata.append('end_date', end)
    formdata.append('bill_amount', amount)

    props.handleImageUpload(formdata)
    setShow(false)
}

/** validate function is or not */
Upload.propTypes = {
    handleImageUpload: propTypes.func.isRequired,
    findPropertyDocument: propTypes.func.isRequired
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    leaseDetails: state.dataPack.leaseDetails,
    constants: state.dataPack.constants,
    documentBills: state.dataPack.documentBills,
    authData: state?.dataPack?.authData
})
export default connect (mapStateToProps, {handleImageUpload, findPropertyDocument})( Upload )