import React from 'react'
import {Modal} from 'react-bootstrap'

export const LargeModal = (props) => {

    return (

        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Notes
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    props.imagePreviewUrl ?
                        <p className="text-center">
                        <img src={props.imagePreviewUrl} className="preview-image" />
                    </p> :
                    <>
                        <p>
                            {props.notes?.note}
                        </p>
                        <ul>
                        {
                            get_property_media(props)
                        }
                        </ul>
                    </>    
                }
                
            </Modal.Body>
            
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={() => props.onModalEvent()}>Cancel</button>
                {
                    props.imagePreviewUrl &&
                    <button type="button" className="btn btn-info" onClick={() => props.onSubmit()}>Upload</button>
                }
            </Modal.Footer>
           
        </Modal>
    )

    function get_property_media(props)
    {
        return props.notes?.notes && props.notes?.notes?.map(option => {
            return (
                <li>
                    {
                        option?.media?.mime == "application/pdf" ?
                        <a href={props?.mediaUrl+option?.media?.path+option?.media?.name} target="_blank" class="download_button _leased _red  ptb-5 plr-20">View PDF</a>
                        :
                        <img src={props?.mediaUrl+option?.media?.path+option?.media?.name} alt="" />
                    }
                </li>
            )
        })
    }
}