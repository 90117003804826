import React from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';

const Transaction = (props) => {
    
    return (
        <table className="table mt-0">
            <tbody>
                <tr><td tabIndex="0"><b>DATE</b></td>
                <td tabIndex="0"><b>DESCRIPTION</b></td>
                <td tabIndex="0"><b>AMOUNT</b></td>
                <td tabIndex="0"><b>BALANCE</b></td>
                </tr>
                {
                  renderTable(props)
                }
            </tbody>
        </table>
    )
} 

const renderTable  = (props) => {

  let { ledgerList }  = props
  const {COMMA_SIGN}            = props.constants
  
  return ledgerList.map( function(option, index) { 

    const propertyDetails  = (option && option.ledger && option.ledger.transfer_to) ? option.ledger.transfer_to.property_to : ''

    let formattedAmount  = props?.helper?.currencySign((option?.amount)?.replace(COMMA_SIGN, ''), false)
    let formattedBalance  = props?.helper?.currencySign((option?.balance)?.replace(COMMA_SIGN, ''), false)

      return (
        <tr key={index}>
            <td>{option.date}</td>
            <td className='pt-0 mt-0'>{option.description} { propertyDetails && `(${propertyDetails.address ?? null})`}</td>
            <td className="text-right">{(option?.amount == '-' || option?.amount == '$0') ? '0.00' : formattedAmount}</td>
            <td className="text-right">{ (option?.balance == '-' || option?.balance == '$0') ? '0.00' : formattedBalance }</td>
        </tr>
      )
  })
}

export default Transaction