import React, {Component, useState} from 'react';
import propTypes from 'prop-types'
import { connect } from 'react-redux';
import {Button, Modal} from 'react-bootstrap'
import POP_IMG from './../../../../component/assets/img/account_reference.png'
import swal from 'sweetalert2'
import { findOwnerProperty, getProfile, addBankDetails, updateBankDetails, deleteBankDetails, selectBank, updateProfile, addSecondAuthority, deleteSecondAuthority, forRedirection, applyNonResident, copyToAll} from './../../../../modules/redux/actions/listActions'


class Authorization extends Component{

    constructor(props)
    {
        super(props)
        this.state  = {
            creditType  : 'all',
            show : false,
            showPop : false,
            showEditPop : false,
            customer_account_id : '',
            account_name : '',
            fin_institution_no : '',
            account_number : '',
            transit : '',
            address: '',
            p_email: '',
            country: 0,
            state: 0,
            city:0,
            personal:false,
            authorization:false,
            banking:false, 
            isBankDetailsEdit: false,
            edit:false,
            cheque:false
        }
    }

    /**
     * mount component once render the initial component 
     * 
     * @param {*} NA
     * @return {*} NA
    */
    componentDidMount()
    {
        document.title = "Profile | RentPerks"
        const { match, authData } = this.props
        this.props.findOwnerProperty(authData.user.customer_id)
        let propId = this.props?.match?.params?.id
        let customer_id = authData?.user?.customer_id
        let type = 'country'
        this.props.getProfile(propId, type, customer_id)
        
        this.setState({
            country:this?.props?.proData?.userLocation?.country,
            state:this?.props?.proData?.userLocation?.state,
            city:this?.props?.proData?.userLocation?.city,
        })
    }

    componentWillMount() {
        const { match, authData } = this.props
        this.props.findOwnerProperty(authData.user.customer_id)
        let propId = this.props?.match?.params?.id
        let customer_id = authData?.user?.customer_id
        let type = 'country'
        this.props.getProfile(propId, type, customer_id)
    }

    componentDidUpdate(prevProps)
    {
        document.title = "Profile | RentPerks"
        const { props } = this
        const {match, authData} = this.props
        if( match?.params?.id != prevProps?.match?.params?.id){
            this.props.findOwnerProperty(authData.user.customer_id)
            let propId = this.props?.match?.params?.id
            let customer_id = authData?.user?.customer_id
            let type = 'country'
            this.props.getProfile(propId, type, customer_id)
        }

        let propCount = props?.propertyList?.length

        if(propCount >= 1 && props?.location?.pathname == '/profile'){
            let f_prop_id = props?.propertyList?.[0]?.property_id
            props.history.push('/profile/'+f_prop_id)
        }
    }

    render(){

        const { props, state } = this
        const { proData, constants, match } = props
        const { address, p_email  }  = state
        const accounts = props?.proData?.accounts
        const countries = props?.proData?.countries
        const states = props?.proData?.states    
        const allState = props?.proData?.allStates    
        const cities = props?.proData?.cities
        const allCities = props?.proData?.allCities
        const selected_bank = props?.proData?.property?.selected_bank
        const foreign_resident = props?.proData?.property?.foreign_resident
        const profile = props?.proData?.profile
        const location = props?.proData?.userLocation
        const customer_id = props?.proData?.profile?.customer_id
        const propId = this.props?.match?.params?.id
        const secondAuthority = props?.proData?.secondAuthority

        let isCheckedEmail = (profile?.details?.email_notify != 'N') ? true : false
        let isCheckedSms = (profile?.details?.sms_notify != 'N') ? true : false

        return (
            <div className='personal-section'>
                <div class="modal-header">
                    <div class="modal-title h4 banking-title"></div>
                    <button type="button" class="close" onClick={() => props?.onClose()}>
                        <span aria-hidden="true">×</span>
                        <span class="sr-only">Close</span>
                    </button>
                </div>
                <div class="modal-header">
                    <div class="modal-title h4 banking-title">
                        <div className='row justify-content-center'>
                            <div className='col-lg-10 col-lg-10 col-md-12 col-sm-12'>
                                <p>YOU CAN ASSIGN A SECOND AUTHORITY TO ACCESS AND READ (NOT EDIT) ANY OF YOUR INFORMATION. THIS IS USEFUL FOR BOOK-KEEPERS, PARTNERS AND SUCH. YOU NEED TO MANAGE ACCESS FOR THIS SECOND AUTHORITY</p>
                            </div>
                        </div>
                    </div>
                </div>
                <form onSubmit={(el) => handleAddSecondAuthority(el, this, props, customer_id)}>
                    <div className='row authorize-section justify-content-center'>
                        <div className='col-lg-8 col-lg-8 col-md-12 col-sm-12'>
                            <div className='row'>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" name="a_first_name" placeholder="First Name*" className="field_text_style" required defaultValue={secondAuthority?.first_name}/>
                                        <span className="help-block with-errors"></span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" name="a_last_name" placeholder="Last Name*" className="field_text_style" required defaultValue={secondAuthority?.last_name} />
                                        <span className="help-block with-errors"></span> 
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" name="a_uname" placeholder="Username*" className="field_text_style" required defaultValue={secondAuthority?.username} />                                    
                                        <span className="help-block with-errors"></span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="email" name="a_email" placeholder="Email*" className="field_text_style" required defaultValue={secondAuthority?.email}/>                                    
                                        <span className="help-block with-errors"></span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" name="a_mobile" placeholder="Mobile*" className="field_text_style" required defaultValue={secondAuthority?.mobile}/>                                    
                                        <span className="help-block with-errors"></span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="password" name="a_passw" placeholder="Password*" className="field_text_style" required autoComplete="off" />
                                        <span className="help-block with-errors"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-8 col-lg-8 col-md-12 col-sm-12'>
                            <div className='row'>
                                <div class="col-md-12 d-flex justify-content-end">
                                    <button type="submit" class="btn btn-primary">Save Changes</button>
                                    {
                                        secondAuthority?.second_authority_id ?
                                        <button type="button" class="btn btn-default" onClick={(el) => handleDeleteEvent(el, props, secondAuthority?.customer_id)}>Delete</button>
                                        :
                                        <button type="button" class="btn btn-default disabled" title="Second Authority Not Found.">Delete</button>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    handleChequeBoxEvent(el){
        el.preventDefault()
        this.setState({
            cheque:true
        })
    }

    updateAccountName(el, isBankDetailsEdit)
    {
        let isBankDetails  = isBankDetailsEdit
        isBankDetails['account_name'] = el.target.value
        this.setState({
            isBankDetailsEdit: isBankDetails
        })
    }

    updateAccountRoute(el, isBankDetailsEdit)
    {
        let isBankDetails  = isBankDetailsEdit
        isBankDetails['route'] = el.target.value
        this.setState({
            isBankDetailsEdit: isBankDetails
        })
    }

    updateAccountNumber(el, isBankDetailsEdit)
    {
        let isBankDetails  = isBankDetailsEdit
        isBankDetails['account_number'] = el.target.value
        this.setState({
            isBankDetailsEdit: isBankDetails
        })
    }

    updateAccountTransit(el, isBankDetailsEdit)
    {
        let isBankDetails  = isBankDetailsEdit
        isBankDetails['transit'] = el.target.value
        this.setState({
            isBankDetailsEdit: isBankDetails
        })
    }
}

/**
 * add second authority details
 * 
 * @param {object} el 
 * @return {*}
 */
const handleAddSecondAuthority = (el, obj, props, customer_id) => {

    el.preventDefault();
    const secondAuthority = props?.proData?.secondAuthority

    let formData    = new FormData();
    formData.append("first_name", el.target.a_first_name.value);
    formData.append("last_name", el.target.a_last_name.value);
    formData.append("username", el.target.a_uname.value);
    formData.append("email", el.target.a_email.value);
    formData.append("mobile", el.target.a_mobile.value);
    formData.append("password", el.target.a_passw.value);
    formData.append("customer_id", customer_id);
    formData.append("second_auth", secondAuthority?.second_authority_id);
    
    props.addSecondAuthority(formData, props)
    obj.setState({showPop:false})
}

const handleDeleteEvent = async(el, props, id) => {

    swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async(result) => {
        if (result.value) {
            let formData    = new FormData();
            formData.append("id", id);
            await props.deleteSecondAuthority(formData, props)
            props?.onClose()
            props?.onOpen()
        }
      })
}

/** validate function is or not */
Authorization.propTypes = {
    findOwnerProperty: propTypes.func.isRequired,
    getProfile: propTypes.func.isRequired,
    addBankDetails: propTypes.func.isRequired,
    updateProfile: propTypes.func.isRequired,
    updateBankDetails: propTypes.func.isRequired,
    deleteBankDetails: propTypes.func.isRequired,
    addSecondAuthority: propTypes.func.isRequired,
    forRedirection: propTypes.func.isRequired,
    deleteSecondAuthority: propTypes.func.isRequired,
    applyNonResident: propTypes.func.isRequired,
    selectBank: propTypes.func.isRequired,
    copyToAll: propTypes.func.isRequired,
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    authData: state.dataPack.authData,
    propertyList: state.dataPack.propertyList,
    proData: state.dataPack.profileData,
    profileData:state.dataPack.profileData
})
export default connect (mapStateToProps, {findOwnerProperty, getProfile, addBankDetails, updateBankDetails, deleteBankDetails, selectBank, updateProfile, addSecondAuthority, deleteSecondAuthority, forRedirection, applyNonResident, copyToAll})( Authorization )