import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import { Link} from 'react-router-dom'
import moment from 'moment'
import 'react-notifications-component/dist/theme.css'
import property from '../../property/bills/property';

const Renting = (props) => {

    const {recentRent}  = props
    
    return (
        <>
            <div show={true} size="lg" className="folio_table transparent_modal_box folio_table_box renting_table">
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                    <Button className="cross-button" variant="" onClick={() => props.handlePopupEvent()}>
                        <i className="fa fa-times-circle fa-2x"></i>
                    </Button>
                </Modal.Header>
                
                <Modal.Body>
                    <div className="height-auto">
                    <table  className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th>Property</th>
                            <th className='text-right'>Rent</th>
                            {
                                recentRent ?
                                <th className="text-right _green">New Funds Received</th> :
                                <th></th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            renderProperty(props)
                        }
                        </tbody>
                    </table>
                    </div>
                </Modal.Body>
               
            </div>
       </>
    )
} 

const renderProperty = (props) => {

    let comingRent                   = 0
    const { folioData, constants, recentRent }   =  props

    return folioData?.propertyList?.map(property => {

        comingRent  = recentRent?.queue_list?.filter( list => list?.approved?.transaction?.ledger?.property_id == property?.property_id && constants.LEDGER_ENTRY_NOT_IN.includes((list?.approved?.description)?.toLowerCase()) )
        
        return (
            <>
            
                <tr>
                    <td><Link to={"/tenancy/"+property?.property_id}>{ property?.address}</Link></td>
                    <td className="num_center">{ props.helper.currencySign( (property?.lease?.rent ? property?.lease?.rent : 0)) }</td>
                    {
                        recentRent ?
                        <td className="_green num_center _green font-weight-bold">{
                            comingRent?.length ?
                            props.helper.currencySign(parseFloat(comingRent[0]?.approved?.amount)) :
                            '-'
                        }</td> :
                        <td></td>
                    }
                </tr>
            </>
        )
    })
}
export default Renting