export const GET_PROPERTY   = 'GET_PROPERTY';
export const LEDGER_LIST    = 'LEDGER_LIST';
export const LEASE_DETAILS  = 'LEASE_DETAILS';
export const OWNER_ENQUIRY  = 'OWNER_ENQUIRY';
export const PROPERTY_BILLS  = 'PROPERTY_BILLS';
export const TENANT_LIST    = 'TENANT_LIST'
export const DOCUMENTS_BILLS = 'DOCUMENTS_BILLS';
export const LOADING_COMPONENT = 'LOADING_COMPONENT';
export const AUTH_USER          = 'AUTH_USER';
export const GET_LOGOUT         = 'GET_LOGOUT';
export const GET_BUILDING         = 'GET_BUILDING';
export const GET_BUILDING_PROPERTY = "GET_BUILDING_PROPERTY"
export const FOR_LEASE = 'FOR_LEASE';
export const GET_FORLEASE_PROPERTY = 'GET_FORLEASE_PROPERTY';
export const PROFILE_PROPERTY = 'PROFILE_PROPERTY';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_PROFILE = 'GET_PROFILE';
export const ADD_BANK_DETAILS = 'ADD_BANK_DETAILS';
export const UPDATE_BANK_DETAILS = 'UPDATE_BANK_DETAILS';
export const DELETE_BANK_DETAILS = 'DELETE_BANK_DETAILS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const ADD_SECOND_AUTHORITY = 'ADD_SECOND_AUTHORITY';
export const DELETE_SECOND_AUTHORITY = 'DELETE_SECOND_AUTHORITY';
export const USER_REDIRECT = 'USER_REDIRECT';
export const FOLIO_DATA = 'FOLIO_DATA';
export const APPLY_NON_RESIDENT = 'APPLY_NON_RESIDENT';
export const OWNER_LIST = "OWNER_LIST";
export const GET_RESOURCES = "GET_RESOURCES";
export const GET_WC_RESOURCES = "GET_WC_RESOURCES";
export const BUILDING_LEDGERS = "BUILDING_LEDGERS";
export const RECENT_RENT = "RECENT_RENT";
export const FOLIO_LEDGERS = "FOLIO_LEDGERS";
export const SELECT_BANK = "SELECT_BANK";
export const CLEAN_UP_DATA = "CLEAN_UP_DATA"
export const CHECK_SLA_SIGNED = "CHECK_SLA_SIGNED"
export const GET_PROPERTY_TYPE = "GET_PROPERTY_TYPE";
export const GET_PROPERTY_LOCATION = "GET_PROPERTY_LOCATION";
export const STATE_CITY = "STATE_CITY"
export const PROPERTY_UTILITY = "PROPERTY_UTILITY"
export const PROPERTY_MEDIA = "PROPERTY_MEDIA"
export const USER_NOTIFICATION = "USER_NOTIFICATION"
export const PENDING_SLA = "PENDING_SLA"
export const SIGNED_SLA = "SIGNED_SLA"

