import React from 'react'
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom'

const publicRoute = ({ component: Component, ...rest }) => {

    const userAccessInfo = rest.authData
    
    return(
        <Route 
            {...rest} 
            render = {(props) => {

                const path      = userAccessInfo?.user?.simulate ? "/owner/list" : "/folio-snap"

                if (userAccessInfo) {
                    return <Redirect to={{ pathname: path, state: { from: props.location } }} />
                }

                return <Component {...props} />
            }} 
        /> 
    );
}

const mapStateToProps = state => ({
    isLoggedIn: state.dataPack.isLoggedIn,
    authData: state.dataPack.authData
})

export default connect (mapStateToProps, {  } )( publicRoute )