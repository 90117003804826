import React, { Component } from 'react'
import { connect } from 'react-redux';
import moment from 'moment'
import propTypes from 'prop-types'
import Upload from './upload'
import { LargeModal } from './../../../../../component/structure/modals'
import { handleImageUpload, renderSlaPdf } from './../../../../redux/actions/listActions'

class Property extends Component {
    
    constructor(props) 
    {
        super(props)
        this.state = {
            modalShow: false, 
            notes: false
        }
    }

    /**
     * get all property document list
     * 
     * @param {object} document 
     * @returns 
     */
    propertyDocument(document, title, type) {

        const { DATEFORMAT } = this.props.constants

        if( document )
        {
            return document.map((option) => {

                return (
                    <tr>
                        <td> <i className="fa fa-file-pdf-o" aria-hidden="true"></i> { (option?.doc_type && option?.doc_type != "title") ? option?.doc_type : title }</td>
                        <td>{ moment((option?.added_on) ? option?.added_on : option?.media?.added_on).format( DATEFORMAT ) }</td>
                        <td><a href={this.findDocumentLink( type, option )} target="_blank" download class="btn _leased _red">View</a></td>
                    </tr>
                )
            })
        }
    }

     /**
     * find document links
     * 
     * @param {object} document 
     * @returns 
     */
    findDocumentLink( type, option )
    {
        const { MEDIAURL } = this.props.constants

        if( type == 'DOCS')
            return MEDIAURL+option?.doc_dir+option?.doc_name

        else if( type == "MT" )
            return MEDIAURL+option?.work_order?.invoice?.path + '/' + option?.work_order?.invoice?.name
    }

    /**
     * get all property storage list
     * 
     * @param {object} document 
     * @returns 
     */
     propertyStorage(document, title) {

        const { DATEFORMAT, MEDIAURL } = this.props.constants

        if( document )
        {
            return document.map((option) => {

                return (
                    <tr>
                        <td> <i className="fa fa-file-pdf-o" aria-hidden="true"></i> { option?.media?.title ?? title }</td>
                        <td>{ moment(option?.media?.added_on).format( DATEFORMAT ) }</td>
                        <td><a href={MEDIAURL+option?.media?.path + '/' + option?.media?.name} target="_blank" download class="btn _leased _red">View</a></td>
                    </tr>
                )
            })
        }
    }

    /**
     * get all property notes list
     * 
     * @param {object} document 
     * @returns 
     */
     propertyNotes(document, title) {

        const { DATEFORMAT } = this.props.constants

        if( document )
        {
            return document.map((option) => {

                return (
                    <tr>
                        <td>{ moment(option?.added_on).format( DATEFORMAT ) }</td>
                        <td><a href="#" class="btn _leased _red" onClick={(el) => this.showNotes(el, option?.note)}>View</a></td>
                    </tr>
                )
            })
        }
    }

    /**
     * 
     * @param {*} el 
     * @param {*} notes 
     */
    showNotes(el, notes){

        el.preventDefault();
        this.setState({
            modalShow: true,
            notes: notes
        })
    }

    render() {
        
        const { props, state }                = this
        const { constants, documentBills }    = props
        
        return (
            <div className="col-sm-6 col-md-8">
                <div className="_property_document">
                    <div className="headline">Property</div>
                    <div className="row d-flex">
                        <div className="col-sm-12 col-md-12 docx_box tenant-view-table">
                            <table className="table doc_table">
                                { 
                                    documentBills?.property && 
                                    this.propertyDocument( documentBills?.property?.document, 'Property Document', 'DOCS' ) 
                                }

                                { 
                                    documentBills?.property && 
                                    this.propertyDocument( documentBills?.property?.maintaince.filter(list => list.work_order && list.work_order.invoice), 'Maintenance Document', 'MT' ) 
                                }
                                { 
                                    documentBills?.property && 
                                    this.propertyStorage( documentBills?.property?.storage, 'Property Storage') 
                                }
                                { 
                                    documentBills?.property && 
                                    this.propertyStorage( documentBills?.property?.rentHistory, 'Rent History') 
                                }
                                { 
                                    documentBills?.property && 
                                    this.propertyNotes( documentBills?.property?.propertyNotes, 'Property Note') 
                                }
                                
                            </table>
                        </div>
                    </div>
                    <div className="row _rows d-flex">
                    <Upload {...props} />
                    <div className="col-sm-12 col-md-3 col-lg-3 cpointer" onClick={(el) => this.generateSla(el)}>
                        <div className="bill_document">
                            <div className="box_icon"><i className="fa fa-file-pdf-o" aria-hidden="true"></i></div>
                            <div className="_link font-11">My Service Level <br/> Agreement</div>
                        </div>
                    </div>
                </div>
            </div>
            <LargeModal
                title="Upload Document"
                imagePreviewUrl={state.imagePreviewUrl}
                notes={ state.notes }
                show={state.modalShow}
                onSubmit={() => this.handleImageUploadEvent()}
                onModalEvent={() => this.setState({modalShow: !state.modalShow})}
            />
        </div>
        )
    }

    /**
     * render pdf using property ID
     * 
     * @param {object} el 
     * @return {*}
     */
    generateSla(el){

        el.preventDefault();
        const { props } = this
        const { params }    = props.match

        props.renderSlaPdf( params.id )
    }

    /**
     * handle image upload
     * 
     * @param {object} el 
     * 
     * @return {*}
     */
    handleUploadEvent(el)
    {
        el.preventDefault();
        
        let file = el.target.files[0];
        var formdata = new FormData();
        formdata.append("document", file);
        formdata.append("documentType", el?.target?.getAttribute('doctype'));
        formdata.append("propertyId", 1);

        this.props.handleImageUpload(formdata)
    }

    /**
     * file upload event
     * 
     * @param {*} NA
     * @return {*}
     */
    async handleImageUploadEvent()
    {
        const { file }   = this.state
     
        var formdata = new FormData();
        formdata.append("document", file);
        await this.props.handleImageUpload(formdata)
    }

    /**
     * get property ledger list on property select
     *   
     * @param {object} el 
     * @return {*}
    */
     async handleEvents(el)
     {
        el.preventDefault();
    
        this.setState({

        creditType: el.target.getAttribute('creditType')
        }, () => {

            const { props, state }     = this
            const propertyId    = props.leaseDetails?.property_id

            const data      = {
                propertyId  : propertyId,
                creditType  : state.creditType,
                startFrom   : "",
                endTo       : ""
            }
            props.findPropertyLedger(data)
        })
     }
}

/** validate function is or not */
Property.propTypes = {
    findOwnerProperty: propTypes.func.isRequired,
    handleImageUpload: propTypes.func.isRequired,
    renderSlaPdf: propTypes.func.isRequired
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    propertyList: state.dataPack.propertyList,
    constants: state.dataPack.constants,
    helper: state.dataPack.helper,
    ledgerList: state.dataPack.ledgerList,
    leaseDetails: state.dataPack.leaseDetails,
    documentBills: state.dataPack.documentBills
})

export default connect (mapStateToProps, {handleImageUpload, renderSlaPdf})( Property )
