import React, { Component } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import Upload from './upload'

class Update extends Component {

    constructor(props)
    {
        super(props)
        this.state  = {
            showUpload  : false,
            propertyImages  : [],
            propertyRequest: '',
            descLength  : 0,
            words: 0,
            accept: props?.constants?.FILEACCEPT
        }
        /** validator initialize */
        this.validator = new SimpleReactValidator();
    }
     
    render() {

        const { props, state } = this
        return (
            <form  onSubmit={(el) => this.handleBeforeFormSubmit(el)} id="manageProperty" >
                <div className="dash-form-bg _wrapper">
                    <div className="row">

                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            
                            <div class="form-group">
                                <textarea onKeyUp={(el) => {this.setState({words: (el.target.value)?.length, descLength: (el.target.value)?.split(' ')?.length, propertyRequest: el.target.value})}} rows="15" cols="120" className="form-control" placeHolder="Tell us what do you want to update here ...">{state?.propertyRequest}</textarea>
                                <p className="char_counter">Words count: {state?.descLength}, Char count: {state?.words}</p>
                                {this.validator.message('Property Details', state?.propertyRequest, 'required', { className: 'text-danger' })}
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        
                        <div className="offset-md-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <div class=""><button className="_black" onClick={(el) => {el.preventDefault(); this.setState({showUpload: true})}}>Add Photos ({(state?.propertyImages?.filter(list => !list.done))?.length} selected)</button></div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <div class=""><button className="_black">Save</button></div>
                        </div>

                    </div>
                    <Upload 
                        accept={ state?.accept }
                        show={state.showUpload} 
                        {...props} 
                        propertyImages={state?.propertyImages?.length ? state?.propertyImages : this.setInitialMedia()}
                        handleUploadPopup={()=>this.setState({showUpload: false})} 
                        handleStoreImages={(data) => {this.uploadEvent(data)}}
                    />
                </div>
            </form>
        )
    }

    setInitialMedia()
    {
        const { media, constants } = this.props
        let initial     = []
        try {
            media?.map( (option, index) => {

                initial.push({source: constants?.MEDIAURLDEV+'/'+option?.media?.path+'/'+option?.media?.name, name: option?.media?.name})
            })
        } catch (error) {
        }
        return initial
    }
    /**
     * store property images 
     * 
     * @param {object} el 
    */
    uploadEvent(el)
    {
        this.setState({
            propertyImages: el
        })
        this.forceUpdate()
    }

    /**
     * handle form submitt events before
     * 
     * @param {object} el 
    */

    handleBeforeFormSubmit(el)
    {
        el.preventDefault();
        if (this.validator.allValid()) {

            this.handleFormSubmit()
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }

    /**
     * handle form submitt events
     * 
     * @param {*} Na 
     */
    async handleFormSubmit()
    {
        const { state, props }  = this
        const { constants }     = props
        let {propertyImages}    = state
        
        let fileFormData            = new FormData();

        /** property images */
        if( propertyImages )
        {   
            propertyImages?.filter(list => !list.done)?.map((image) => {
                fileFormData.append(image?.uid, image?.file);
            })
            fileFormData.append( 'property_id', props?.match?.params?.id);
        }

        fileFormData.append( 'property_request', state.propertyRequest);
        this.props.updatePropertyInformation(fileFormData, props?.constants?.MEDIA_UPLOAD_URL)
    }
}

export default Update
