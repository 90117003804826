import React, {useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import moment from 'moment'
import { Link } from 'react-router-dom'
import 'react-notifications-component/dist/theme.css'
import TenantProfile from './profile';

const Leaseing = (props) => {

    const [show, setShow] = useState(0);

    return (
        <>
            <div className="folio_table transparent_modal_box folio_table_box">
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                    <Button className="cross-button" variant="" onClick={() => props.handlePopupEvent()}>
                        <i className="fa fa-times-circle fa-2x"></i>
                    </Button>
                </Modal.Header>
                
                <Modal.Body>
                    <table  className="table table-striped table-hover billDueTable">
                        <tbody>
                            {
                                renderProperty(props, setShow)
                            }
                        </tbody>
                    </table>
                </Modal.Body>
            </div>
            <TenantProfile suTtitle="Tenant Profile" {...props} show={show} handlePopupEvent={() => setShow(false)}  />
       </>
    )
}

const renderProperty = (props, setShow) => {

    const { folioData }   =  props

    const list  = folioData?.propertyList?.filter( property => property?.tenant_lease )

    if( !list?.length ) return <h4>No New Lease found!</h4>

    return folioData?.propertyList?.map(property => {

        if( property?.tenant_lease ){ 

            return (
                <>
                    <tr>
                        <td><Link to={"/tenancy/"+property?.property_id}>{ property?.address}</Link></td>
                        <td>Started On: {moment(property?.tenant_lease?.start).format('MMM DD, YYYY')}</td>
                        <td className="text-center"><button className="table-button btn btn-info" onClick={() => setShow(property)}><i className="fa fa-user fa-2x"></i> </button></td>
                    </tr>
                </>
            )
        }
    })
}

export default Leaseing