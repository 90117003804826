import React, { Component } from 'react'
import { connect } from 'react-redux';
import Panel from './../panel'
import List from './list'
import moment from 'moment'
import Dates from './../tenent/dates'
import Refer from './../panel/refer'
import propTypes from 'prop-types'
import DatePicker from 'react-mobile-datepicker';
import Overlay from './../../../../component/layout/overlay'

import { findOwnerBuilding, findFolioProperty } from './../../../redux/actions/listActions'

class Building extends Component {
    
    constructor(props)
    {
        super(props)

        this.state  = {
            start: new Date(moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD')),
            end: new Date(moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD')),
            isStart: false,
            isENd: false,
            modalShow:false,
            leasedOnly: false
        }
    }
    /**
     * mount component once render the initial component 
     * 
     * @param {*} NA
     * @return {*} NA
    */
   async componentDidMount()
   { 
      document.title = "Building View | RentPerks"
      const { props } = this
      const { user }  = props.authData

      await props.findFolioProperty(user.customer_id)
      await this.handleUpdateEvent()
   }

   /**
     * trigger apis on url param change
     * 
     * @param {object} prevProps
     * @return {*} 
    */
    componentDidUpdate(prevProps) 
    {
        const { match }   = this.props

        if( match.params.id != prevProps.match.params.id )
          this.handleUpdateEvent()
    }

    render() {

        const { props, state }   = this
        const { folioData }      = props
        let filterName           = state.leasedOnly ? 1 :0 

        return (
            <>
                <Panel {...props} />
                <div className="dashboard-middle parentCell">
                    
                    <div className="dash-form-bg detail-wrapper">
                      {
                        folioData ? 
                        <List {... props} leasedOnly={state.leasedOnly} start={state.start} end={state.end} />  
                        :
                        <p>No Property found for this building.</p>
                      }
                      
                      <div className="row date_range_selector">
                        <div className="col-sm-6 col-md-3">
                          
                        </div>
                        <div className="col-sm-6 col-md-3">
                          <div className="date_selector d-flex" onClick={(el) => this.setState({ isStart: true, isEnd: false, el })} >
                            <Dates setDate={state.start}/>
                          </div>
                          <p>From</p>
                        </div>
                        <div className="col-sm-6 col-md-3">
                          <div className="date_selector d-flex" onClick={(el) => this.setState({ isEnd: true, isStart: false, el })}>
                            <Dates setDate={state.end} />
                          </div>
                          <p>To</p>
                        </div>
                        <div className="col-sm-6 col-md-3 text-center">
                          <button className="_btn btn-pdf"> PDF</button><button className="_btn btn-pdf-csv"> CSV</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <DatePicker 
                    value={this.state.start} 
                    isOpen={this.state.isStart} 
                    isPopup={true} 
                    theme="android" 
                    confirmText="SET" 
                    cancelText="CANCEL" 
                    className="mobStart" 
                    onSelect={(time) => this.setState({ start: time, isStart: false }, () => { this.manageDates('start', time)})} 
                    onCancel={() => this.setState({ isStart: false })} 
                />

                <DatePicker 
                    value={this.state.end} 
                    isOpen={this.state.isEnd} 
                    theme="android" 
                    confirmText="SET" 
                    cancelText="CANCEL" 
                    className="mobEnd" 
                    onSelect={(time) => this.setState({ end: time, isEnd: false }, () => {this.manageDates('end', time)} ) } 
                    onCancel={() => this.setState({ isEnd: false })} 
                />
                <Refer />
            </>
        )
    }

    /**
     * manage start and end dates 
     * 
     * @param {string} type 
     * @return {*}
     */
     manageDates(type)
     {
         const { start, end }    = this.state
         try {
             
             if( type === 'start' )
             {
                 if( moment(start).format('x') > moment(end).format('x') )
                     this.setState({ end: start}, () => {this.handleUpdateEvent() })
                 else
                     this.handleUpdateEvent()
             }
             else if( type === 'end' )
             {
                 if( moment(end).format('x') < moment(start).format('x') )
                     this.setState({ start : end }, () => {this.handleUpdateEvent() })
                 else
                     this.handleUpdateEvent()
             }
         } catch (error) {}
     }
    
    /**
     * handle property change event
     * 
     * @param{*} NA
     * @return {*} 
     */
    async handleUpdateEvent()
    {
        const { props, state }      = this   
        const { match, authData }   = props

        let data  = {
            groupId: match.params.id,
            leasedOnly: state.leasedOnly,
            startDate: state.start,
            endDate: state.end,
            customerId: authData.user.customer_id
        }

        await props.findFolioProperty(data)
    }
}

/** validate function is or not */
Building.propTypes = {
  findOwnerBuilding: propTypes.func.isRequired,
  findFolioProperty: propTypes.func.isRequired
}

/**
 * sending all state to component as props
 *
 * @param {object} state
 */
const mapStateToProps = state => ({
    propertyList: state.dataPack.propertyList,
    helper: state.dataPack.helper,
    authData: state.dataPack.authData,
    buildings: state.dataPack.buildingList,
    folioData: state.dataPack.folioData,
    constants: state.dataPack.constants
})

export default connect (mapStateToProps, {findOwnerBuilding, findFolioProperty})( Building )
