import React, { Component } from 'react'
import { connect } from 'react-redux';
import moment from 'moment'
import propTypes from 'prop-types'
import { LargeModal } from './../../../../../component/structure/modals'
import { handleImageUpload, renderSlaPdf, findPropertyDocument, findPropertyBill } from './../../../../redux/actions/listActions'

class Property extends Component {
    
    constructor(props) 
    {
        super(props)
        this.state = {
            modalShow: false, 
            notes: false,
            global_sla: 7
        }
    }

    componentDidMount()
    {
        const { leaseDetails }   = this.props

        if(leaseDetails){
            this.props.findPropertyDocument( leaseDetails.property_id )
            this.props.findPropertyBill( leaseDetails.property_id )
        }
    }

    /**
     * get all property document list
     * 
     * @param {object} document 
     * @returns 
     */
    propertyDocument(document, title, type) {

        const { DATEFORMAT, MEDIAURLDEV } = this.props.constants

        if( document )
        {
            return document.map((option) => {

                return (
                    <tr>
                        <td className='text-left'> <i className="fa fa-file-pdf-o" aria-hidden="true"></i> { (option?.doc_type && option?.doc_type != "title") ? option?.doc_type : title }</td>
                        <td>{ moment((option?.added_on) ? option?.added_on : option?.media?.added_on).format( DATEFORMAT ) }</td>
                        <td className='text-center'><a href={MEDIAURLDEV+''+option?.media?.path +''+ option?.media?.name} target="_blank" download class="download_button _leased _red plr-20">View</a></td>
                    </tr>
                )
            })
        }
    }

     /**
     * find document links
     * 
     * @param {object} document 
     * @returns 
     */
    findDocumentLink( type, option )
    {
        const { MEDIAURL } = this.props.constants

        if( type == 'DOCS')
            return MEDIAURL+option?.doc_dir+option?.doc_name

        else if( type == "MT" )
            return MEDIAURL+option?.work_order?.invoice?.path + '/' + option?.work_order?.invoice?.name
    }

    /**
     * get all property condition report list
     * 
     * @param {object} document 
     * @returns 
     */
     propertyCr(document, title) {

        const { DATEFORMAT, MEDIAURL, CONDITION_REPORT_TYPE } = this.props.constants

        let filterData  = document?.filter(list => list.signed_media_id )
        
        if( filterData )
        {
            return filterData?.map((option) => {

                let media_file = ( option?.signed_pdf ) ? option?.signed_pdf?.path+'/'+option?.signed_pdf?.name : option?.media?.path + '/' + option?.media?.name;

                return (
                    <tr>
                        <td  className='text-left' title={option?.title}> <i className="fa fa-file-pdf-o mr-1" aria-hidden="true"></i>{ (option?.title)?.substr(0,40) ?? title } ({ CONDITION_REPORT_TYPE[option?.type] }) ({ option?.is_cp_signed == "Y" ? 'Signed' : 'Unsigned' })</td>
                        <td>{ moment(option?.added_on).format( DATEFORMAT ) }</td>
                        <td className='text-center'><a href={MEDIAURL+media_file} target="_blank" download class="download_button _leased _red plr-20">View</a></td>
                    </tr>
                )
            })
        }
    }

    /**
     * get all property storage list
     * 
     * @param {object} document 
     * @returns 
     */
     propertyStorage(document, title) {

        const { DATEFORMAT, MEDIAURL } = this.props.constants

        if( document )
        {
            return document?.filter(list => !list.tenant_id)?.map((option) => { console.log(option, 'option');

                let media_file = ( option?.signed_pdf ) ? option?.signed_pdf?.path+'/'+option?.signed_pdf?.name : option?.media?.path + '/' + option?.media?.name;

                return (
                    <tr>
                        <td className="w-30 text-left" title={option?.title}> <i className="fa fa-file-pdf-o mr-1" aria-hidden="true"></i>{ (option?.title)?.substr(0,40) ?? title }</td>
                        <td>{ moment(option?.added_on).format( DATEFORMAT ) }</td>
                        <td className='text-center'><a href={MEDIAURL+media_file} target="_blank" download class="download_button _leased _red plr-20">View</a></td>
                    </tr>
                )
            })
        }
    }

    handleLeaseDetailsEvent(el, option)
    {
        el.preventDefault();

        try {
            const applicationIdLease  = option?.application_id
            
            const { MEDIAURL }  = this.props.constants
            
            if( !applicationIdLease )
            {
                alert('No Lease Found!')
                return false;
            }
            if(option?.path && option?.name)
                window.open(MEDIAURL+option.path+option.name);
            else
                window.open(MEDIAURL+"uploads/property_application/"+applicationIdLease+"/lease.pdf");
        } catch (error) {
            
        }
    }

    /**
     * get all property lease list
     * 
     * @param {object} document 
     * @returns 
     */
     propertyLease(document, title) {

        const { DATEFORMAT } = this.props.constants

        if( document )
        {
            return document?.filter(list => !list.tenant_id)?.map((option) => { console.log(option, 'option');

                let current_lease = (option?.tenancy_end_date == null || moment(option?.tenancy_end_date).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) ? "current_lease" : "";

                return (
                    <tr>
                        <td className={`w-30 text-left ${current_lease}`} title={option?.title}> <i className="fa fa-file-pdf-o mr-1" aria-hidden="true"></i>{ (option?.title)?.substr(0,40) ?? title }</td>
                        <td className={`${current_lease}`}>{ moment(option?.added_on).format( DATEFORMAT ) }</td>
                        <td className={`text-center ${current_lease}`}><a href="#" target="_blank" download class="download_button _leased _red plr-20" onClick={(el)=>this.handleLeaseDetailsEvent(el, option)}>View</a></td>
                    </tr>
                )
            })
        }
    }

    /**
     * get all property notes list
     * 
     * @param {object} document 
     * @returns 
     */
     propertyNotes(document, title) {

        const { DATEFORMAT } = this.props.constants

        if( document )
        {
            return document.map((option) => {

                return (
                    <tr>
                        <td className='text-left'><i class="fa fa-sticky-note"></i> { title }</td>
                        <td>{ moment(option?.date_modified).format( DATEFORMAT ) }</td>
                        <td className='text-center'><a href="#" class="download_button _leased _red  plr-20" onClick={(el) => this.showNotes(el, option)}>View</a></td>
                    </tr>
                )
            })
        }
    }

    /**
     * 
     * @param {*} el 
     * @param {*} notes 
     */
    showNotes(el, notes){

        el.preventDefault();
        this.setState({
            modalShow: true,
            notes: notes
        })
    }

    render() {
        
        const { DATEFORMAT, MEDIAURL } = this.props.constants

        const { props, state }                = this
        const { constants, documentBills, applicationId }    = props
        const { tenant }    = props.documentBills
        let leaseDate = '';

        let ownerDocument
        try {
            ownerDocument = documentBills?.property?.ownerDocument
            leaseDate = tenant?.list[0]?.lease?.added_on
        } catch (error) {
            console.log(error, 'error');
         }

        return (
            <>
                <div className="row d-flex auto_scroll_bill_due_table">
                    <div className="col-sm-12 col-md-12 docx_box tenant-view-table w-full mt-1">
                        <button type="button" class="close close-absolute" onClick={() => props?.onClose()}>
                            <i className='fa fa-times'></i>
                        </button>
                        <table className="table doc_table property-ledger-table m-0">
                            <thead>
                                <tr>
                                    <th tabindex="0">DESCRIPTION</th>
                                    <th tabindex="0">Date</th>
                                    <th tabindex="0">Link</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ownerDocument?.lease &&
                                    this.propertyLease( ownerDocument?.lease, 'Lease') 
                                }
                                { 
                                    (ownerDocument && ownerDocument?.storage) && 
                                    this.propertyCr( ownerDocument?.cr, 'Condition Report') 
                                }
                                { 
                                    (ownerDocument && ownerDocument?.storage) && 
                                    this.propertyStorage( ownerDocument?.storage, 'Property Storage') 
                                }
                                { 
                                    (ownerDocument && ownerDocument?.note) && 
                                    this.propertyNotes( ownerDocument?.note, 'Notes') 
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row _rows d-flex">
                    {/* <Upload {...props} /> */}
                    
                    {/* <div className="col-sm-12 col-md-3 col-lg-3 cpointer">
                    <a href={constants?.MEDIAURL+'pub/service_agreement/owner_portal_sla_documents/'+props?.authData?.user?.customer_id+'/'+state?.global_sla} target="_blank">
                        <div className="bill_document">
                            <div className="box_icon"><i className="fa fa-file-pdf-o" aria-hidden="true"></i></div>
                            <div className="_link font-11">My Service Level <br/> Agreements</div>
                        </div>
                        </a>
                    </div> */}
                    
                </div>
                <LargeModal
                    title="Upload Document"
                    imagePreviewUrl={state.imagePreviewUrl}
                    notes={ state.notes }
                    show={state.modalShow}
                    mediaUrl={MEDIAURL}
                    onSubmit={() => this.handleImageUploadEvent()}
                    onModalEvent={() => this.setState({modalShow: !state.modalShow})}
                />
            </>
        )
    }

    renderDocuments()
    {
        const { props, state }    = this
        const { documentBills }   = this.props
        const { MEDIAURLDEV }     = this.props.constants
        
        try {
            
            const {cr}                              = documentBills?.property?.ownerDocument
        
            const filterData                        = cr ? (cr.filter(list => (list.customer_id == state?.applicationId || list?.lease?.application?.applicant_id == state?.applicationId ))): []
            
            return filterData.map( function(option, index) {
                return (

                    <tr key={index}>
                        <td className='text-left'><i className="fa fa-file-pdf-o mr-1" aria-hidden="true"></i> Condition Report</td>
                        <td>{moment(option.date_added).format('MMM  D, Y')}</td>
                        <td>
                            {
                                <a href="javascript:void(0)" className="dash-edit btn _leased _red plr-20" onClick={(el) => this.handlePdfEvents(el, option)}> View</a>
                            }
                        </td>
                    </tr>
                )
            }, this)
        } catch (error) {}
        return false
    }

    /**
     * render pdf using property ID
     * 
     * @param {object} el 
     * @return {*}
     */
    generateSla(el){

        el.preventDefault();
        const { props } = this
        const { params }    = props.match

        props.renderSlaPdf( params.id )
    }

    /**
     * handle image upload
     * 
     * @param {object} el 
     * 
     * @return {*}
     */
    handleUploadEvent(el)
    {
        el.preventDefault();
        
        let file = el.target.files[0];
        var formdata = new FormData();
        formdata.append("document", file);
        formdata.append("documentType", el?.target?.getAttribute('doctype'));
        formdata.append("propertyId", 1);

        this.props.handleImageUpload(formdata)
    }

    /**
     * file upload event
     * 
     * @param {*} NA
     * @return {*}
     */
    async handleImageUploadEvent()
    {
        const { file }   = this.state
     
        var formdata = new FormData();
        formdata.append("document", file);
        await this.props.handleImageUpload(formdata)
    }

    /**
     * get property ledger list on property select
     *   
     * @param {object} el 
     * @return {*}
    */
     async handleEvents(el)
     {
        el.preventDefault();
    
        this.setState({

        creditType: el.target.getAttribute('creditType')
        }, () => {

            const { props, state }     = this
            const propertyId    = props.leaseDetails?.property_id

            const data      = {
                propertyId  : propertyId,
                creditType  : state.creditType,
                startFrom   : "",
                endTo       : ""
            }
            props.findPropertyLedger(data)
        })
     }

    handleLeaseDetailsEvent__(el)
    {
        el.preventDefault();

        try {
            const { tenant }    = this.props.documentBills
            const applicationIdLease  = tenant?.list[0]?.lease?.application_id
            
            const { MEDIAURL }  = this.props.constants
            if( !applicationIdLease )
            {
                alert('Please Select Tenant to open the lease details')
                return false;
            }
            window.open(MEDIAURL+"uploads/property_application/"+applicationIdLease+"/lease.pdf");
        } catch (error) {
            
        }
    }
}

/** validate function is or not */
Property.propTypes = {
    findOwnerProperty: propTypes.func.isRequired,
    handleImageUpload: propTypes.func.isRequired,
    renderSlaPdf: propTypes.func.isRequired,
    findPropertyDocument: propTypes.func.isRequired,
    findPropertyBill:propTypes.func.isRequired
}

/**
 * sending all state to component as props
 * 
 * @param {object} state 
 */
const mapStateToProps = state => ({
    propertyList: state.dataPack.propertyList,
    constants: state.dataPack.constants,
    helper: state.dataPack.helper,
    ledgerList: state.dataPack.ledgerList,
    leaseDetails: state.dataPack.leaseDetails,
    documentBills: state.dataPack.documentBills
})

export default connect (mapStateToProps, {handleImageUpload, renderSlaPdf, findPropertyDocument, findPropertyBill})( Property )
